.popular-brands {
	padding: 30px 0;

	&__title {
		color: $dark-blue;
		margin-bottom: 25px;
	}

	.carousel-inner {
		> .item {
			align-items: stretch;
			height: 100%;
			gap: 20px;
			&.active, &.next.left, &.prev.right {
				display: flex;
			}
			> .inner-item {
				display: flex;
				width: 100%;
			}
		}
	}

	&__arrows-wrapper {
		.carousel-control {
			width: 38px;
			height: 30px;
			font-size: 20px;
			background-image: none !important;
			background-color: transparent;
			display: flex;
			align-items: center;
			justify-content: center;
			opacity: 1;
			position: absolute;
			top: inherit;
			bottom: -10px;
			z-index: 3;
			box-shadow: 0px 0px 0 0 transparent;
			text-shadow: 0 0 transparent;
			color: #f0027f;
			&:hover {
				cursor: pointer;
			}
		  }
	}

	.carousel-indicators {
		margin: 10px auto;
		position: relative;
		height: auto;
		bottom: 0;
		left: inherit;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 0;
		gap: 5px;
		flex-wrap: wrap;
		z-index: 3;
		> li {
		  margin: 0;
		  width: 10px;
		  height: 10px;
		  border: none;
		  border-radius: 50%;
		  background-color: #d3d3d9;
		  &.active {
			background-color: #f0027f;
		  }
		}
	  }
}
