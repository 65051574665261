.news-and-events {

  &__header {
    clear: both;
    padding: 58px 0 35px;
  }

  &__heading {
    display: inline-block;
    font-size: 40px;
    line-height: 48px;
    color: $dark-blue;
    font-weight: 700;
    vertical-align: middle;
    width: calc(100% - 200px);
    margin-bottom: 0;
  }

  &__see-all {
    display: inline-block;
    vertical-align: middle;
    line-height: 48px;
    text-align: right;
    width: 100%;
    max-width: 190px;
  }

  &__all-news {
    margin: 62px 0
  }

}
