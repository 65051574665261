body.geoPopin {
  height: 100vh;
  overflow-y: hidden !important;
}

.geolocation {
  position: fixed;
  top: -1000px;
  left: -1000px;
  background: rgba(0,0,0, .7);
  display: none;

  &--open {
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100vh;

  }

  &__content {
    padding: 15px;
    background: $dark-grey;
    display: inline-block;
  }

  &__popin {
    background: $white;
    padding: 10px;
    display: flex;
    flex-direction: column;
  }

  &__popin-title {
    font-weight: bold;
    color: $dark-blue;
    margin-bottom: 10px;
    font-size: 14px;

    &--main {
      text-transform: uppercase;
      margin-right: 35px;
    }
  }

  &__countries {
    display: flex;
    flex-direction: column;
  }

  &__country {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin: 0 0 10px 10px;
    font-size: 14px;
    flex: 0 0 auto;
    order: 2;

    &--default {
      order: 0;
    }

    &--current {
      order: 1;
    }

    &--hidden {
      display: none;
    }
  }

  &__radio {

  }
  &__custom-radio {
    content: '';
    display: block;
    height: 15px;
    width: 15px;
    border-radius: 8px;
    border: 1px solid #999;
    transition: all .2s ease-in;
  }
  input[type="radio"] {
    display: none;
  }

  input[type="radio"]:checked + &__custom-radio {
    background: $pink;
    border-color: $pink;
  }



  &__radio, &__flag {
    margin-right: 10px;
  }

  &__country-name {
    font-weight: bold;
    color: $dark-blue;
  }

  .btn-pink {
    border-radius: 0;
    max-height: 25px;
    min-height: 25px;
    font-weight: normal;
    max-width: 125px;
    min-width: 125px;
    margin: 10px auto;
    font-size: 14px;
  }

  // flags
  &__flag {
    width: 20px;
    height: 14px;
    //background-image: url("images/flags.png");
    display: none;

    &--fr {
      height: 14px;
      background-position: -1731px 0;
    }

    &--be {
      height: 15px;
      background-position: -440px 0;
    }
  }






}