.header {
  &-top {
    padding: 0 10px;
    justify-content: space-around;
    &--left {
      width: auto;
      margin: 0 auto;
    }
    &--right {
      width: auto;
    }
  }

  &__contact-us {
    display: flex;
    align-items: center;

    &-number {
      font-size: 18px;
    }

    &-infos {
      display: none;
    }

    &-number-info {
      display: none;
    }

    &-agency {
      display: none;
    }

    &-link {
      font-size: 18px;
    }
  }

  &__switch {
    display: none;
  }

  &__language-selector {
    &--selected {
      font-size: 18px;
    }
  }

  &_global-wrapper {
    padding: 0 10px;
    display: flex;
    flex-wrap: wrap;
  }

  .sticky-menu-label { 
    order: 1;
    display: block;
    &__toggle-label {
      display: none !important;
    }
  }

  &__logos-wrapper {
    order: 1
  }

  &__logo-link-image {
    height: 30px;
    margin-right: 2px;
  }

  &__technical-links-wrapper {
    order: 2;
    margin-left: auto;
    height: 60px;
  }

  &__login i {
    font-size: 30px;
  }

  &__login-popup {
    display: none;
  }

  &__forms {
    order: 4;
    width: 100%;
    max-width: inherit;
    margin: 0 -10px;
  }

  &__search {
    width: 100%;
  }

  &__select-order {
    display: none;
  }

  &__login-text {
    display: none;
  }

  &__cart-wrapper i {
    font-size: 30px;
  }
  &__cart-link-caption { 
    display: none;
  }

  &__cart-link-amount { 
    display: none!important;
  }
  &__cart-count {
    right: -14px;
  }
}
