.reinsurance-bar-2 {

  .reinsurance-bar-2__carousel {

    .carousel-control-arrow {
      max-width: 100%;
      width: calc(100vw - 30px);

      .carousel-control {
        display: block;
      }
    }
  }
}
