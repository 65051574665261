.footer-contact-us {
  border-top: 1px solid #eeeef0;
  border-bottom: 1px solid #eeeef0;

  .col-md-4 {
    border-right: 1px solid #eeeef0;
    margin-bottom: 0;
    padding-top: 45px;
    padding-bottom: 45px;

    &:last-child {
      border: 0;
    }
  }

  .contact-section .generic-cnt {
    max-width: 280px;
  }

  .row.row--equal-height .panel.eshop-panel-default {
    background-color: #fbfbfb;
  }
}
