.well {
  border-radius: 0px;
  &.blue {
    background-color: #000033;
  }

  &.magenta {
    background-color: #F0027F;
  }

  &.green {
    background-color: #3fa535;
  }

  &.beige {
    background-color: #F0EDE7;
  }

  &.white {
    background-color: #FFFFFF;
  }
}