.footer-sitemap {
  border-top: 1px solid #eeeef0;
  background: #fbfbfb;


  .col-md-3 {
    border-right: 1px solid #eeeef0;

    padding-top: 50px;
    padding-bottom: 35px;
    margin-bottom: 0;

    &:last-child {
      border-right: 0;
    }
  }

  &__wrapper {
    padding: 0 20px 0;
    max-width: 100%;

    .footer-sitemap-section {
      &:first-child {
        .footer-sitemap-section__subtitle{
          &:first-child {
            margin-top: 37px;
          }
        }
      }
    }
  }
}
