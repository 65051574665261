.panoramic-gallery {

  &__carousel-control {
    display: block !important;
  }

  &__modal-body {
		top: calc(25vh / 2);
	}

  &__modal-footer {
    bottom: 0;
    margin-top: calc(30vh / 2);
  }

  &__carousel-popup-media {
		max-height: 200px;
	}

  &--full-width {
		position: relative;
		height: auto;
		overflow: hidden;

		.panoramic-gallery__media {
			max-height: 185px;
      overflow: hidden;
		}
	}

  &__media-text-wrapper {
    position: relative;
    top: 0;
    transform: translateY(0);
    width: 100%;
    left: 0;
    right: 0;
    background: #f6f6f7;
    border-bottom: 1px #eeeef0 solid;
    padding: 25px;
  }

  &__media-text-category,
  &__media-text-title,
  &__media-text-description {
    color: $dark-blue;
  }

  &__media-text-category {

    &::before {
      background: $dark-blue;
    }
  }

  &__media-text-title {
    font-size: 26px;
  }

  &__media-text-description {
    line-height: 48px;
  }

  &__media-text-wrapper-button {
    display: block;
    width: 100%;
  }

  .panoramic-gallery__media-text-wrapper-button + .panoramic-gallery__media-text-wrapper-button {
    margin-top: 25px;
  }
}
