@charset 'UTF-8';

@import
  // START BOOTSTRAP 3.4.1
  "bootstrap-341/variables"
  ,"bootstrap-341/mixins"
  ,"bootstrap-341/normalize"
  ,"bootstrap-341/print"
  ,"bootstrap-341/scaffolding"
  ,"bootstrap-341/type"
  ,"bootstrap-341/code"
  ,"bootstrap-341/grid"
  ,"bootstrap-341/tables"
  ,"bootstrap-341/forms"
  ,"bootstrap-341/buttons"
  ,"bootstrap-341/component-animations"
  ,"bootstrap-341/dropdowns"
  ,"bootstrap-341/button-groups"
  ,"bootstrap-341/input-groups"
  ,"bootstrap-341/navs"
  ,"bootstrap-341/breadcrumbs"
  ,"bootstrap-341/pager"
  ,"bootstrap-341/labels"
  ,"bootstrap-341/thumbnails"
  ,"bootstrap-341/alerts"
  ,"bootstrap-341/panels"
  ,"bootstrap-341/wells"
  ,"bootstrap-341/close"
  ,"bootstrap-341/modals"
  ,"bootstrap-341/tooltip"
  ,"bootstrap-341/popovers"
  ,"bootstrap-341/carousel"
  ,"bootstrap-341/utilities"
  ,"bootstrap-341/responsive-utilities"
  //,"bootstrap-341/glyphicons", "bootstrap-341/navbar", "bootstrap-341/pagination", "bootstrap-341/badges", "bootstrap-341/jumbotron", "bootstrap-341/progress-bars", "bootstrap-341/media", "bootstrap-341/list-group", "bootstrap-341/responsive-embed"
  // END BOOTSTRAP 3.4.1
  ,'base/reset'
  ,'base/colors'
  ,'base/config-rwd'
  ,'base/typography'
  ,'base/font'
  ,'base/font-awesome'
  ,'base/grid-settings'
  ,'base/buttons'
  ,'utils/mixins'
  ,'base/other'
  ,'vendor/add-this'
  ,'base/loader'
  ,'base/print'
  ,'utils/breakpoints'
  ,'utils/bootstrap-extra'
  ,'vendor/angular-selectbox'
  ,'../../node_modules/css-spaces/source/settings'
  ,'../../node_modules/css-spaces/source/margins'
  ,'../../node_modules/css-spaces/source/paddings'
  ,'../../node_modules/css-spaces/source/widths'
;

// COMPONENTS

// components xl:scss
@import "../components/c004__bubble-link/scss/bubble-link.xl";
@import "../components/c004__button/scss/button.xl";
@import "../components/c006__latest-content/scss/latest-content.xl";
@import "../components/c008__latest-item/scss/latest-item.xl";
@import "../components/c012__header/scss/header.xl";
@import "../components/c021__breadcrumb/scss/breadcrumb.xl";
@import "../components/c022__carousel-banner/scss/carousel-banner.xl";
@import "../components/c023__carousel-slider/scss/carousel-slider.xl";
@import "../components/c025__certifications/scss/certifications.xl";
@import "../components/c027__promotional-block/scss/promotional-block.xl";
@import "../components/c031__explore-antalis/scss/explore-antalis.xl";
@import "../components/c032__first-bar/scss/first-bar.xl";
@import "../components/c035__locations/scss/locations.xl";
@import "../components/c036__links-to-other-sites/scss/links-to-other-sites.xl";
@import "../components/c037__important-message/scss/important-message.xl";
@import "../components/c038__blue-text-and-image/scss/blue-text-and-image.xl";
@import "../components/c041__navigation/scss/navigation.xl";
@import "../components/c047__customer-segments/scss/customer-segments.xl";
@import "../components/c048__customer-segments-item/scss/customer-segments-item.xl";
@import "../components/c049__share-button/scss/share-button.xl";
@import "../components/c050__introductory-text/scss/introductory-text.xl";
@import "../components/c052__social-network/scss/social-network.xl";
@import "../components/c053__social-network-item/scss/social-network-item.xl";
@import "../components/c054__sticky-menu-label/scss/sticky-menu-label.xl";
@import "../components/c055__reinsurance-bar-2/scss/reinsurance-bar-2.xl";
@import "../components/c056__reinsurance-item/scss/reinsurance-item.xl";
@import "../components/c057__legal-links/scss/legal-links.xl";
@import "../components/c060__cookie-message/scss/cookie-message.xl";
@import "../components/c063__right-side-navigation/scss/right-side-navigation.xl";
@import "../components/c064__related-downloads/scss/related-downloads.xl";
@import "../components/c065__related-downloads-item/scss/related-downloads-item.xl";
@import "../components/c066__links/scss/links.xl";
@import "../components/c068__key-points/scss/key-points.xl";
@import "../components/c069__key-point-item/scss/key-point-item.xl";
@import "../components/c070__inpage-banner/scss/inpage-banner.xl";
@import "../components/c071__accordion/scss/accordion.xl";
@import "../components/c072__accordion-item/scss/accordion-item.xl";
@import "../components/c073__testimonial-carousel/scss/testimonial-carousel.xl";
@import "../components/c074__testimonial-carousel-item/scss/testimonial-carousel-item.xl";
@import "../components/c201__mobile-search/scss/mobile-search.xl";
@import "../components/c202__navigation--sector/scss/navigation--sector.xl";
@import "../components/c203__latest-content-title/scss/latest-content-title.xl";
@import "../components/c204__latest-content-filters/scss/latest-content-filters.xl";
@import "../components/c206__top-menu/scss/top-menu.xl";
@import "../components/c211__messages-container/scss/messages-container.xl";
@import "../components/c212__back-to-top/scss/back-to-top.xl";
@import "../components/c213__generic-cnt/scss/generic-cnt.xl";
@import "../components/c214__panoramic-gallery/scss/panoramic-gallery.xl";
@import "../components/c215__article-header/scss/article-header.xl";
@import "../components/c217__generic-video/scss/generic-video.xl";
@import "../components/c218__footer/scss/footer.xl";
@import "../components/c222__blockquote-with-cta/scss/blockquote-with-cta.xl";
@import "../components/c223__cards/scss/cards.xl";
@import "../components/c225__icon-text-contact-item/scss/icon-text-contact-item.xl";
@import "../components/c226__our-location/scss/our-location.xl";
@import "../components/c227__our-location-item/scss/our-location-item.xl";
@import "../components/c228__form-wrapper/scss/form-wrapper.xl";
@import "../components/c229__locations-map/scss/locations-map.xl";
@import "../components/c237__news-and-events/scss/news-and-events.xl";
@import "../components/c238__news-card/scss/news-card.xl";
@import "../components/c239__payment-options/scss/payment-options.xl";
@import "../components/c240__reinsurance-bar/scss/reinsurance-bar.xl";
@import "../components/c241__footer-sitemap/scss/footer-sitemap.xl";
@import "../components/c242__footer-contact-us/scss/footer-contact-us.xl";
@import "../components/c243__tools-and-services/scss/tools-and-services.xl";
@import "../components/c244__header-fast-panel/scss/header-fast-panel.xl";
@import "../components/c246__link-card/scss/link-card.xl";
@import "../components/c249__footer-sitemap-section/scss/footer-sitemap-section.xl";
@import "../components/c250__contact-section/scss/contact-section.xl";
@import "../components/c252__inpage-navigation/scss/inpage-navigation.xl";
@import "../components/c253__right-side-section/scss/right-side-section.xl";
@import "../components/c256__benefits/scss/benefits.xl";
@import "../components/c257__benefits-item/scss/benefits-item.xl";
@import "../components/c263__thank-you-cards/scss/thank-you-cards.xl";
@import "../components/c264__thank-you-cards-item/scss/thank-you-cards-item.xl";
@import "../components/c265__case-study-results/scss/case-study-results.xl";
@import "../components/c266__case-study-results-item/scss/case-study-results-item.xl";
@import "../components/c268__thank-you-download/scss/thank-you-download.xl";
@import "../components/c269__tagged-carousel/scss/tagged-carousel.xl";
@import "../components/c270__tagged-carousel-item/scss/tagged-carousel-item.xl";
@import "../components/c274__share-popup/scss/share-popup.xl";
@import "../components/c275__drop-down/scss/drop-down.xl";
@import "../components/c278__event-summary/scss/event-summary.xl";
@import "../components/c279__form-rating/scss/form-rating.xl";
@import "../components/c280__popular-brands/scss/popular-brands.xl";
@import "../components/c281__popular-brands-item/scss/popular-brands-item.xl";
@import "../components/c283__page-navigation/scss/page-navigation.xl";
@import "../components/c284__search-prelayer/scss/search-prelayer.xl";
@import "../components/c285__caterories/scss/caterories.xl";
@import "../components/c286__results-preview/scss/results-preview.xl";
@import "../components/c287__result-item/scss/result-item.xl";
@import "../components/c288__search-bar/scss/search-bar.xl";
@import "../components/c289__screen-overlay/scss/screen-overlay.xl";
@import "../components/c290__buttons-block/scss/buttons-block.xl";
@import "../components/c291__contact-line-blue/scss/contact-line-blue.xl";
@import "../components/c292__image-cover/scss/image-cover.xl";
@import "../components/c293__reinsurance-bar-footer/scss/reinsurance-bar-footer.xl";
@import "../components/c294__search-prelayer-light/scss/search-prelayer-light.xl";
@import "../components/c295__blog-edito/scss/blog-edito.xl";
@import "../components/c296__geolocation-popin/scss/geolocation-popin.xl";
@import "../components/c317__well/scss/well.xl";
// endinject
@include md-less {
  // components lg:scss
  @import "../components/c004__bubble-link/scss/bubble-link.lg";
  @import "../components/c004__button/scss/button.lg";
  @import "../components/c006__latest-content/scss/latest-content.lg";
  @import "../components/c008__latest-item/scss/latest-item.lg";
  @import "../components/c012__header/scss/header.lg";
  @import "../components/c021__breadcrumb/scss/breadcrumb.lg";
  @import "../components/c022__carousel-banner/scss/carousel-banner.lg";
  @import "../components/c023__carousel-slider/scss/carousel-slider.lg";
  @import "../components/c025__certifications/scss/certifications.lg";
  @import "../components/c027__promotional-block/scss/promotional-block.lg";
  @import "../components/c031__explore-antalis/scss/explore-antalis.lg";
  @import "../components/c032__first-bar/scss/first-bar.lg";
  @import "../components/c035__locations/scss/locations.lg";
  @import "../components/c036__links-to-other-sites/scss/links-to-other-sites.lg";
  @import "../components/c037__important-message/scss/important-message.lg";
  @import "../components/c038__blue-text-and-image/scss/blue-text-and-image.lg";
  @import "../components/c041__navigation/scss/navigation.lg";
  @import "../components/c047__customer-segments/scss/customer-segments.lg";
  @import "../components/c048__customer-segments-item/scss/customer-segments-item.lg";
  @import "../components/c049__share-button/scss/share-button.lg";
  @import "../components/c050__introductory-text/scss/introductory-text.lg";
  @import "../components/c052__social-network/scss/social-network.lg";
  @import "../components/c053__social-network-item/scss/social-network-item.lg";
  @import "../components/c054__sticky-menu-label/scss/sticky-menu-label.lg";
  @import "../components/c055__reinsurance-bar-2/scss/reinsurance-bar-2.lg";
  @import "../components/c056__reinsurance-item/scss/reinsurance-item.lg";
  @import "../components/c057__legal-links/scss/legal-links.lg";
  @import "../components/c060__cookie-message/scss/cookie-message.lg";
  @import "../components/c063__right-side-navigation/scss/right-side-navigation.lg";
  @import "../components/c064__related-downloads/scss/related-downloads.lg";
  @import "../components/c065__related-downloads-item/scss/related-downloads-item.lg";
  @import "../components/c066__links/scss/links.lg";
  @import "../components/c068__key-points/scss/key-points.lg";
  @import "../components/c069__key-point-item/scss/key-point-item.lg";
  @import "../components/c070__inpage-banner/scss/inpage-banner.lg";
  @import "../components/c071__accordion/scss/accordion.lg";
  @import "../components/c072__accordion-item/scss/accordion-item.lg";
  @import "../components/c073__testimonial-carousel/scss/testimonial-carousel.lg";
  @import "../components/c074__testimonial-carousel-item/scss/testimonial-carousel-item.lg";
  @import "../components/c201__mobile-search/scss/mobile-search.lg";
  @import "../components/c202__navigation--sector/scss/navigation--sector.lg";
  @import "../components/c203__latest-content-title/scss/latest-content-title.lg";
  @import "../components/c204__latest-content-filters/scss/latest-content-filters.lg";
  @import "../components/c206__top-menu/scss/top-menu.lg";
  @import "../components/c211__messages-container/scss/messages-container.lg";
  @import "../components/c212__back-to-top/scss/back-to-top.lg";
  @import "../components/c213__generic-cnt/scss/generic-cnt.lg";
  @import "../components/c214__panoramic-gallery/scss/panoramic-gallery.lg";
  @import "../components/c215__article-header/scss/article-header.lg";
  @import "../components/c217__generic-video/scss/generic-video.lg";
  @import "../components/c218__footer/scss/footer.lg";
  @import "../components/c222__blockquote-with-cta/scss/blockquote-with-cta.lg";
  @import "../components/c223__cards/scss/cards.lg";
  @import "../components/c226__our-location/scss/our-location.lg";
  @import "../components/c227__our-location-item/scss/our-location-item.lg";
  @import "../components/c228__form-wrapper/scss/form-wrapper.lg";
  @import "../components/c229__locations-map/scss/locations-map.lg";
  @import "../components/c237__news-and-events/scss/news-and-events.lg";
  @import "../components/c238__news-card/scss/news-card.lg";
  @import "../components/c239__payment-options/scss/payment-options.lg";
  @import "../components/c240__reinsurance-bar/scss/reinsurance-bar.lg";
  @import "../components/c241__footer-sitemap/scss/footer-sitemap.lg";
  @import "../components/c242__footer-contact-us/scss/footer-contact-us.lg";
  @import "../components/c243__tools-and-services/scss/tools-and-services.lg";
  @import "../components/c244__header-fast-panel/scss/header-fast-panel.lg";
  @import "../components/c246__link-card/scss/link-card.lg";
  @import "../components/c249__footer-sitemap-section/scss/footer-sitemap-section.lg";
  @import "../components/c250__contact-section/scss/contact-section.lg";
  @import "../components/c252__inpage-navigation/scss/inpage-navigation.lg";
  @import "../components/c253__right-side-section/scss/right-side-section.lg";
  @import "../components/c256__benefits/scss/benefits.lg";
  @import "../components/c257__benefits-item/scss/benefits-item.lg";
  @import "../components/c263__thank-you-cards/scss/thank-you-cards.lg";
  @import "../components/c264__thank-you-cards-item/scss/thank-you-cards-item.lg";
  @import "../components/c265__case-study-results/scss/case-study-results.lg";
  @import "../components/c266__case-study-results-item/scss/case-study-results-item.lg";
  @import "../components/c268__thank-you-download/scss/thank-you-download.lg";
  @import "../components/c269__tagged-carousel/scss/tagged-carousel.lg";
  @import "../components/c270__tagged-carousel-item/scss/tagged-carousel-item.lg";
  @import "../components/c274__share-popup/scss/share-popup.lg";
  @import "../components/c275__drop-down/scss/drop-down.lg";
  @import "../components/c278__event-summary/scss/event-summary.lg";
  @import "../components/c279__form-rating/scss/form-rating.lg";
  @import "../components/c280__popular-brands/scss/popular-brands.lg";
  @import "../components/c281__popular-brands-item/scss/popular-brands-item.lg";
  @import "../components/c284__search-prelayer/scss/search-prelayer.lg";
  @import "../components/c285__caterories/scss/caterories.lg";
  @import "../components/c286__results-preview/scss/results-preview.lg";
  @import "../components/c287__result-item/scss/result-item.lg";
  @import "../components/c288__search-bar/scss/search-bar.lg";
  @import "../components/c289__screen-overlay/scss/screen-overlay.lg";
  @import "../components/c290__buttons-block/scss/buttons-block.lg";
  @import "../components/c291__contact-line-blue/scss/contact-line-blue.lg";
  @import "../components/c292__image-cover/scss/image-cover.lg";
  @import "../components/c293__reinsurance-bar-footer/scss/reinsurance-bar-footer.lg";
  @import "../components/c294__search-prelayer-light/scss/search-prelayer-light.lg";
  @import "../components/c295__blog-edito/scss/blog-edito.lg";
  // endinject
}
@include sm-less {
  // components md:scss
  @import "../components/c004__bubble-link/scss/bubble-link.md";
  @import "../components/c004__button/scss/button.md";
  @import "../components/c006__latest-content/scss/latest-content.md";
  @import "../components/c008__latest-item/scss/latest-item.md";
  @import "../components/c012__header/scss/header.md";
  @import "../components/c021__breadcrumb/scss/breadcrumb.md";
  @import "../components/c022__carousel-banner/scss/carousel-banner.md";
  @import "../components/c023__carousel-slider/scss/carousel-slider.md";
  @import "../components/c025__certifications/scss/certifications.md";
  @import "../components/c027__promotional-block/scss/promotional-block.md";
  @import "../components/c031__explore-antalis/scss/explore-antalis.md";
  @import "../components/c032__first-bar/scss/first-bar.md";
  @import "../components/c035__locations/scss/locations.md";
  @import "../components/c036__links-to-other-sites/scss/links-to-other-sites.md";
  @import "../components/c037__important-message/scss/important-message.md";
  @import "../components/c038__blue-text-and-image/scss/blue-text-and-image.md";
  @import "../components/c041__navigation/scss/navigation.md";
  @import "../components/c047__customer-segments/scss/customer-segments.md";
  @import "../components/c048__customer-segments-item/scss/customer-segments-item.md";
  @import "../components/c049__share-button/scss/share-button.md";
  @import "../components/c050__introductory-text/scss/introductory-text.md";
  @import "../components/c052__social-network/scss/social-network.md";
  @import "../components/c053__social-network-item/scss/social-network-item.md";
  @import "../components/c054__sticky-menu-label/scss/sticky-menu-label.md";
  @import "../components/c055__reinsurance-bar-2/scss/reinsurance-bar-2.md";
  @import "../components/c056__reinsurance-item/scss/reinsurance-item.md";
  @import "../components/c057__legal-links/scss/legal-links.md";
  @import "../components/c060__cookie-message/scss/cookie-message.md";
  @import "../components/c063__right-side-navigation/scss/right-side-navigation.md";
  @import "../components/c064__related-downloads/scss/related-downloads.md";
  @import "../components/c065__related-downloads-item/scss/related-downloads-item.md";
  @import "../components/c066__links/scss/links.md";
  @import "../components/c068__key-points/scss/key-points.md";
  @import "../components/c069__key-point-item/scss/key-point-item.md";
  @import "../components/c070__inpage-banner/scss/inpage-banner.md";
  @import "../components/c071__accordion/scss/accordion.md";
  @import "../components/c072__accordion-item/scss/accordion-item.md";
  @import "../components/c073__testimonial-carousel/scss/testimonial-carousel.md";
  @import "../components/c074__testimonial-carousel-item/scss/testimonial-carousel-item.md";
  @import "../components/c201__mobile-search/scss/mobile-search.md";
  @import "../components/c202__navigation--sector/scss/navigation--sector.md";
  @import "../components/c203__latest-content-title/scss/latest-content-title.md";
  @import "../components/c204__latest-content-filters/scss/latest-content-filters.md";
  @import "../components/c206__top-menu/scss/top-menu.md";
  @import "../components/c211__messages-container/scss/messages-container.md";
  @import "../components/c212__back-to-top/scss/back-to-top.md";
  @import "../components/c213__generic-cnt/scss/generic-cnt.md";
  @import "../components/c214__panoramic-gallery/scss/panoramic-gallery.md";
  @import "../components/c215__article-header/scss/article-header.md";
  @import "../components/c217__generic-video/scss/generic-video.md";
  @import "../components/c218__footer/scss/footer.md";
  @import "../components/c222__blockquote-with-cta/scss/blockquote-with-cta.md";
  @import "../components/c223__cards/scss/cards.md";
  @import "../components/c225__icon-text-contact-item/scss/icon-text-contact-item.md";
  @import "../components/c226__our-location/scss/our-location.md";
  @import "../components/c227__our-location-item/scss/our-location-item.md";
  @import "../components/c228__form-wrapper/scss/form-wrapper.md";
  @import "../components/c229__locations-map/scss/locations-map.md";
  @import "../components/c237__news-and-events/scss/news-and-events.md";
  @import "../components/c238__news-card/scss/news-card.md";
  @import "../components/c239__payment-options/scss/payment-options.md";
  @import "../components/c240__reinsurance-bar/scss/reinsurance-bar.md";
  @import "../components/c241__footer-sitemap/scss/footer-sitemap.md";
  @import "../components/c242__footer-contact-us/scss/footer-contact-us.md";
  @import "../components/c243__tools-and-services/scss/tools-and-services.md";
  @import "../components/c244__header-fast-panel/scss/header-fast-panel.md";
  @import "../components/c246__link-card/scss/link-card.md";
  @import "../components/c249__footer-sitemap-section/scss/footer-sitemap-section.md";
  @import "../components/c250__contact-section/scss/contact-section.md";
  @import "../components/c252__inpage-navigation/scss/inpage-navigation.md";
  @import "../components/c253__right-side-section/scss/right-side-section.md";
  @import "../components/c256__benefits/scss/benefits.md";
  @import "../components/c257__benefits-item/scss/benefits-item.md";
  @import "../components/c263__thank-you-cards/scss/thank-you-cards.md";
  @import "../components/c264__thank-you-cards-item/scss/thank-you-cards-item.md";
  @import "../components/c265__case-study-results/scss/case-study-results.md";
  @import "../components/c266__case-study-results-item/scss/case-study-results-item.md";
  @import "../components/c268__thank-you-download/scss/thank-you-download.md";
  @import "../components/c269__tagged-carousel/scss/tagged-carousel.md";
  @import "../components/c270__tagged-carousel-item/scss/tagged-carousel-item.md";
  @import "../components/c274__share-popup/scss/share-popup.md";
  @import "../components/c275__drop-down/scss/drop-down.md";
  @import "../components/c278__event-summary/scss/event-summary.md";
  @import "../components/c279__form-rating/scss/form-rating.md";
  @import "../components/c280__popular-brands/scss/popular-brands.md";
  @import "../components/c281__popular-brands-item/scss/popular-brands-item.md";
  @import "../components/c283__page-navigation/scss/page-navigation.md";
  @import "../components/c284__search-prelayer/scss/search-prelayer.md";
  @import "../components/c285__caterories/scss/caterories.md";
  @import "../components/c286__results-preview/scss/results-preview.md";
  @import "../components/c287__result-item/scss/result-item.md";
  @import "../components/c288__search-bar/scss/search-bar.md";
  @import "../components/c289__screen-overlay/scss/screen-overlay.md";
  @import "../components/c290__buttons-block/scss/buttons-block.md";
  @import "../components/c291__contact-line-blue/scss/contact-line-blue.md";
  @import "../components/c292__image-cover/scss/image-cover.md";
  @import "../components/c293__reinsurance-bar-footer/scss/reinsurance-bar-footer.md";
  @import "../components/c294__search-prelayer-light/scss/search-prelayer-light.md";
  @import "../components/c295__blog-edito/scss/blog-edito.md";
  // endinject
}
@include xs {
  // components sm:scss
  @import "../components/c004__bubble-link/scss/bubble-link.sm";
  @import "../components/c004__button/scss/button.sm";
  @import "../components/c006__latest-content/scss/latest-content.sm";
  @import "../components/c008__latest-item/scss/latest-item.sm";
  @import "../components/c012__header/scss/header.sm";
  @import "../components/c021__breadcrumb/scss/breadcrumb.sm";
  @import "../components/c022__carousel-banner/scss/carousel-banner.sm";
  @import "../components/c023__carousel-slider/scss/carousel-slider.sm";
  @import "../components/c025__certifications/scss/certifications.sm";
  @import "../components/c027__promotional-block/scss/promotional-block.sm";
  @import "../components/c031__explore-antalis/scss/explore-antalis.sm";
  @import "../components/c032__first-bar/scss/first-bar.sm";
  @import "../components/c035__locations/scss/locations.sm";
  @import "../components/c036__links-to-other-sites/scss/links-to-other-sites.sm";
  @import "../components/c037__important-message/scss/important-message.sm";
  @import "../components/c038__blue-text-and-image/scss/blue-text-and-image.sm";
  @import "../components/c041__navigation/scss/navigation.sm";
  @import "../components/c047__customer-segments/scss/customer-segments.sm";
  @import "../components/c048__customer-segments-item/scss/customer-segments-item.sm";
  @import "../components/c049__share-button/scss/share-button.sm";
  @import "../components/c050__introductory-text/scss/introductory-text.sm";
  @import "../components/c052__social-network/scss/social-network.sm";
  @import "../components/c053__social-network-item/scss/social-network-item.sm";
  @import "../components/c054__sticky-menu-label/scss/sticky-menu-label.sm";
  @import "../components/c055__reinsurance-bar-2/scss/reinsurance-bar-2.sm";
  @import "../components/c056__reinsurance-item/scss/reinsurance-item.sm";
  @import "../components/c057__legal-links/scss/legal-links.sm";
  @import "../components/c060__cookie-message/scss/cookie-message.sm";
  @import "../components/c063__right-side-navigation/scss/right-side-navigation.sm";
  @import "../components/c064__related-downloads/scss/related-downloads.sm";
  @import "../components/c065__related-downloads-item/scss/related-downloads-item.sm";
  @import "../components/c066__links/scss/links.sm";
  @import "../components/c068__key-points/scss/key-points.sm";
  @import "../components/c069__key-point-item/scss/key-point-item.sm";
  @import "../components/c070__inpage-banner/scss/inpage-banner.sm";
  @import "../components/c071__accordion/scss/accordion.sm";
  @import "../components/c072__accordion-item/scss/accordion-item.sm";
  @import "../components/c073__testimonial-carousel/scss/testimonial-carousel.sm";
  @import "../components/c074__testimonial-carousel-item/scss/testimonial-carousel-item.sm";
  @import "../components/c201__mobile-search/scss/mobile-search.sm";
  @import "../components/c202__navigation--sector/scss/navigation--sector.sm";
  @import "../components/c203__latest-content-title/scss/latest-content-title.sm";
  @import "../components/c204__latest-content-filters/scss/latest-content-filters.sm";
  @import "../components/c206__top-menu/scss/top-menu.sm";
  @import "../components/c211__messages-container/scss/messages-container.sm";
  @import "../components/c212__back-to-top/scss/back-to-top.sm";
  @import "../components/c213__generic-cnt/scss/generic-cnt.sm";
  @import "../components/c214__panoramic-gallery/scss/panoramic-gallery.sm";
  @import "../components/c215__article-header/scss/article-header.sm";
  @import "../components/c217__generic-video/scss/generic-video.sm";
  @import "../components/c218__footer/scss/footer.sm";
  @import "../components/c222__blockquote-with-cta/scss/blockquote-with-cta.sm";
  @import "../components/c223__cards/scss/cards.sm";
  @import "../components/c225__icon-text-contact-item/scss/icon-text-contact-item.sm";
  @import "../components/c226__our-location/scss/our-location.sm";
  @import "../components/c227__our-location-item/scss/our-location-item.sm";
  @import "../components/c228__form-wrapper/scss/form-wrapper.sm";
  @import "../components/c229__locations-map/scss/locations-map.sm";
  @import "../components/c237__news-and-events/scss/news-and-events.sm";
  @import "../components/c238__news-card/scss/news-card.sm";
  @import "../components/c239__payment-options/scss/payment-options.sm";
  @import "../components/c240__reinsurance-bar/scss/reinsurance-bar.sm";
  @import "../components/c241__footer-sitemap/scss/footer-sitemap.sm";
  @import "../components/c242__footer-contact-us/scss/footer-contact-us.sm";
  @import "../components/c243__tools-and-services/scss/tools-and-services.sm";
  @import "../components/c244__header-fast-panel/scss/header-fast-panel.sm";
  @import "../components/c246__link-card/scss/link-card.sm";
  @import "../components/c249__footer-sitemap-section/scss/footer-sitemap-section.sm";
  @import "../components/c250__contact-section/scss/contact-section.sm";
  @import "../components/c252__inpage-navigation/scss/inpage-navigation.sm";
  @import "../components/c253__right-side-section/scss/right-side-section.sm";
  @import "../components/c256__benefits/scss/benefits.sm";
  @import "../components/c257__benefits-item/scss/benefits-item.sm";
  @import "../components/c263__thank-you-cards/scss/thank-you-cards.sm";
  @import "../components/c264__thank-you-cards-item/scss/thank-you-cards-item.sm";
  @import "../components/c265__case-study-results/scss/case-study-results.sm";
  @import "../components/c266__case-study-results-item/scss/case-study-results-item.sm";
  @import "../components/c268__thank-you-download/scss/thank-you-download.sm";
  @import "../components/c269__tagged-carousel/scss/tagged-carousel.sm";
  @import "../components/c270__tagged-carousel-item/scss/tagged-carousel-item.sm";
  @import "../components/c274__share-popup/scss/share-popup.sm";
  @import "../components/c275__drop-down/scss/drop-down.sm";
  @import "../components/c278__event-summary/scss/event-summary.sm";
  @import "../components/c279__form-rating/scss/form-rating.sm";
  @import "../components/c280__popular-brands/scss/popular-brands.sm";
  @import "../components/c281__popular-brands-item/scss/popular-brands-item.sm";
  @import "../components/c284__search-prelayer/scss/search-prelayer.sm";
  @import "../components/c285__caterories/scss/caterories.sm";
  @import "../components/c286__results-preview/scss/results-preview.sm";
  @import "../components/c287__result-item/scss/result-item.sm";
  @import "../components/c288__search-bar/scss/search-bar.sm";
  @import "../components/c289__screen-overlay/scss/screen-overlay.sm";
  @import "../components/c290__buttons-block/scss/buttons-block.sm";
  @import "../components/c291__contact-line-blue/scss/contact-line-blue.sm";
  @import "../components/c292__image-cover/scss/image-cover.sm";
  @import "../components/c293__reinsurance-bar-footer/scss/reinsurance-bar-footer.sm";
  @import "../components/c294__search-prelayer-light/scss/search-prelayer-light.sm";
  @import "../components/c295__blog-edito/scss/blog-edito.sm";
  // endinject
}
@media (max-width:$bp-xxs-max) {
  // components xs:scss
  @import "../components/c004__bubble-link/scss/bubble-link.xs";
  @import "../components/c004__button/scss/button.xs";
  @import "../components/c006__latest-content/scss/latest-content.xs";
  @import "../components/c008__latest-item/scss/latest-item.xs";
  @import "../components/c012__header/scss/header.xs";
  @import "../components/c021__breadcrumb/scss/breadcrumb.xs";
  @import "../components/c022__carousel-banner/scss/carousel-banner.xs";
  @import "../components/c023__carousel-slider/scss/carousel-slider.xs";
  @import "../components/c025__certifications/scss/certifications.xs";
  @import "../components/c027__promotional-block/scss/promotional-block.xs";
  @import "../components/c031__explore-antalis/scss/explore-antalis.xs";
  @import "../components/c032__first-bar/scss/first-bar.xs";
  @import "../components/c035__locations/scss/locations.xs";
  @import "../components/c036__links-to-other-sites/scss/links-to-other-sites.xs";
  @import "../components/c037__important-message/scss/important-message.xs";
  @import "../components/c038__blue-text-and-image/scss/blue-text-and-image.xs";
  @import "../components/c041__navigation/scss/navigation.xs";
  @import "../components/c047__customer-segments/scss/customer-segments.xs";
  @import "../components/c048__customer-segments-item/scss/customer-segments-item.xs";
  @import "../components/c049__share-button/scss/share-button.xs";
  @import "../components/c050__introductory-text/scss/introductory-text.xs";
  @import "../components/c052__social-network/scss/social-network.xs";
  @import "../components/c053__social-network-item/scss/social-network-item.xs";
  @import "../components/c054__sticky-menu-label/scss/sticky-menu-label.xs";
  @import "../components/c055__reinsurance-bar-2/scss/reinsurance-bar-2.xs";
  @import "../components/c056__reinsurance-item/scss/reinsurance-item.xs";
  @import "../components/c057__legal-links/scss/legal-links.xs";
  @import "../components/c060__cookie-message/scss/cookie-message.xs";
  @import "../components/c063__right-side-navigation/scss/right-side-navigation.xs";
  @import "../components/c064__related-downloads/scss/related-downloads.xs";
  @import "../components/c065__related-downloads-item/scss/related-downloads-item.xs";
  @import "../components/c066__links/scss/links.xs";
  @import "../components/c068__key-points/scss/key-points.xs";
  @import "../components/c069__key-point-item/scss/key-point-item.xs";
  @import "../components/c070__inpage-banner/scss/inpage-banner.xs";
  @import "../components/c071__accordion/scss/accordion.xs";
  @import "../components/c072__accordion-item/scss/accordion-item.xs";
  @import "../components/c073__testimonial-carousel/scss/testimonial-carousel.xs";
  @import "../components/c074__testimonial-carousel-item/scss/testimonial-carousel-item.xs";
  @import "../components/c201__mobile-search/scss/mobile-search.xs";
  @import "../components/c202__navigation--sector/scss/navigation--sector.xs";
  @import "../components/c203__latest-content-title/scss/latest-content-title.xs";
  @import "../components/c204__latest-content-filters/scss/latest-content-filters.xs";
  @import "../components/c206__top-menu/scss/top-menu.xs";
  @import "../components/c211__messages-container/scss/messages-container.xs";
  @import "../components/c212__back-to-top/scss/back-to-top.xs";
  @import "../components/c213__generic-cnt/scss/generic-cnt.xs";
  @import "../components/c214__panoramic-gallery/scss/panoramic-gallery.xs";
  @import "../components/c215__article-header/scss/article-header.xs";
  @import "../components/c217__generic-video/scss/generic-video.xs";
  @import "../components/c218__footer/scss/footer.xs";
  @import "../components/c222__blockquote-with-cta/scss/blockquote-with-cta.xs";
  @import "../components/c223__cards/scss/cards.xs";
  @import "../components/c226__our-location/scss/our-location.xs";
  @import "../components/c227__our-location-item/scss/our-location-item.xs";
  @import "../components/c228__form-wrapper/scss/form-wrapper.xs";
  @import "../components/c229__locations-map/scss/locations-map.xs";
  @import "../components/c237__news-and-events/scss/news-and-events.xs";
  @import "../components/c238__news-card/scss/news-card.xs";
  @import "../components/c239__payment-options/scss/payment-options.xs";
  @import "../components/c240__reinsurance-bar/scss/reinsurance-bar.xs";
  @import "../components/c241__footer-sitemap/scss/footer-sitemap.xs";
  @import "../components/c242__footer-contact-us/scss/footer-contact-us.xs";
  @import "../components/c243__tools-and-services/scss/tools-and-services.xs";
  @import "../components/c244__header-fast-panel/scss/header-fast-panel.xs";
  @import "../components/c246__link-card/scss/link-card.xs";
  @import "../components/c249__footer-sitemap-section/scss/footer-sitemap-section.xs";
  @import "../components/c250__contact-section/scss/contact-section.xs";
  @import "../components/c252__inpage-navigation/scss/inpage-navigation.xs";
  @import "../components/c253__right-side-section/scss/right-side-section.xs";
  @import "../components/c256__benefits/scss/benefits.xs";
  @import "../components/c257__benefits-item/scss/benefits-item.xs";
  @import "../components/c263__thank-you-cards/scss/thank-you-cards.xs";
  @import "../components/c264__thank-you-cards-item/scss/thank-you-cards-item.xs";
  @import "../components/c265__case-study-results/scss/case-study-results.xs";
  @import "../components/c266__case-study-results-item/scss/case-study-results-item.xs";
  @import "../components/c268__thank-you-download/scss/thank-you-download.xs";
  @import "../components/c269__tagged-carousel/scss/tagged-carousel.xs";
  @import "../components/c270__tagged-carousel-item/scss/tagged-carousel-item.xs";
  @import "../components/c274__share-popup/scss/share-popup.xs";
  @import "../components/c275__drop-down/scss/drop-down.xs";
  @import "../components/c278__event-summary/scss/event-summary.xs";
  @import "../components/c279__form-rating/scss/form-rating.xs";
  @import "../components/c280__popular-brands/scss/popular-brands.xs";
  @import "../components/c281__popular-brands-item/scss/popular-brands-item.xs";
  @import "../components/c284__search-prelayer/scss/search-prelayer.xs";
  @import "../components/c285__caterories/scss/caterories.xs";
  @import "../components/c286__results-preview/scss/results-preview.xs";
  @import "../components/c287__result-item/scss/result-item.xs";
  @import "../components/c288__search-bar/scss/search-bar.xs";
  @import "../components/c289__screen-overlay/scss/screen-overlay.xs";
  @import "../components/c290__buttons-block/scss/buttons-block.xs";
  @import "../components/c291__contact-line-blue/scss/contact-line-blue.xs";
  @import "../components/c292__image-cover/scss/image-cover.xs";
  @import "../components/c293__reinsurance-bar-footer/scss/reinsurance-bar-footer.xs";
  @import "../components/c294__search-prelayer-light/scss/search-prelayer-light.xs";
  @import "../components/c295__blog-edito/scss/blog-edito.xs";
  // endinject
}
// components animations:scss
@import "../components/c004__bubble-link/scss/bubble-link.animations";
@import "../components/c004__button/scss/button.animations";
@import "../components/c006__latest-content/scss/latest-content.animations";
@import "../components/c008__latest-item/scss/latest-item.animations";
@import "../components/c012__header/scss/header.animations";
@import "../components/c021__breadcrumb/scss/breadcrumb.animations";
@import "../components/c022__carousel-banner/scss/carousel-banner.animations";
@import "../components/c023__carousel-slider/scss/carousel-slider.animations";
@import "../components/c025__certifications/scss/certifications.animations";
@import "../components/c027__promotional-block/scss/promotional-block.animations";
@import "../components/c031__explore-antalis/scss/explore-antalis.animations";
@import "../components/c032__first-bar/scss/first-bar.animations";
@import "../components/c035__locations/scss/locations.animations";
@import "../components/c036__links-to-other-sites/scss/links-to-other-sites.animations";
@import "../components/c037__important-message/scss/important-message.animations";
@import "../components/c038__blue-text-and-image/scss/blue-text-and-image.animations";
@import "../components/c041__navigation/scss/navigation.animations";
@import "../components/c047__customer-segments/scss/customer-segments.animations";
@import "../components/c048__customer-segments-item/scss/customer-segments-item.animations";
@import "../components/c049__share-button/scss/share-button.animations";
@import "../components/c050__introductory-text/scss/introductory-text.animations";
@import "../components/c052__social-network/scss/social-network.animations";
@import "../components/c053__social-network-item/scss/social-network-item.animations";
@import "../components/c054__sticky-menu-label/scss/sticky-menu-label.animations";
@import "../components/c055__reinsurance-bar-2/scss/reinsurance-bar-2.animations";
@import "../components/c056__reinsurance-item/scss/reinsurance-item.animations";
@import "../components/c057__legal-links/scss/legal-links.animations";
@import "../components/c060__cookie-message/scss/cookie-message.animations";
@import "../components/c063__right-side-navigation/scss/right-side-navigation.animations";
@import "../components/c064__related-downloads/scss/related-downloads.animations";
@import "../components/c065__related-downloads-item/scss/related-downloads-item.animations";
@import "../components/c066__links/scss/links.animations";
@import "../components/c068__key-points/scss/key-points.animations";
@import "../components/c069__key-point-item/scss/key-point-item.animations";
@import "../components/c070__inpage-banner/scss/inpage-banner.animations";
@import "../components/c071__accordion/scss/accordion.animations";
@import "../components/c072__accordion-item/scss/accordion-item.animations";
@import "../components/c073__testimonial-carousel/scss/testimonial-carousel.animations";
@import "../components/c074__testimonial-carousel-item/scss/testimonial-carousel-item.animations";
@import "../components/c201__mobile-search/scss/mobile-search.animations";
@import "../components/c202__navigation--sector/scss/navigation--sector.animations";
@import "../components/c203__latest-content-title/scss/latest-content-title.animations";
@import "../components/c204__latest-content-filters/scss/latest-content-filters.animations";
@import "../components/c206__top-menu/scss/top-menu.animations";
@import "../components/c211__messages-container/scss/messages-container.animations";
@import "../components/c212__back-to-top/scss/back-to-top.animations";
@import "../components/c213__generic-cnt/scss/generic-cnt.animations";
@import "../components/c214__panoramic-gallery/scss/panoramic-gallery.animations";
@import "../components/c215__article-header/scss/article-header.animations";
@import "../components/c217__generic-video/scss/generic-video.animations";
@import "../components/c218__footer/scss/footer.animations";
@import "../components/c222__blockquote-with-cta/scss/blockquote-with-cta.animations";
@import "../components/c223__cards/scss/cards.animations";
@import "../components/c226__our-location/scss/our-location.animations";
@import "../components/c227__our-location-item/scss/our-location-item.animations";
@import "../components/c228__form-wrapper/scss/form-wrapper.animations";
@import "../components/c229__locations-map/scss/locations-map.animations";
@import "../components/c237__news-and-events/scss/news-and-events.animations";
@import "../components/c238__news-card/scss/news-card.animations";
@import "../components/c239__payment-options/scss/payment-options.animations";
@import "../components/c240__reinsurance-bar/scss/reinsurance-bar.animations";
@import "../components/c241__footer-sitemap/scss/footer-sitemap.animations";
@import "../components/c242__footer-contact-us/scss/footer-contact-us.animations";
@import "../components/c243__tools-and-services/scss/tools-and-services.animations";
@import "../components/c244__header-fast-panel/scss/header-fast-panel.animations";
@import "../components/c246__link-card/scss/link-card.animations";
@import "../components/c249__footer-sitemap-section/scss/footer-sitemap-section.animations";
@import "../components/c250__contact-section/scss/contact-section.animations";
@import "../components/c252__inpage-navigation/scss/inpage-navigation.animations";
@import "../components/c253__right-side-section/scss/right-side-section.animations";
@import "../components/c256__benefits/scss/benefits.animations";
@import "../components/c257__benefits-item/scss/benefits-item.animations";
@import "../components/c263__thank-you-cards/scss/thank-you-cards.animations";
@import "../components/c264__thank-you-cards-item/scss/thank-you-cards-item.animations";
@import "../components/c265__case-study-results/scss/case-study-results.animations";
@import "../components/c266__case-study-results-item/scss/case-study-results-item.animations";
@import "../components/c268__thank-you-download/scss/thank-you-download.animations";
@import "../components/c269__tagged-carousel/scss/tagged-carousel.animations";
@import "../components/c270__tagged-carousel-item/scss/tagged-carousel-item.animations";
@import "../components/c274__share-popup/scss/share-popup.animations";
@import "../components/c275__drop-down/scss/drop-down.animations";
@import "../components/c278__event-summary/scss/event-summary.animations";
@import "../components/c279__form-rating/scss/form-rating.animations";
@import "../components/c280__popular-brands/scss/popular-brands.animations";
@import "../components/c281__popular-brands-item/scss/popular-brands-item.animations";
@import "../components/c284__search-prelayer/scss/search-prelayer.animations";
@import "../components/c285__caterories/scss/caterories.animations";
@import "../components/c286__results-preview/scss/results-preview.animations";
@import "../components/c287__result-item/scss/result-item.animations";
@import "../components/c288__search-bar/scss/search-bar.animations";
@import "../components/c289__screen-overlay/scss/screen-overlay.animations";
@import "../components/c290__buttons-block/scss/buttons-block.animations";
@import "../components/c291__contact-line-blue/scss/contact-line-blue.animations";
@import "../components/c292__image-cover/scss/image-cover.animations";
@import "../components/c293__reinsurance-bar-footer/scss/reinsurance-bar-footer.animations";
@import "../components/c294__search-prelayer-light/scss/search-prelayer.animations";
@import "../components/c295__blog-edito/scss/blog-edito.animations";
// endinject

// PAGES

// pages xl:scss
@import "../templates/pages/t02__sector/scss/sector.xl";
@import "../templates/pages/t03__eshop-homepage/scss/eshop-homepage.xl";
@import "../templates/pages/t201__test__promotional-block/scss/test__promotional-block.xl";
@import "../templates/pages/t202__test__latest-content-data/scss/test__latest-content-data.xl";
@import "../templates/pages/t203__test__carousel-slider/scss/test-carousel-slider.xl";
@import "../templates/pages/t204__test__generic-cnt/scss/test__generic-cnt.xl";
@import "../templates/pages/t206__test__trus-carousel/scss/test__trus-carousel.xl";
@import "../templates/pages/t207__test__thank-you-cards/scss/test__thank-you-cards.xl";
@import "../templates/pages/t212__test__accordion/scss/test__accordion.xl";
@import "../templates/pages/t214__test__form-rating/scss/test__form-rating.xl";
@import "../templates/pages/t209__test__panoramic-gallery/scss/test__panoramic-gallery.xl";
@import "../templates/pages/t205__test__blue-text-and-image/scss/test__blue-text-and-image.xl";
@import "../templates/pages/t302__test__selectbox/scss/test__selectbox.xl";
@import "../templates/pages/t04__contact-us/scss/contact-us.xl";
@import "../templates/pages/t304__test__popular-brands/scss/test__popular-brands.xl";
@import "../templates/pages/t305__test__news-events/scss/test__news-events.xl";
@import "../templates/pages/t402__test__data-prelayer/scss/test__data-prelayer.xl";
@import "../templates/pages/t403__test__blog-edito/scss/test__blog-edito.xl";
@import "../templates/pages/t303__test__datapicker/scss/test__datapicker.xl";
// endinject
@include md-less {
  // pages lg:scss
  @import "../templates/pages/t02__sector/scss/sector.lg";
  @import "../templates/pages/t03__eshop-homepage/scss/eshop-homepage.lg";
  @import "../templates/pages/t201__test__promotional-block/scss/test__promotional-block.lg";
  @import "../templates/pages/t202__test__latest-content-data/scss/test__latest-content-data.lg";
  @import "../templates/pages/t203__test__carousel-slider/scss/test-carousel-slider.lg";
  @import "../templates/pages/t204__test__generic-cnt/scss/test__generic-cnt.lg";
  @import "../templates/pages/t206__test__trus-carousel/scss/test__trus-carousel.lg";
  @import "../templates/pages/t207__test__thank-you-cards/scss/test__thank-you-cards.lg";
  @import "../templates/pages/t212__test__accordion/scss/test__accordion.lg";
  @import "../templates/pages/t214__test__form-rating/scss/test__form-rating.lg";
  @import "../templates/pages/t209__test__panoramic-gallery/scss/test__panoramic-gallery.lg";
  @import "../templates/pages/t205__test__blue-text-and-image/scss/test__blue-text-and-image.lg";
  @import "../templates/pages/t302__test__selectbox/scss/test__selectbox.lg";
  @import "../templates/pages/t04__contact-us/scss/contact-us.lg";
  @import "../templates/pages/t304__test__popular-brands/scss/test__popular-brands.lg";
  @import "../templates/pages/t305__test__news-events/scss/test__news-events.lg";
  @import "../templates/pages/t402__test__data-prelayer/scss/test__data-prelayer.lg";
  @import "../templates/pages/t403__test__blog-edito/scss/test__blog-edito.lg";
  @import "../templates/pages/t303__test__datapicker/scss/test__datapicker.lg";
  // endinject
}
@include sm-less {
  // pages md:scss
  @import "../templates/pages/t02__sector/scss/sector.md";
  @import "../templates/pages/t03__eshop-homepage/scss/eshop-homepage.md";
  @import "../templates/pages/t201__test__promotional-block/scss/test__promotional-block.md";
  @import "../templates/pages/t202__test__latest-content-data/scss/test__latest-content-data.md";
  @import "../templates/pages/t203__test__carousel-slider/scss/test-carousel-slider.md";
  @import "../templates/pages/t204__test__generic-cnt/scss/test__generic-cnt.md";
  @import "../templates/pages/t206__test__trus-carousel/scss/test__trus-carousel.md";
  @import "../templates/pages/t207__test__thank-you-cards/scss/test__thank-you-cards.md";
  @import "../templates/pages/t212__test__accordion/scss/test__accordion.md";
  @import "../templates/pages/t214__test__form-rating/scss/test__form-rating.md";
  @import "../templates/pages/t209__test__panoramic-gallery/scss/test__panoramic-gallery.md";
  @import "../templates/pages/t205__test__blue-text-and-image/scss/test__blue-text-and-image.md";
  @import "../templates/pages/t302__test__selectbox/scss/test__selectbox.md";
  @import "../templates/pages/t04__contact-us/scss/contact-us.md";
  @import "../templates/pages/t304__test__popular-brands/scss/test__popular-brands.md";
  @import "../templates/pages/t305__test__news-events/scss/test__news-events.md";
  @import "../templates/pages/t402__test__data-prelayer/scss/test__data-prelayer.md";
  @import "../templates/pages/t403__test__blog-edito/scss/test__blog-edito.md";
  @import "../templates/pages/t303__test__datapicker/scss/test__datapicker.md";
  // endinject
}
@include xs {
  // pages sm:scss
  @import "../templates/pages/t02__sector/scss/sector.sm";
  @import "../templates/pages/t03__eshop-homepage/scss/eshop-homepage.sm";
  @import "../templates/pages/t201__test__promotional-block/scss/test__promotional-block.sm";
  @import "../templates/pages/t202__test__latest-content-data/scss/test__latest-content-data.sm";
  @import "../templates/pages/t203__test__carousel-slider/scss/test-carousel-slider.sm";
  @import "../templates/pages/t204__test__generic-cnt/scss/test__generic-cnt.sm";
  @import "../templates/pages/t206__test__trus-carousel/scss/test__trus-carousel.sm";
  @import "../templates/pages/t207__test__thank-you-cards/scss/test__thank-you-cards.sm";
  @import "../templates/pages/t212__test__accordion/scss/test__accordion.sm";
  @import "../templates/pages/t214__test__form-rating/scss/test__form-rating.sm";
  @import "../templates/pages/t209__test__panoramic-gallery/scss/test__panoramic-gallery.sm";
  @import "../templates/pages/t205__test__blue-text-and-image/scss/test__blue-text-and-image.sm";
  @import "../templates/pages/t302__test__selectbox/scss/test__selectbox.sm";
  @import "../templates/pages/t04__contact-us/scss/contact-us.sm";
  @import "../templates/pages/t304__test__popular-brands/scss/test__popular-brands.sm";
  @import "../templates/pages/t305__test__news-events/scss/test__news-events.sm";
  @import "../templates/pages/t402__test__data-prelayer/scss/test__data-prelayer.sm";
  @import "../templates/pages/t403__test__blog-edito/scss/test__blog-edito.sm";
  @import "../templates/pages/t303__test__datapicker/scss/test__datapicker.sm";
  // endinject
}
@media (max-width:$bp-xxs-max) {
  // pages xs:scss
  @import "../templates/pages/t02__sector/scss/sector.xs";
  @import "../templates/pages/t03__eshop-homepage/scss/eshop-homepage.xs";
  @import "../templates/pages/t201__test__promotional-block/scss/test__promotional-block.xs";
  @import "../templates/pages/t202__test__latest-content-data/scss/test__latest-content-data.xs";
  @import "../templates/pages/t203__test__carousel-slider/scss/test-carousel-slider.xs";
  @import "../templates/pages/t204__test__generic-cnt/scss/test__generic-cnt.xs";
  @import "../templates/pages/t206__test__trus-carousel/scss/test__trus-carousel.xs";
  @import "../templates/pages/t207__test__thank-you-cards/scss/test__thank-you-cards.xs";
  @import "../templates/pages/t212__test__accordion/scss/test__accordion.xs";
  @import "../templates/pages/t214__test__form-rating/scss/test__form-rating.xs";
  @import "../templates/pages/t209__test__panoramic-gallery/scss/test__panoramic-gallery.xs";
  @import "../templates/pages/t205__test__blue-text-and-image/scss/test__blue-text-and-image.xs";
  @import "../templates/pages/t302__test__selectbox/scss/test__selectbox.xs";
  @import "../templates/pages/t04__contact-us/scss/contact-us.xs";
  @import "../templates/pages/t304__test__popular-brands/scss/test__popular-brands.xs";
  @import "../templates/pages/t305__test__news-events/scss/test__news-events.xs";
  @import "../templates/pages/t402__test__data-prelayer/scss/test__data-prelayer.xs";
  @import "../templates/pages/t403__test__blog-edito/scss/test__blog-edito.xs";
  @import "../templates/pages/t303__test__datapicker/scss/test__datapicker.xs";
  // endinject
}
// pages animations:scss
@import "../templates/pages/t02__sector/scss/sector.animations";
@import "../templates/pages/t03__eshop-homepage/scss/eshop-homepage.animations";
@import "../templates/pages/t201__test__promotional-block/scss/test__promotional-block.animations";
@import "../templates/pages/t202__test__latest-content-data/scss/test__latest-content-data.animations";
@import "../templates/pages/t203__test__carousel-slider/scss/test-carousel-slider.animations";
@import "../templates/pages/t204__test__generic-cnt/scss/test__generic-cnt.animations";
@import "../templates/pages/t206__test__trus-carousel/scss/test__trus-carousel.animations";
@import "../templates/pages/t207__test__thank-you-cards/scss/test__thank-you-cards.animations";
@import "../templates/pages/t212__test__accordion/scss/test__accordion.animations";
@import "../templates/pages/t214__test__form-rating/scss/test__form-rating.animations";
@import "../templates/pages/t209__test__panoramic-gallery/scss/test__panoramic-gallery.animations";
@import "../templates/pages/t205__test__blue-text-and-image/scss/test__blue-text-and-image.animations";
@import "../templates/pages/t302__test__selectbox/scss/test__selectbox.animations";
@import "../templates/pages/t04__contact-us/scss/contact-us.animations";
@import "../templates/pages/t304__test__popular-brands/scss/test__popular-brands.animations";
@import "../templates/pages/t305__test__news-events/scss/test__news-events.animations";
@import "../templates/pages/t402__test__data-prelayer/scss/test__data-prelayer.animations";
@import "../templates/pages/t403__test__blog-edito/scss/test__blog-edito.animations";
@import "../templates/pages/t303__test__datapicker/scss/test__datapicker.animations";
// endinject