.benefits {

  .col-md-4 {

    &:nth-child(n+1) {
      border-left: 0;
      border-right: 0;
    }
  }
}
