.footer-sitemap-section {
  color: $dark-blue;

  &__title {
    font-size: 20px;
    line-height: 1.2;
    font-weight: 700;
    margin-bottom: 10px;
  }

  &__subtitle {
    text-transform: uppercase;
    font-weight: 700;
    line-height: 1.2;
    margin-bottom: 10px;
  }

  &__items {
    max-width: 230px;
    margin: 0 0 15px;
  }

  &__item {
    display: block;
  }

  &__item-link {
    color: $dark-blue;
    text-decoration: none;
    line-height: 24px;

    &:hover {
      text-decoration: underline;
      color: $pink;
    }
  }

  &__wrapper-image {
    position: relative;
    display: block;
    overflow: hidden;
    max-width: 230px;
    max-height: 130px;
  }

  &__play {
    display: block;
    position: absolute;
    border-radius: 50%;
    width: 55px;
    height: 55px;
    background: rgba(255,255,255, .8);
    text-align: center;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 50%;

    > .fa-play {
      font-size: 25px;
      line-height: 55px;
      color: $dark-blue;
    }
  }

  &__image {
    max-width: 230px;
  }
}
