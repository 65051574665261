.article-header {

  &__info-panel {
    display: block;
    margin-bottom: 0;
  }

  &__share,
  &__print,
  &__comments {
    display: inline-flex;
    margin-bottom: 35px;
  }

  &__share {
    margin-right: 15px;
  }

  &__print {
    margin-left: 0;
  }

}
