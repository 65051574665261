.btn-pink {
    padding: 0 48px;
    color: #FFF;
    background-color: #F0027F;
    min-height: 50px;
    border: 0 none;
    min-width: 200px;
    font-family: 'Source Sans Pro', Verdana, sans-serif;
    font-size: 1.8rem;
}
