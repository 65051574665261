.benefits-item {
  padding: 55px 45px;
  text-align: center;
  width: 100%;

  &__image {
    margin-bottom: 25px;
  }

  &__title {
    margin-bottom: 20px;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    text-transform: uppercase;
  }

  &__description {
    font-size: 18px;
    line-height: 24px;
  }
}
