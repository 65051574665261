.key-point-item {
  padding: 0 10px;
  width: 100%;
  margin-left: 0;
  margin-right: 0;

  &__icon {
    display: none;
  }

  &__text {
    padding: 0;
    border: 0;
    width: 100%;
  }

  &__text-description {
    margin-bottom: 30px;
    line-height: 24px;
    font-size: 16px;
  }
}
