@import 'config-rwd';
@import 'grid-settings';

p { font-size:18px; line-height:24px; color:#000033; }
p + p, p + ul, p + ol, p + a, p + link, p + nav, p + button, p + table, p + h1, p + h2, p + h3, p + h4, p + h5, p + h6, p + video, p + audio, p + source, p + track, p + img, p + picture, p + b, p + strong, p + form, p + input, p + textarea, p + select, p + option, p + label, p + legend, p + datalist, p + output, p + optgroup, p + iframe, p + frame, p + noframes, p + div, p + span, p + section, p + article, p + aside, p + dialog, p + summary, p + data, p + main, p + dir, p + dl, p + dd, p + menu, p + map, p + figure, p + area, p + canvas, p + figcaption, p + blockquote, p + abbr, p + address, p + bdi, p + bdo, p + big, p + center, p + cite, p + code, p + del, p + dfn, p + em, p + ins, p + kbd, p + mark, p + meter, p + pre, p + progress, p + input, p + q, p + rp, p + rt, p + ruby, p + s, p + sub, p + time, p + tt, p + u, p + var, p + wbr, p + strike{ margin-top:10px; }

a {
  color:#000033; text-decoration:none; border-bottom:1px #7c7a8c solid; 
  &:hover, &:active { color:#F0027F; border:0; }
}
strong { font-weight:700; }
.highlight { background:#daf0f6; }
small { font-size:14px; line-height:18px; color: #7c7a8c; }

h1 { 
  font-size:40px; line-height:48px; margin-bottom:40px; color:#000033; font-weight:700; @include md-less { font-size:38px; line-height:44px; margin-bottom:38px; }
  @include sm-less { font-size:34px; line-height:39px; margin-bottom:34px; }
  @include xs { font-size:30px; line-height:35px; margin-bottom:30px; }
  @include xxs { font-size:20px; line-height:28px; margin-bottom:24px; }
}
h2 { font-size:32px; line-height:40px; margin-bottom:32px; padding-top:32px; font-weight:700; }
h3 { font-size:24px; line-height:32px; margin-bottom:24px; color:#000033; }
h4 { font-size:22px; line-height:32px; margin-bottom:32px; color:#000033; text-transform:uppercase; font-weight:300; }
h5 { font-size:18px; line-height:24px; margin-bottom:32px; color:#000033; text-transform:uppercase; font-weight:700; }
h6 { font-size:16px; line-height:24px; margin-bottom:32px; color:#000033; text-transform:uppercase; font-family:$font-normal; }

.intro-copy { 
  font-size:24px; color:#000033; line-height:32px; font-weight:300; 
  @include sm-less { font-size:16px; line-height:24px; }
}
.descriptive-text { font-size:16px; line-height:24px; color:#514e65; font-family:$font-normal; }

@include sm-less { 
  h2 { font-size:22px; line-height:30px; margin-bottom:22px; padding-top:22px; }
  h3 { font-size:16px; line-height:22px; margin-bottom:16px; }   
  .descriptive-text { font-size:14px; line-height:18px; }
}

ul:not(.cke_panel_list) { font-size:18px; line-height:24px; list-style:none;
  li {
    &::before { display:inline-block; content:''; background:url('images/bubble-black.svg') center center no-repeat; width:6px; height:18px; margin-right:5px; vertical-align:middle; }
    ul, ol { padding-left:20px; }
  }
}
ul.list-secondary { font-family:$font-normal;
  > li { 
    &::before { background:url('images/arrow-right.svg') center center no-repeat; width:7px; height:18px; margin-right:5px; vertical-align:middle; }
  }
}
ul.list-checked { font-family:$font-normal;
  > li { 
    &::before { background:url('images/check.svg') center center no-repeat; background-size:14px; width:15px; height:18px; margin-right:10px; vertical-align:middle; color:$pink; }
  }
}
ul.list-circle { 
  font-family:$font-normal;
  > li { 
    &::before { background:url('images/bubble-pink.svg') center center no-repeat; background-size:8px 8px; width:10px; height:18px; margin-right:5px; vertical-align:sub; top:-1px; position:relative; }
  }
}
ol { list-style:decimal; padding-left:0; margin-left:17px; font-size:18px; line-height:24px; font-family:$font-normal;
  > li { 
    list-style:decimal; font-family:$font-normal;
    &::before { display:none !important; background:none; }
    ul, ol { padding-left:20px; }
  }
}
p { 
  q { display:block; font-size:20px; line-height:32px; font-weight:700;
    &::before { content:""; display:block; height:4px; width:50px; background:$pink; margin:20px 0; }
  }
  cite { display:block; font-size:16px; line-height:24px; text-transform:uppercase; font-weight:300; margin-bottom:32px; }
}
table { font-size:18px; line-height:24px; width:100%; }
table thead tr { background:white; }
table thead tr td { font-weight:700; }
table tr { height:56px; border-left:1px solid #d8d8d8; border-right:1px solid #d8d8d8; }
table tr th, table tr td { border-top:1px solid #d8d8d8; border-bottom:1px solid #d8d8d8; vertical-align:middle; padding:2px 5px; }
table tbody tr {
  &:nth-child(odd) { background:#fbfbfb; }
  &:nth-child(even) { background:white; }
}
.generic-cnt__media { 
  display:flex; flex-direction:column;
  &--left { float:left; }
  &--right { float:right; }
}
video, img, iframe { 
  &[align="right"] { margin-left:18px; float:right;
    & + .generic-cnt__media-title { padding-left:18px; }
  }
  &[align="left"] { margin-right:18px; float:left;
    & + .generic-cnt__media-title { padding-right:18px; }
  }
  & + .generic-cnt__media-title { font-size:.75em; margin:-10px 0 18px; }
  @include xxs {
    &, &[align="left"], &[align="right"] { width:100%; display:block; margin:18px 0; float:none; }
  }
}
@include xxs {
  p { font-size:16px; line-height:24px; }
  ul:not(.cke_panel_list) { font-size:16px; }
  ol { font-size:16px; }
  table { font-size:16px; }
}
.blue {
  color: #000033;
}
.magenta {
  color: #F0027F;
}
.green {
  color: #3fa535;
}
.white {
  color: #FFFFFF;
}
em{
  font-style: italic;
}
// Add a bground color for the style list item for White of ckeditor
.cke_panel_listItem .white{
  background-color: #000;
}