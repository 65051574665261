//font
$font-normal:              'Source Sans Pro', Verdana, sans-serif;

// breakppoint & media query
$bp-xs:                     544px;
$bp-sm:                     768px;
$bp-md:                     992px;
$bp-lg:                     1200px;

$bp-xxs-max:                543px;
$bp-xs-max:                 767px;
$bp-sm-min:                 768px;
$bp-sm-max:                 991px;
$bp-md-min:                 992px;
$bp-md-max:                 1199px;
$bp-lg-min:                 1200px;

// RWD
// ------------------------------
@mixin xxs {
    @media (max-width:$bp-xxs-max) { @content; }
}
@mixin xs {
    @media (max-width:$bp-xs-max) { @content; }
}
@mixin sm-less {
    @media (max-width:$bp-sm-max) { @content; }
}
@mixin sm {
    @media (min-width:$bp-sm-min) and (max-width:$bp-sm-max) { @content; }
}
@mixin sm-more {
    @media (min-width:$bp-sm-min) { @content; }
}
@mixin md-less {
    @media (max-width:$bp-md-max) { @content; }
}
@mixin md {
    @media (min-width:$bp-md-min) and (max-width:$bp-md-max) { @content; }
}
@mixin md-more {
    @media (min-width:$bp-md-min) { @content; }
}
@mixin lg-less {
    @media (max-width:$bp-lg-max) { @content; }
}
@mixin lg {
    @media (min-width:$bp-lg-min) { @content; }
}