.contact-line-blue {

  &__wrapper {
    display: block;
  }

  &__text,
  &__phone {
    display: block;
    width: 100%;
    margin-bottom: 10px;
    text-align: left;
  }

  &__button {
    width: 100%;
  }

}
