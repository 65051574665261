.introductory-text {
  padding: 50px 10px;

  &__wrapper-mobile {
    display: block;

    & ~ .introductory-text__wrapper-desktop {
      display: none;
    }
  }

  &__title {
    font-size: 32px;
    line-height: 48px;
  }

  p {
    font-size: 24px;
    line-height: 40px;
  }

}

.introductory-text + .form-wrapper {
  padding-top: 0;
  margin-top: -50px;
}
