.inpage-navigation {

  &__wrapper-navigation {
    border-top: 1px solid #eeeef0;
    border-bottom: 1px solid #eeeef0;

    .panel-title {
      margin-bottom: 0 !important;
    }
  }

  &__tabs-navigation {
    display: flex;
  }

  &__tabs-navigation-item {
    position: relative;
    display: inline-block;
    border-right: 1px solid #eeeef0;
    padding: 25px;
    width: 25%;
    cursor: pointer;

    &::before {
      content: "";
      display: block;
      height: 0px;
      width: 100%;
      transition: all .5s;
      position: absolute;
      margin-left: -25px;
      top: -1px;
    }

    &:hover,
    &.active {

      &::before {
        height: 6px;
        background: $pink;
      }
    }

    &:first-child {
      border-left: 1px solid #eeeef0;
    }
  }

  &__tabs-navigation-item-link {
    display: block;
    color: $dark-blue;
    text-align: center;
    line-height: 24px;
    font-size: 18px;
    font-weight: 700;
    text-decoration: none;
    text-transform: uppercase;

    &:hover {
      text-decoration: none;
    }
  }

  &__tabs-icon {
    display: none;
  }

  &__wrapper-content {
    padding: 80px 0;
    border-bottom: 1px solid #eeeef0;
    background: #fbfbfb;
  }

  .tab-content {
    width: 100%;
  }

  .tab-pane {
    .latest-content {
      margin: 0;
      padding-bottom: 0;
      border: 0 !important;

      .latest-content-filters {
        margin: 0;
      }

      .latest-content__load {
        margin-top: 30px;
      }
    }
  }

}
