$newsCard__imageHeight: 184px;

.news-card {
  background: white;
  height: 100%;
  border: 1px solid #eeeef0;
  margin-bottom: 40px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;

  .link { font-weight:bold; }

  &__media {
    display: block;
    font-size: 1px;
    height: $newsCard__imageHeight;

    & + .news-card__details {
      flex-basis: 100%;
      height: calc(100% - #{$newsCard__imageHeight});
      display: flex;
      flex-direction: column;

      .bubble-link {
        margin-top: auto;
        //font-size: 20px;
      }
    }

    &--autoheight {
      height: auto;
    }
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: all $latestContent__duration--desktop;
  }

  &__details {
    padding: 0 25px;
  }

  &__category {
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 700;
    margin: 25px 0 10px;
    vertical-align: middle;
    color: $dark-blue;

    &:before {
      content: '';
      display: inline-block;
      margin-right: 5px;
      border-top: 3px $dark-blue solid;
      width: 19px;
      height: 3px;
      vertical-align: middle;
      transition: all $latestContent__duration--desktop;
    }
  }

  &--event {

    .news-card__title {
      font-weight: 700;
      font-size: 2.4rem;
      //line-height: 32px;
      line-height: 26px;
      margin-top: 0;
      margin-bottom: 15px;
    }
  }

  &__title {
    font-weight: 700;
    font-size: 2.4rem;
    margin-top: 0;
    margin-bottom: 20px;
    margin-top: 10px;
    line-height: 26px;
  }

  &__content {
    margin-bottom: 20px;
    font-size: 1.5rem;
    line-height: 20px;
    font-family: 'Source Sans Pro', Verdana, sans-serif;
  }

  &:hover {
    .desktop & .news-card__category {
      &:before {
        width: 38px;
        transition: all $latestContent__duration--desktop;
      }
    }
  }

  &__social {
    border-top: 1px solid #eeeef0;
    margin-top: 30px;
    margin-bottom: -20px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }

  &__social-link {
    display: inline-flex;
    border-right: 1px solid #eeeef0;
    padding: 10px 10px 12px;
    vertical-align: middle;
    width: 60px;

    .share-button__button {
      display:inline-block;
      width: 20px;
      height: 20px;
      vertical-align: middle;
    }

    .share-button__counter {
      display:inline-block;
      vertical-align: middle;
      line-height: 21px;
      font-size: 16px;
    }
  }

  &__social-icon {
    width: auto !important;
    height: 100% !important;
  }

  &--right-side {
    margin-bottom: 0;
    margin: 20px;
    display: block;

    .news-card__media {
      height: 128px;
    }

    .news-card__category {
      font-size: 18px;
    }

    .news-card__title {
      font-size: 20px;
      line-height: 24px;
    }

    .news-card__details {
      padding: 0 20px;
    }

    .news-card__dowloads {
      padding: 15px 20px 15px;
    }
  }

  &__dowloads {
    padding: 15px 25px 12px;
    text-align: center;
    display: block;
    width: 100%;
  }

  &__dowloads-link {
    display: block;
    width: 100%;
    font-weight: 700;
  }

  .news-card__social-link + .news-card__dowloads {
    display: inline-flex;
    width: calc(100% - 60px);
  }

  &__location,
  &__date {
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    color: $dark-purple;
  }

  &__price {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: $dark-blue;
    margin-bottom: 25px;
  }

  &__media-date {
    position: absolute;
    top: 30px;
    left: 30px;
    color: white;
    font-size: 32px;
    line-height: 1;
    font-weight: 700;
  }

}

.latest-content .news-card {
  display: block;
}
