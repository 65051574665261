.thank-you-download {
  padding: 75px 0 80px;
  text-align: left;
  margin-left: 9.1%;
  max-width: 695px;

  &__wrapper-desktop {
    margin-bottom: 20px;
  }

  &__wrapper-mobile {
    display: none;
    margin-bottom: 20px;
  }

  &__title {
    font-size: 40px;
    line-height: 48px;
  }

  p {
    font-size: 24px;
    line-height: 32px;
  }

  &__button {
    width: auto;
  }

  &__link {
    display: block;
    margin-top: 40px;
  }
}
