.form-rating {

  .pointer {
    color: $dark-blue;

    &.rated {
      color: $pink;
    }

    &.numberOff {
      -webkit-text-stroke: 1.4px $dark-blue;
      -webkit-text-fill-color: $dark-blue;
      color: $dark-blue;
    }

    &.numberOn,
    &.numberCurrent {
      -webkit-text-stroke: 1.4px $pink;
      -webkit-text-fill-color: $pink;
      color: $pink;
    }
  }

  .numberOff + .text-primary {
    color: white;
  }
  .numberOn + .text-primary {
    color: white;
  }
  .numberCurrent + .text-primary {
    color: white;
  }

  .numberOff {
    &:hover {
      color: $pink;
      -webkit-text-stroke: 1.4px $pink;
      -webkit-text-fill-color: $pink;
    }
  }

  .form-wrapper--inverse {

    .pointer {
      color: white;

      &.rated {
        color: $pink;
      }

      &.numberOff {
        -webkit-text-stroke: 1.4px white;
        -webkit-text-fill-color: white;
        color: white;

        .text-primary {
          color: $pink;
        }
      }

      &.numberOn {
        -webkit-text-stroke: 1.4px $pink;
        -webkit-text-fill-color: $pink;
        color: $pink;

        .text-primary {
          color: white;
        }
      }

      &.numberCurrent  {
        -webkit-text-stroke: 1.4px $pink;
        -webkit-text-fill-color: $pink;
        color: $pink;

        .text-primary {
          color: white;
        }
      }
    }

    .numberOff + .text-primary {
      color: $pink;
    }
    .numberOn + .text-primary {
      color: white;
    }
    .numberCurrent + .text-primary {
      color: white;
    }
  }
}
