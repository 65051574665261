.generic-cnt {
  font-size: 18px;
  line-height: 24px;
  color: #000033;

  @import '../../../scss/base/richtext-editor';

  &--impact {
    padding-left: 15%;
    padding-right: 10%;
  }

  &--inverse {
    color: white !important;

    p,
    a,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    .intro-copy
    {
      color: white;
    }

    .highlight {
      color: black;
    }
  }
}
