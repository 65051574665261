.introductory-text {

  &--left-align {
    .introductory-text__wrapper {
      margin-left: 0;
      max-width: 100%;
    }
  }

}
