.top-menu {
  height: 45px;
  overflow: hidden;
  width: 100%;
  align-items: center;
  justify-content: center;

  .hidden-xs {
    display: none;
  }

  &__user,
  &__login,
  &__card,
  &__shop {
    margin-left: 0;
    text-align: center;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 45px;
  }

  &__login,
  &__card,
  &__shop {
    border-left: 1px solid #eeeef0;
  }

  &__login-content,
  &__card-content,
  &__shop-content {
    span {
      display: none;
    }
  }

  &__shop-content {
    .link {
      margin-top: 0px;
    }
  }

  &__shop {
    font-size: 14px;
  }

}
