//
// Button groups
// --------------------------------------------------
.btn-group, .btn-group-vertical {
  position:relative; display:inline-block; vertical-align:middle; 
  > .btn {
    position:relative; float:left;
    &:hover, &:focus, &:active, &.active { z-index:2;}
  }
}
.btn-group {
  .btn + .btn, .btn + .btn-group, .btn-group + .btn, .btn-group + .btn-group { margin-left:-1px; }
}
.btn-toolbar {
  margin-left:-5px; @include clearfix;
  .btn, .btn-group, .input-group { float:left; }
  > .btn, > .btn-group, > .input-group { margin-left:5px; }
}
.btn-group > .btn:not(:first-child):not(:last-child):not(.dropdown-toggle) { border-radius:0; }
.btn-group > .btn:first-child {
  margin-left:0;
  &:not(:last-child):not(.dropdown-toggle) { @include border-right-radius(0); }
}
.btn-group > .btn:last-child:not(:first-child), .btn-group > .dropdown-toggle:not(:first-child) { @include border-left-radius(0); }
.btn-group > .btn-group { float:left; }
.btn-group > .btn-group:not(:first-child):not(:last-child) > .btn { border-radius:0; }
.btn-group > .btn-group:first-child:not(:last-child) {
  > .btn:last-child, > .dropdown-toggle { @include border-right-radius(0); }
}
.btn-group > .btn-group:last-child:not(:first-child) > .btn:first-child { @include border-left-radius(0); }
.btn-group .dropdown-toggle:active, .btn-group.open .dropdown-toggle { outline:0; }
//.btn-group-xs > .btn { @extend .btn-xs; }
.btn-group-sm > .btn { @extend .btn-sm; }
.btn-group-lg > .btn { @extend .btn-lg; }

.btn-group > .btn + .dropdown-toggle { padding-right:8px; padding-left:8px; }
.btn-group > .btn-lg + .dropdown-toggle { padding-right:12px; padding-left:12px; }
.btn-group.open .dropdown-toggle {
  @include box-shadow(inset 0 3px 5px rgba(0, 0, 0, .125));
  // &.btn-link { @include box-shadow(none); }
}
.btn .caret {  margin-left:0; }
.btn-lg .caret { border-width:$caret-width-large $caret-width-large 0; border-bottom-width:0; }
.dropup .btn-lg .caret { border-width:0 $caret-width-large $caret-width-large; }

/*
.btn-group-vertical {
  > .btn, > .btn-group, > .btn-group > .btn { display:block; float:none; width:100%; max-width:100%; }
  > .btn-group {
    @include clearfix;
    > .btn { float:none; }
  }
  > .btn + .btn, > .btn + .btn-group, > .btn-group + .btn, > .btn-group + .btn-group { margin-top:-1px; margin-left:0; }
}

.btn-group-vertical > .btn {
  &:not(:first-child):not(:last-child) { border-radius:0; }
  &:first-child:not(:last-child) { @include border-top-radius($btn-border-radius-base); @include border-bottom-radius(0); }
  &:last-child:not(:first-child) { @include border-top-radius(0); @include border-bottom-radius($btn-border-radius-base); }
}
.btn-group-vertical > .btn-group:not(:first-child):not(:last-child) > .btn { border-radius:0; }
.btn-group-vertical > .btn-group:first-child:not(:last-child) {
  > .btn:last-child, > .dropdown-toggle { @include border-bottom-radius(0); }
}
.btn-group-vertical > .btn-group:last-child:not(:first-child) > .btn:first-child { @include border-top-radius(0); }

.btn-group-justified {
  display:table; width:100%; table-layout:fixed; border-collapse:separate;
  > .btn, > .btn-group { display:table-cell; float:none; width:1%; }
  > .btn-group .btn { width:100%; }
  > .btn-group .dropdown-menu { left:auto; }
}
*/

[data-toggle="buttons"] {
  > .btn, > .btn-group > .btn {
    input[type="radio"], input[type="checkbox"] { position:absolute; clip:rect(0, 0, 0, 0); pointer-events:none; }
  }
}
