.header {
  &-top {
    &--left {
      width: 30%;
    }
    &--right {
      width: 30%;
    }
  }

  &__login-text {
    display: none;
  }
}
