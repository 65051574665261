.inpage-navigation {
  border-top: 1px solid #eeeef0;
  border-bottom: 1px solid #eeeef0;

  &__wrapper-navigation {
    border: 0;

    .container {
      width: 100%;
    }
  }

  &__tabs-navigation-item-link {
    position: relative;
    text-align: left;
    font-size: 18px;
    line-height: 18px;
    padding: 25px 25px 20px;
    outline: none;

    &.collapsed {
      transition: none;

      .inpage-navigation__tabs-icon {
        display: inline-block;
        position: absolute;
        right: 25px;
        top: 35px;
        height: 12px;
        width: 12px;

        .fa-plus {
          display: inline-block;
        }

        .fa-minus {
          display: none;
        }
      }
    }
  }

  &__tabs-icon {
    display: inline-block;
    position: absolute;
    right: 25px;
    top: 50%;
    transform: translateY(-50%);
    height: 12px;
    width: 12px;
    font-size: 12px;
    color: #7c7a8c;
    line-height: normal;

    .fa-plus {
      display: none;
    }
  }

  .panel-group {
    margin-bottom: 0;
    width: calc(100% + 30px);
    margin-left: -15px;

    .panel {
      border-radius: 0;
      box-shadow: none;
      border: 0;
    }

    .panel + .panel {
      margin-top: 0;
    }

    .panel-default {
      background: transparent;
      border-bottom: 1px solid #eeeef0;

    }

    .panel-default > .panel-heading {
      background: transparent;
      padding: 0;
    }

    .panel-body {
      padding: 45px 5px;
      background: #fbfbfb;
      border: 0;

      .latest-content {
        margin-bottom: 0;
        margin-top: 0;
        padding-bottom: 0;

        .latest-content-filters {
          margin-top: 30px;
          margin-bottom: 0;
        }
      }
    }

    .panel-collapse.collapse.in {
      border-top: 1px solid #eeeef0;
    }
  }
}
