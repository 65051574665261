//
// Grid system
// --------------------------------------------------
.container {
  @include container-fixed;
  @media (min-width:$screen-sm-min) { width:$container-sm; }
  @media (min-width:$screen-md-min) { width:$container-md; }
  @media (min-width:$screen-lg-min) { width:$container-lg; }
}
.container-fluid { @include container-fixed; }

.row { @include make-row; }

.row-no-gutters {
  margin-right:0;margin-left:0;
  [class*="col-"] { padding-right:0; padding-left:0; }
}

@include make-grid-columns;
@include make-grid(xs);
@media (min-width:$screen-sm-min) { @include make-grid(sm); }
@media (min-width:$screen-md-min) { @include make-grid(md); }
@media (min-width:$screen-lg-min) { @include make-grid(lg); }