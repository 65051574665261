.link-card {

  &__text,
  &__text-hover {
    line-height: 18px;
  }

  &__title,
  &__hover-title {
    font-size: 16px;
  }

  &__description,
  &__hover-description {
    font-size: 14px;
  }
}
