.blue-text-and-image {
  display: block;
  height: auto;

  &__text {
    width: calc(100%);
    padding-left: 0;
    padding-right: 0;
    height: auto;
  }

  &__text-content {
    width: calc(100% - 70px);
    position: relative;
    top: 0;
    left: 0;
    transform: translate(0, 0);
    margin-right: 20px;
    margin-left: 20px;
  }

  &__media {
    display: none;
  }

  &__description {
    max-width: 100%;
  }

  &--full-width {

    .blue-text-and-image__text {
      width: 100%;

      .blue-text-and-image__text-content {
        width: calc(100% - 40px);
      }
    }
  }
}

.container {
  .blue-text-and-image {
    margin-right: -15px;
    margin-left: -15px;
    width: calc(100% + 30px);
  }
}
