.page-navigation {
  border-top: 0;

  &::before {
    position: relative;
    display: none;
  }

  > .container {
    width: 100%;
    padding: 0;

    > .row {
      margin: 0;

      & > * {
        padding: 0;
      }
    }
  }

  &__wrapper-content {
    display: block;
    padding: 45px 25px;
    background: #fbfbfb;
    border-bottom: 1px solid #ddd;
    overflow: auto;
    height: auto;
    opacity: 1;
    transition: all 500ms ease;

    .latest-content {
      margin-bottom: 0;
      margin-top: 25px;
      padding-bottom: 0;

      .latest-content-filters {
        margin-top: 30px;
        margin-bottom: 0;
      }
    }

    &.closed {
      overflow: hidden;
      height: 0;
      padding: 0 25px;
      opacity: 0;
      transition: all 500ms ease;
    }
  }

  &__menu-item {
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    border: 0;

    &.mobile-tabs {
      display: block;
    }
    &::before {
      display: none !important;
    }

    &:first-child {
      border-left: 0;
      border-top: 1px solid #eeeef0;
    }

    &.active {

      .page-navigation__menu-item-link {

        .fa-plus {
          display: none;
        }
        .fa-minus {
          display: block;
        }

        &:hover {
          cursor: pointer !important;
          .page-navigation__menu-item-icon {
            color: $pink;
          }
        }
      }
    }
  }

  &__menu-item-link {
    position: relative;
    text-align: left;
    font-size: 18px;
    line-height: 18px;
    padding: 25px 25px 20px;
    outline: none;
    border-bottom: 1px solid #ddd;
    position: relative;
  }

  &__menu-item-icon {
    display: inline-block;
    position: absolute;
    right: 25px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    height: 12px;
    width: 12px;
    font-size: 12px;
    color: #7c7a8c;
    line-height: normal;

    .fa-minus {
      display: none;

      &.hide {
        display: none;
      }
    }

    .fa-plus {

      &.show {
        display: block;
      }
    }
  }

  .page-navigation__menu-item.active ~ .page-navigation__menu-item:not(.mobile-tabs) {
    display: none;
  }
}
