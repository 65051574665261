.generic-video {
  position: relative;

  /*&:hover {
    .generic-video__controls {
      opacity: 1;
    }
  }
  */

  &__controls {
    position: absolute;
    opacity: 0;
    z-index: 2;
    width: 56px;
    height: 56px;
    border-radius: 50%;
    background: rgba(255,255,255, 0.7);
    cursor: default;
    transition: all .5s;
    top: 50%;
    transform: translate(-50%);
    left: 50%;
    margin-top: -23px;
    display: none;

    &:hover {
      cursor: default;
    }

    &--show {
      opacity: 1;
      display: block;
    }

    &--video-play {
      opacity: 0;
      transition: all .5s;

      .generic-video__play {
        opacity: 0;
      }

      .generic-video__pause {
        opacity: 1;
      }
    }
  }

  &__play,
  &__pause {
    position: absolute;
    width: 23px;
    height: 23px;
    transition: all .5s;
    top: 50%;
    left: 50%;
    margin: -11px 0 0 -11px;
    color: #333;
    font-size: 28px;
    text-align: center;

    .fa {
      top: -2px;
    }
  }

  &__play {
    opacity: 1;
  }

  &__pause {
    opacity: 0;

    .fa {
      left: -2px;
    }
  }


}
