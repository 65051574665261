.contact-section {
  padding-bottom: 75px;
  position: relative;
  padding-left: 15px;

  &__title {
    margin-bottom: 15px;
  }

  &__button {
    position: absolute;
    bottom: 0;
  }

  /* modal */
  &__close-icon {
    font-size: 14px;
    margin-left: 10px;
    color: $dark-blue;
    line-height: 24px;
  }

  .modal {
    top: 50%;
    transform: translateY(-50%);
    border-radius: 0;

    .modal-dialog {
      border-radius: 0;

      .modal-header {
        border: 0;
        border-radius: 0;
      }

      .modal-footer {
        border: 0;
        padding: 0;
        border-radius: 0;
      }

      .close {
        color: $dark-blue;
        font-size: 14px;
        display: flex;
        opacity: 1;
        align-items: center;
        outline: none;
        font-weight: 700;
        align-content: center;
        line-height: 24px;
      }

      .modal-content {
        border-radius: 0;
        border-color: #eeeef0;
        height: auto;
      }

      .modal-body-title {
        text-align: center;
        margin-bottom: 25px;
      }

      .modal-body {
        padding: 25px 40px;
      }
    }
  }
}
