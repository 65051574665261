.reinsurance-bar--footer {
  	min-height: 115px;
    border-top: 1px solid #eeeef0;
    .reinsurance-bar {
    &__items {
    	min-height: 115px;
    }
    &__item {
    	border: 0;
    	max-height: inherit;
    }
    &__link-icon {
	    max-height: inherit;
    	max-width: inherit;
    	line-height: inherit;
    	font-size: 24px;
    	line-height: 48px;
    }
    &__link {
    	text-transform: none;
    	font-size: 14px;
    	text-align: left;
    	max-height: inherit;
    	padding: 0 5px;
    }
    &__link-title {
    	line-height: 16px;
    	font-weight: 700;
    }
  }
}