.button,
.btn-pink,
.btn-light,
.btn-secondary,
.btn-grey {
  display: inline-flex;
  padding: 0 5px;
  color: $white;
  background-color: $pink;
  height: 50px;
  border: 0 none;
  width: 200px;
  max-width: 100%;
  font-size: 16px;
  font-family: 'Source Sans Pro',Verdana,sans-serif;
  font-weight: 700;
  position: relative;
  outline: none;
  text-decoration: none;
  overflow: hidden;
  align-items: center;
  align-content: center;
  text-align: center;
  text-decoration: none;
  cursor: pointer;

  span {
    display: block;
    width: 100%;
    color: $white;
    text-decoration: none;
    line-height: 1;
  }

  &:hover {

    .desktop & span {
      animation: buttonHover .85s;
    }
  }

  &--disabled {
    color: $grey !important;
    background-color: $light-grey !important;
    border: 1px solid $grey !important;
    cursor: default  !important;

    span {
      color: $grey !important;
    }
  }
  &.btn--with-icon {
    text-align:left;
    i {
      font-size: 32px;
      padding-right: 10px;
      height: 32px;
    }
  }

  &.btn--auto {
    width: auto;
    padding: 0 10px;
  }
}

.btn-secondary {
  border: 1px solid #fff;
  background-color: transparent;
}

.btn-light {
  background: transparent;
  border: 1px solid $dark-blue;
  color: $dark-blue;

  span {
    color: $dark-blue;
  }
}

.btn-grey {
  background-color: #e9e7ed;
  color: $dark-blue;

  span {
    color: $dark-blue;
  }
}
