$latestContent__duration--desktop: .5s;
$latestContent__twitterColor: $twitter-feed-bg;
$latestContent__facebookColor: #3b5998;
$latestContent__googleColor: #dd4b39;
$latestContent__youtubeColor: #ee1111;
$latestContent__pinterestColor: #bd081c;
$latestContent__height-images: 150px;

.latest-item {
  margin-bottom: 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  border: 1px solid #eeeef0;

  &__content {
    height: 100%;
    opacity: 1;
  }

  &__content-link {
    text-decoration: none;
    color: #000033;
  }

  &__play {
    display: none;
    position: absolute;
    left: 50%;
    top: 125px;
    font-size: 50px;
    color: white;
    transform: translateX(-50%);
    text-shadow: 0px 0px 15px rgba(0, 0, 0, 0.9);
    transition: all $latestContent__duration--desktop;
  }

  &__details {
    color: white;
    padding: 84px 20px 26px;

    > p, p, h3 {
      color: white;
      > a {
        color: white;
        text-decoration: underline;
      }
    }

    > a {
      color: white;
      text-decoration: underline;
    }

    .latest-item__category {
      display: none;
    }
  }

  .image-cover ~ .latest-item__details {
    padding: 26px 20px;

    .latest-item__text {
      margin-bottom: 0;
    }
  }

  &__category {
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 700;
    padding: 25px 25px 20px;
    vertical-align: middle;

    &::before {
      content: '';
      display: inline-block;
      margin-right: 5px;
      margin-top: -2px;
      border-top: 3px #000033 solid;
      width: 19px;
      height: 3px;
      vertical-align: middle;
      transition: all $latestContent__duration--desktop;
    }
  }

  &__social {
    overflow: hidden;
    background-color: #FFF;
    position: absolute;
    top: 20px;
    right: 20px;
    border-radius: 50px;
    text-align: center;
    transition: all $latestContent__duration--desktop;
    width: 40px;
    height: 40px;
    display: block;
    white-space: nowrap;
  }

  &__social-icon {
    display: inline-block;
    width: 30px;
    line-height: 1;
    font-size: 21px;
    vertical-align: middle;
    transition: all $latestContent__duration--desktop;
    margin: 10px 5px;
  }

  &__social-label {
    display: inline-block;
    color: #000033;
    vertical-align: middle;
    margin-right: 15px;
    margin-top: -3px;
    line-height: 1;
    transition: all $latestContent__duration--desktop;

    &::first-letter {
      text-transform: uppercase;
    }
  }

  &__title {
    font-weight: 700;
    font-size: 2rem;
    margin-top: 0;
    padding-bottom: 10px;
    margin-bottom: 0;

    & ~ .latest-item__text {
      padding-top: 10px;
    }
  }

  &__text {
    //padding-top: 10px;
    //margin-bottom: 20px;
    font-size: 1.5rem;
    font-family: 'Source Sans Pro', Verdana, sans-serif;

    p {
      font-size: 1.5rem;
      font-family: 'Source Sans Pro', Verdana, sans-serif;
    }
  }

  &__image {
    display: block;
    width: 100%;
    object-fit: cover;
    transition: all $latestContent__duration--desktop;

    & ~ .latest-item__details {
      padding: 15px 25px;

      // .latest-item__category {
      //   display: block;
      //
      //   &::before {
      //     border-color: white;
      //   }
      // }
      //
      // & ~ .latest-item__category {
      //   display: none;
      // }
    }
  }

  &__images {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    overflow: hidden;
    order: 2;
    transition: all $latestContent__duration--desktop;

    & ~ .latest-item__details {
      order: 1;
      padding: 84px 25px 15px;
    }

    & ~ .latest-item__category  {
      order: 3;
    }
  }

  &__single-image {
    flex: 1 1 50%;
    max-width: 50%;
    min-width: 50%;
    height: 150px;
    max-height: 150px;
    min-height: 150px;
    position: relative;

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &:last-child:nth-child(odd) {
      flex-basis: 100%;
      max-width: 100%;
      min-width: 100%;
    }
  }

  /* Pinterest */

  &--pinterest {
    .latest-item__image {
      min-height: $latestContent__height-images;
    }

    .latest-item__details {
      background-color: $latestContent__pinterestColor;
      border-color: $latestContent__pinterestColor;
    }

    .latest-item__social-icon {
      color: $latestContent__pinterestColor;
    }

    .latest-item__text {
      font-size: 14px;
      line-height: 20px;

      p {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }

  /* Youtube */

  &--youtube {
    .latest-item__play {
      display: block;
      background-color: #e11;
      width: 62px;
      height: 44px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 10px;
      > i {
        font-size:20px;
      }
    }

    .latest-item__image {
      height: 290px;
    }

    .latest-item__details {
      background-color: $latestContent__youtubeColor;
      border-color: $latestContent__youtubeColor;
    }

    .latest-item__social-icon {
      color: $latestContent__youtubeColor;
    }
  }

  /* Googleplus */

  &--googleplus {
    .latest-item__image {
      min-height: $latestContent__height-images;
    }

    .latest-item__details {
      background-color: $latestContent__googleColor;
      border-color: $latestContent__googleColor;
    }

    .latest-item__social-icon {
      color: $latestContent__googleColor;
    }

    .latest-item__text {
      font-size: 14px;
      line-height: 20px;

      p {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }

  /* Facebook */

  &--facebook {
    .latest-item__image {
      min-height: $latestContent__height-images;
    }

    .latest-item__details {
      background-color: $latestContent__facebookColor;
      border-color: $latestContent__facebookColor;
    }

    .latest-item__social-icon {
      color: $latestContent__facebookColor;
    }

    .latest-item__text {
      font-size: 14px;
      line-height: 20px;

      p {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }

  /* Twitter */

  &--twitter {
    .latest-item__image {
      min-height: $latestContent__height-images;
    }

    .latest-item__details {
      background-color: $latestContent__twitterColor;
    }

    .latest-item__social-icon {
      margin: 2px 3px;
      color: $latestContent__twitterColor;
      font-size: 37px;
      width: 37px;
      height: 37px;
    }

    .latest-item__text {
      a {
        color: white;
      }
    }

    .latest-item__text {
      font-size: 14px;
      line-height: 20px;

      p {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }

  /* Instagram */

  &--instagram {
    .latest-item__image {
      min-height: $latestContent__height-images;
    }
    .latest-item__social {
      display:none;
    }
    .latest-item__details {
      color: black;
      border-color: $latestContent__facebookColor;
    }

    .latest-item__text > p {
      color: #000033;
      font-size: 14px;
      line-height: 20px;
    }

    .latest-item__footer {
      color: #d5d3db;
      position: relative;
      top: 15px;
      font-size: 12px;
    
      &--instagram-logo {
        display: inline-block;
        width: 25px;
        height: 25px;
        background: url(images/instagram.png) no-repeat;
        background-size: 100% 100%;
      } 

      &--instagram-date {
        display: inline-block;
        line-height: 25px;
        position: relative;
        top: -7px;
      }
    } 

  }

  /* Hover */

  &:hover {

    .desktop & .bubble-pink {
      @extend .bubble-pink-hover;
    }

    .desktop & .latest-item__image,
    .desktop & .latest-item__images {
      filter: brightness(100%);
    }

    .desktop & .latest-item__category {
      &::before {
        width: 38px;
        transition: all $latestContent__duration--desktop;
      }
    }

    .desktop & .latest-item__social {
      width: 122px;
      height: 40px;
      border-radius: 50px;
      transition: all $latestContent__duration--desktop;
    }

    .desktop & .latest-item__play {
      text-shadow: 0px 0px 25px rgba(0, 0, 0, 1);
      transform: translateX(-50%) scale(1.1);
    }
  }
}
