//
// Forms
// --------------------------------------------------
fieldset { min-width:0; padding:0; margin:0; border:0; }
legend { display:block; width:100%; padding:0; margin-bottom:$line-height-computed; font-size:($font-size-base * 1.5); line-height:inherit; color:$legend-color; border:0; border-bottom:1px solid $legend-border-color; }
label { display:inline-block; max-width:100%; 
  margin-bottom:5px; font-weight:700; }
input[type="search"] { @include box-sizing(border-box); -webkit-appearance:none; appearance:none; }
input[type="radio"], input[type="checkbox"] { 
  margin:4px 0 0; margin-top:1px \9;  line-height:normal; 
  &[disabled], &.disabled, fieldset[disabled] & { cursor:$cursor-disabled; }
}
input[type="file"] { display:block; }
input[type="range"] { display:block; width:100%; }
select[multiple], select[size] { height:auto; }
input[type="file"]:focus, input[type="radio"]:focus, input[type="checkbox"]:focus { @include tab-focus; }
output { display:block; padding-top:($padding-base-vertical + 1); font-size:$font-size-base; line-height:$line-height-base; color:$input-color; }
.form-control { display:block; width:100%; height:$input-height-base; 
  padding:$padding-base-vertical $padding-base-horizontal; font-size:$font-size-base; line-height:$line-height-base; color:$input-color; background-color:$input-bg; background-image:none; border:1px solid $input-border; border-radius:$input-border-radius; 
  @include box-shadow(inset 0 1px 1px rgba(0, 0, 0, .075)); @include transition(border-color ease-in-out .15s, box-shadow ease-in-out .15s); @include form-control-focus; @include placeholder; 
  &::-ms-expand { background-color:transparent; border:0; }
  &[disabled], &[readonly], fieldset[disabled] & { background-color:$input-bg-disabled; opacity:1; }
  &[disabled], fieldset[disabled] & { cursor:$cursor-disabled; }
}
textarea.form-control { height:auto; }
@media screen and (-webkit-min-device-pixel-ratio:0) { 
  input[type="date"], input[type="time"], input[type="datetime-local"], input[type="month"] {
    &.form-control { line-height:$input-height-base; }
    &.input-sm, .input-group-sm & { line-height:$input-height-small; }
    &.input-lg, .input-group-lg & { line-height:$input-height-large; }
  }
}
.form-group { margin-bottom:$form-group-margin-bottom; }
.radio, .checkbox { 
  position:relative; display:block; margin-top:10px; margin-bottom:10px; 
  &.disabled, fieldset[disabled] & {
    label { cursor:$cursor-disabled; }
  }
  label { min-height:$line-height-computed; padding-left:20px; margin-bottom:0; font-weight:400; cursor:pointer; }
}
.radio input[type="radio"], .radio-inline input[type="radio"], .checkbox input[type="checkbox"], .checkbox-inline input[type="checkbox"] { position:absolute; margin-top:4px \9; margin-left:-20px; }
.radio + .radio, .checkbox + .checkbox { margin-top:-5px; }
.radio-inline, .checkbox-inline { 
  position:relative; display:inline-block; padding-left:20px; margin-bottom:0; font-weight:400; vertical-align:middle; cursor:pointer; 
  &.disabled, fieldset[disabled] & { cursor:$cursor-disabled; }
}
.radio-inline + .radio-inline, .checkbox-inline + .checkbox-inline { margin-top:0; margin-left:10px; }
.form-control-static { 
  min-height:($line-height-computed + $font-size-base); padding-top:($padding-base-vertical + 1); padding-bottom:($padding-base-vertical + 1); margin-bottom:0;
  &.input-lg, &.input-sm { padding-right:0; padding-left:0; }
}
@include input-size('.input-sm', $input-height-small, $padding-small-vertical, $padding-small-horizontal, $font-size-small, $line-height-small, $input-border-radius-small);
.form-group-sm { 
  .form-control { height:$input-height-small; padding:$padding-small-vertical $padding-small-horizontal; font-size:$font-size-small; line-height:$line-height-small; border-radius:$input-border-radius-small; }
  select.form-control { height:$input-height-small; line-height:$input-height-small; }
  textarea.form-control, select[multiple].form-control { height:auto; }
  .form-control-static { height:$input-height-small; min-height:($line-height-computed + $font-size-small); padding:($padding-small-vertical + 1) $padding-small-horizontal; font-size:$font-size-small; line-height:$line-height-small; }
}
@include input-size('.input-lg', $input-height-large, $padding-large-vertical, $padding-large-horizontal, $font-size-large, $line-height-large, $input-border-radius-large);
.form-group-lg { 
  .form-control { height:$input-height-large; padding:$padding-large-vertical $padding-large-horizontal; font-size:$font-size-large; line-height:$line-height-large; border-radius:$input-border-radius-large; }
  select.form-control { height:$input-height-large; line-height:$input-height-large; }
  textarea.form-control, select[multiple].form-control { height:auto; }
  .form-control-static { height:$input-height-large; min-height:($line-height-computed + $font-size-large); padding:($padding-large-vertical + 1) $padding-large-horizontal; font-size:$font-size-large; line-height:$line-height-large; }
}
.has-feedback { 
  position:relative; 
  .form-control { padding-right:($input-height-base * 1.25); }
}
.form-control-feedback { position:absolute; top:0; right:0; z-index:2; display:block; width:$input-height-base; height:$input-height-base; line-height:$input-height-base; text-align:center; pointer-events:none; }
.input-lg + .form-control-feedback, .input-group-lg + .form-control-feedback, .form-group-lg .form-control + .form-control-feedback { width:$input-height-large; height:$input-height-large; line-height:$input-height-large; }
.input-sm + .form-control-feedback, .input-group-sm + .form-control-feedback, .form-group-sm .form-control + .form-control-feedback { width:$input-height-small; height:$input-height-small; line-height:$input-height-small; }
.has-success { @include form-control-validation($state-success-text, $state-success-text, $state-success-bg); }
.has-warning { @include form-control-validation($state-warning-text, $state-warning-text, $state-warning-bg); }
.has-error { @include form-control-validation($state-danger-text, $state-danger-text, $state-danger-bg); }
.has-feedback label { 
  & ~ .form-control-feedback { top:($line-height-computed + 5); }
  &.sr-only ~ .form-control-feedback { top:0; }
}
.help-block { display:block; margin-top:5px; margin-bottom:10px; color:lighten($text-color, 25%); }
@mixin form-inline { 
  @media (min-width:$screen-sm-min) { 
    .form-group { display:inline-block; margin-bottom:0; vertical-align:middle; }
    .form-control { display:inline-block; width:auto; vertical-align:middle; }
    .form-control-static { display:inline-block; }
    .input-group {
      display:inline-table; vertical-align:middle; 
      .input-group-addon, .input-group-btn, .form-control { width:auto; }
    }
    .input-group > .form-control { width:100%; }
    .control-label { margin-bottom:0; vertical-align:middle; }
    .radio, .checkbox { 
      display:inline-block; margin-top:0; margin-bottom:0; vertical-align:middle;
       label { padding-left:0; }
    }
    .radio input[type="radio"], .checkbox input[type="checkbox"] { position:relative; margin-left:0; }
    .has-feedback .form-control-feedback { top:0; }
  }
}
.form-inline { @include form-inline; }
.form-horizontal { 
  .radio, .checkbox, .radio-inline, .checkbox-inline { padding-top:($padding-base-vertical + 1); margin-top:0; margin-bottom:0; }
  .radio, .checkbox { min-height:($line-height-computed + ($padding-base-vertical + 1)); }
  .form-group { @include make-row; }
  @media (min-width:$screen-sm-min) { 
    .control-label { padding-top:($padding-base-vertical + 1); margin-bottom:0; text-align:right; }
  }
  .has-feedback .form-control-feedback { right:floor(($grid-gutter-width / 2)); }
  .form-group-lg { 
    @media (min-width:$screen-sm-min) { 
      .control-label { padding-top:($padding-large-vertical + 1); font-size:$font-size-large; }
    }
  }
  .form-group-sm { 
    @media (min-width:$screen-sm-min) { 
      .control-label { padding-top:($padding-small-vertical + 1); font-size:$font-size-small; }
    }
  }
}
