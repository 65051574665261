.panoramic-gallery {

  &__view-gallery {
    display: none;
  }

  &__carousel-control {
    top: 60%;
  }

  &__modal-body {
		top: calc(25vh / 2);
	}

  &__modal-footer {
    bottom: 0;
    margin-top: calc(30vh / 2);
  }

  &__carousel-popup-media {
		max-height: 270px;
	}
}
