.social-network {
  padding: 70px 0 55px;

  &__title {
    text-align: center;
    font-size: 2.4rem;
    margin-bottom: 24px;
    font-weight: 700;
  }

  &__navigation {
    padding: 0;
    margin: 0;
    font-size: 1px;
    text-align: center;
  }

  &__icons {
    text-align: center;
  }
}
