.certifications {
  padding: 50px 0 55px;
  display: flex;
  width: 100%;
  flex-direction: row;

  &--reverse {
    flex-direction: column-reverse;

    .certifications__images {
      width: 100%;
    }

    .certifications__text {
      width: 100%;
      margin-bottom: 40px;
    }

    .certifications__image {
      margin: 0 15px 30px;
      vertical-align: middle;

      &:last-child {
        margin: 0 15px 30px;
      }
    }
  }

  &__images {
    width: 50%;
    order: 1;
    text-align: center;
  }

  &__text {
    width: 50%;
    order: 2;
    text-align: center;
  }

  &__image {
    max-width: 200px;
    height: auto;
    margin: 0 15px 30px 0;
    text-align: left;

    &:last-child {
      margin-right: 0;
    }
  }

  &__title {
    margin-top: 20px;
    font-size: 2.4rem;
    margin-bottom: 24px;
    font-weight: 700;
  }
}

@media (min-width:$bp-lg-min) {
  .certifications__image {
    max-width: 80%;
    margin-right: 10px;
    height: auto;
  }
}
