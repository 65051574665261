.payment-options {
  display: block;
  padding: 20px 0;


  &__title {
    display: block;
    margin-bottom: 15px;
    text-align: center;
    width: 100%;
  }

  &__items {
    display: block;
    text-align: left;
    text-align: center;
    margin: 7px;
  }
}
