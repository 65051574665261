.form-wrapper {
  padding: 35px 25px;

  &--gray {
    border: 1px #eeeef0 solid;
    background: #fbfbfb;
  }

  &--push-up {
    margin-top: -80px;
  }

  &--inverse {
    background: $dark-blue;
    color: white;

    .form-wrapper__tooltip {
      background: $pink;
      color: white;

      &::after{
      	border-top-color: $pink;
      }
    }

    legend {
      color: white;
    }

    .form-group {

      .control-label {
        display: block;
        color: white !important;
      }

      .checkbox,
      .checkbox-label {
        color: white !important;

        a {
          color: white !important;
        }
      }
    }

  }

  form > fieldset > div.form-group > .col-sm-offset-2 {
    margin-left: 0 !important;
    width: 100% !important;
  }

  &__help {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
    min-height: 22px;

    &:hover,
    &:active,
    &:focus {
      .form-wrapper__tooltip {
        // top: -25px;
        top: auto;
        margin-bottom: 0;
        opacity: 1;
        bottom: 30px;
      }
    }
  }

  &__help-icon {
    display: inline-block;
    background: $pink;
    color: white;
    border-radius: 50%;
    height: 16px;
    min-width: 16px;
    text-align: center;
    font-size: 12px;
    line-height: 19px;
    padding: 0 3px;
    font-weight: 700;
    overflow: hidden;
    margin-left: 15px;
  }

  &__tooltip {
    background: $dark-blue;
    color: white;
    position: absolute;
    margin: 0 0 0 8px;
    opacity: 1;
    font-size: 14px;
    line-height: 1.1;
    width: 100%;
    width: 200px;
    padding: 6px 8px 3px;
    text-align: center;
    border-radius: 3px;
    font-weight: 300;
    z-index: 2;
    left: 50%;
    top: -1500vh;
    transform: translateX(-50%);
    text-transform: none;
    margin-bottom: -10px;
    bottom: auto;
    opacity: 0;


    .desktop & {
      transition: margin-bottom .3s;
    }

    &::after{
      margin-bottom: -10px;
      bottom: 0;
    	left: 50%;
    	border: solid transparent;
    	content: " ";
    	height: 0;
    	width: 0;
    	position: absolute;
    	pointer-events: none;
    	border-color: rgba(0, 0, 0, 0);
    	border-top-color: $dark-blue;
    	border-width: 6px;
    	margin-left: -6px;
    }
  }

  &__error-message {
    color: $pink;
    opacity: 0;
    transition: opacity .3s;
  }

  &__captcha {
    margin: 20px 0;
    text-align: center;

    > div {
      width: 100% !important;
    }

    iframe {
      display: inline-block;
      margin: 0 auto;
      max-width: 100%;
      border-radius: 4px;
    }
  }


  legend {
    display: block;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 25px;
    text-transform: uppercase;
  }

  .form-group {

    &.has-error {
      .form-wrapper__error-message {
        opacity: 1;
      }

      input,
      select,
      textarea {
        border-color: $pink;
      }
    }

    .help-block {
      margin: 0;
    }

    .control-label {
      display: block;
      text-transform: uppercase;
      width: 100%;
      text-align: left;
      //margin-bottom: 15px;
      font-weight: 700;
      font-size: 18px;
      line-height: 24px;
      padding-top: 0;
      vertical-align: top;
      color: $dark-blue;
    }

    .checkbox-label {
      text-transform: none !important;
      padding-left: 39px !important;
      line-height: 20px;
      padding-top: 10px;

      > span {
        &::before {
          margin-left: -39px;
          margin-right: 0;
          margin-top: -4px;
        }
      }
    }

    input[type="text"],
    select {
      height: 48px;
      margin-bottom: 10px;
      border-radius: 0;
      font-size: 18px;
      line-height: 24px;
    }

    textarea {
      margin-bottom: 10px;
      font-size: 18px;
      line-height: 24px;
    }

    select:not([multiple]) {
      appearance:none;
      background-repeat:no-repeat;
      background-position: 95% 50%;
      padding-right: 26px;
      padding-top: 0 !important;
      padding-bottom: 0 !important;
      background-image: url('images/arrow-down.svg');
      background-size: 14px;
      line-height: 24px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow:hidden;
    }

    input,
    select,
    textarea {
      transition: border .5s;

      &:focus {
        border-color: $pink;
        transition: border .5s;
      }

      &::-webkit-input-placeholder {
        color: #999 !important;
      }
      &:-moz-placeholder {
        color: #999 !important;
      }
      &::-moz-placeholder {
        color: #999 !important;
      }
    }

    .form-control {
      display: block;
      border-radius: 0;
      box-shadow: none;
      border: 1px #d3d3d9 solid;
      color: $dark-blue;
      padding: 15px 15px 12px;

      &::-webkit-input-placeholder {
        color: #999 !important;
      }
      &:-moz-placeholder {
        color: #999 !important;
      }
      &::-moz-placeholder {
        color: #999 !important;
      }
    }
    .form-control:-ms-input-placeholder {
      color: #999 !important;
    }
    .form-control::-ms-input-placeholder {
      color: #999 !important;
    }
    .form-control::placeholder {
      color: #999 !important;
    }

    // TODO: need fix this
    button.btn.btn-sm {
      @extend .button;
      border-radius: 0;
      margin-bottom: 15px;
    }

    //
    // checkbox           'images/checkbox.png'
    // checkbox:checked   'images/checkbox-checked.png'
    //
    .checkbox {
      font-size: 16px;
      line-height: 24px;
      clear: both;
      color: #514e65;

      a {
        color: #514e65;
        text-decoration: underline;

        &:hover {
          text-decoration: none;
        }
      }

      label{
      	position: relative;
      	cursor: pointer;
        padding-left: 0;
        overflow: hidden;
        display: flex;
        align-items: center;
      }

      input[type="checkbox"], input[type="radio"]{
      	position: absolute;
      	right: 9000px;
      }

      /*Check box*/
      input[type="checkbox"] + span{
        display: flex;
        align-items: center;
        margin-top: -2px;

        &::before{
          flex: 0 0 auto;
          display: inline-block;
          content: "";
          background:url('images/checkbox.png') no-repeat;
          width: 24px;
          height: 24px;
          margin-right: 15px;
          transition: background .3s;
          margin-top: 3px;
        }
      }

      input[type="checkbox"]:checked + span:before{
      	background:url('images/checkbox-checked.png') no-repeat;
      	animation: effect 250ms ease-in;
        transition: background .3s;
      }
    }
  }


  /* Profiling */
  &--profiling {
    .form-group {
      label {
        display: block;
        margin-bottom: 2px;
        font-weight: bold;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-transform: none;
      }

      input[type="checkbox"] {
        font-size: 2em;
        width: .7em;
        height: .7em;
        display: inline-block;
        vertical-align: top;
        margin: 0;
      }

      input[type="checkbox"] + label {
        display: inline-block;
        margin: 4px;
        vertical-align: top;
        height: 20px;
        line-height: 1.4em;
      }

      input[type="text"],
      select {
        width: 100%;
        font-size: 14px;
        max-width: 100%;
        min-width: 100%;
      }

      select[multiple] {
        height: 87px;
      }
    }

    input[type="submit"].button.btn-pink {
      text-align: center;
      max-width: 100%;
      min-width: 100%;
      display: inline-block;
    }
  }


  /* Styles Dapackier */

  .datePickerMin {
    font-size: 14px;

    button {
      width: auto !important;
      height: auto !important;
      border: 0!important;
    }

    .uib-datepicker-popup.dropdown-menu {
      display: block !important;
      border-radius: 0 !important;
      z-index: 4 !important;
    }

    .uib-datepicker {
      margin: 20px 20px 20px 5px;
      min-width: 383px;
    }

    .dropdown-menu {
      position: absolute !important;
      z-index: 1000 !important;
      display: none !important;
      float: left !important;
      min-width: 160px !important;
      padding: 5px 0 !important;
      margin: 2px 0 0 !important;
      font-size: 14px !important;
      text-align: left !important;
      list-style: none !important;
      background-color: #fff !important;
      background-clip: padding-box !important;
      border: 1px solid #eeeef0 !important;
      border-radius: 4px !important;
      box-shadow: 0 6px 12px rgba(0,0,0,.175) !important;
      outline: none !important;
    }

    .pull-left {
      float: left!important;
    }
    .btn-group-sm>.btn, .btn-sm {
      padding: 5px 10px !important;
      font-size: 12px !important;
      line-height: 1.5 !important;
      border-radius: 3px !important;
    }
    .btn-default {
      color: #333 !important;
      background-color: #fff !important;
      border-color: #ccc !important;
    }
    .btn {
      display: inline-block !important;
      padding: 6px 12px !important;
      margin-bottom: 0 !important;
      font-size: 14px !important;
      font-weight: 400 !important;
      line-height: 1.42857143 !important;
      text-align: center !important;
      white-space: nowrap !important;
      vertical-align: middle !important;
      touch-action: manipulation !important;
      cursor: pointer !important;
      user-select: none !important;
      background-image: none !important;
      border: 1px solid transparent !important;
      border-radius: 4px !important;
    }
    .fa {
      position: relative !important;
      top: 1px !important;
      display: inline-block !important;
      font-family: 'FontAwesome'  !important;
      font-style: normal !important;
      font-weight: 400 !important;
      line-height: 1 !important;
      -webkit-font-smoothing: antialiased !important;
      -moz-osx-font-smoothing: grayscale !important;
    }

    .uib-datepicker .uib-title {
      width: 100% !important;
      outline: none !important;
    }

    /* Reset default styles datapicker */
    button.btn.btn-default.btn-sm {
      border: 0 !important;
      border-radius: 0 !important;
      color: #777 !important;
      outline: none !important;
      animation: none !important;

      &:hover,
      &:focus {
        animation: none !important;
        color: #fff !important;
        background-color: transparent !important;
        span {
          color: $pink !important;
        }
      }
    }
    small.ng-binding {
      font-weight: 700 !important;
      text-transform: uppercase !important;
    }
    button#datepicker-786-7746-title {
      font-size: 16px !important;
      font-weight: 300 !important;
      text-transform: uppercase !important;
    }
    .btn-group-sm>.btn, .btn-sm {
      font-size: 14px !important;
    }

    .btn-default.active,
    .btn-default:active,
    .open>.dropdown-toggle.btn-default {
      outline: none !important;
      border-radius: 0 !important;
      width: 100% !important;
    }

    button.btn.btn-default.btn-sm.active {
      box-shadow: none !important;
      outline: none !important;

      .text-info {
        color: white !important;
        outline: none !important;
      }

      &:hover {
        background: $pink !important;
        span {
          color: white !important;
        }
      }
    }
    table.uib-daypicker {
      margin: 10px !important;
      outline: none !important;
    }
    th.text-center.ng-scope {
      padding: 10px 10px !important;
      outline: none !important;
    }

    table {
      border-collapse: collapse !important;
      border-spacing: 0 !important;
      width: 100%;

      thead,
      tbody {

        tr {
          th {
            vertical-align: top !important;

            button {

              &:hover,
              &:focus {
                color: $pink !important;
                background-color: transparent !important;
              }

            }
          }
        }
      }

      thead {
        tr {
          th {
            &:nth-child(2) {
              button {
                strong {
                  font-family: 'Source Sans Pro',Verdana,sans-serif;
                  text-transform: uppercase;
                  font-size: 16px;
                  color: $dark-blue;
                }

                &:hover,
                &:focus {
                  color: $pink !important;
                  background: transparent !important;
                  box-shadow: none;

                  strong {
                    color: $pink !important;
                  }
                }
              }
            }
            button.btn.btn-default.btn-sm.pull-left.uib-left,
            button.btn.btn-default.btn-sm.pull-right.uib-right {
              width: 40px !important;

              .fa {
                color: $dark-blue;
              }

              &:hover,
              &:focus {
                color: $pink !important;
                background: transparent !important;
                box-shadow: none;

                strong,
                .fa {
                  color: $pink !important;
                }
              }
            }
          }
        }
      }
      tbody {
        tr {
          td {
            button {
              display: block;
              width: 100%;
              height: 100%;
              color: #777!important;

              span {
                color: #777!important;
              }

              &:hover,
              &:focus,
              &:active {
                animation: none !important;
                color: #fff !important;
                background-color: transparent !important;
                box-shadow: none !important;
                span {
                  color: $pink !important;
                  animation: none !important;
                }
              }

              &.active {
                animation: none !important;
                background-color: #F0027F !important;
                box-shadow: none;
                span {
                  color: #fff !important;
                  animation: none !important;
                }
              }
            }
          }
        }
      }

    }

    .btn-info.active, .btn-info:active, .open>.dropdown-toggle.btn-info {
      color: #fff !important;
      background-color: #F0027F !important;
      border-color: transparent !important;
      box-shadow: none !important;
      border-radius: 0 !important;

      .text-info {
        color: white !important;
      }
    }
    button.btn.btn-default {
      border: 0 !important;
    }
    .btn-info.active.focus, .btn-info.active:focus, .btn-info.active:hover, .btn-info:active.focus, .btn-info:active:focus, .btn-info:active:hover, .open>.dropdown-toggle.btn-info.focus, .open>.dropdown-toggle.btn-info:focus, .open>.dropdown-toggle.btn-info:hover {
      color: #fff !important;
      background-color: #F0027F !important;
      border-color: transparent !important;
      animation: none !important;

      &:hover {
        animation: none !important;
      }
    }

    button.btn.btn-default.btn-sm.btn-info {
      color: #777 !important;
      border-color: transparent !important;
      animation: none !important;

      &:hover {
        animation: none !important;
      }

      .text-muted {
        color: #777 !important;
      }
    }

    /* current, clear, close buttons */
    button.btn.btn-sm.btn-success.pull-right.uib-close.ng-binding,
    button.btn.btn-sm.btn-danger.uib-clear.ng-binding,
    button.btn.btn-sm.btn-info.uib-datepicker-current.ng-binding {
      background: $pink !important;
      border: 0 !important;
      border-radius: 0 !important;

      &:hover {
        background: #cb0078 !important;
      }
    }

    .uib-button-bar {
      .btn-group,
      .uib-close {
        margin: 0 15px 10px;
      }
    }

    /* Input datapicker styles */

    p.input-group.datepicker-popup-position {
      display: flex;
    }

    .input-group-btn {
      //padding: 10px 0px 2px !important;

      > button.btn.btn-default.btn-md {
        width: 40px;
        height: 46px !important;
        border: 0;
        // margin-left: -41px;
        // margin-top: 1px;
        border-radius: 0;
        z-index: 4;
        outline: none;
        background: transparent !important;
        margin: 0 0 0 -41px;

        &:hover,
        &:active {
          background: transparent !important;
          color: $pink !important;
          box-shadow: none;
        }
      }
    }

    input.form-control.ng-pristine.ng-valid.ng-isolate-scope.ng-empty.ng-valid-date.input-md.ng-touched {
      padding-right: 45px;
    }

  }

  /* Captcha */
  .form-wrapper__captcha iframe {
    display: block !important;
    margin: 0 !important;
  }
}

.right {

  .form-wrapper {

    ff-date-picker {

      .col-sm-6 {

        &.datePickerMin {
          width: 100% !important;
          min-width: 194px !important;
          max-width: 100% !important;
        }
      }
    }
  }
}
