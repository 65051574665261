.icon-text-contact-item {

  &--contact {
    padding-left: 0px;
  }

  &--right-side {
    .icon-text-contact-item__button {
      min-width: 200px;
      display: inline-flex;
      width: auto;
    }
  }

}
