//
// Breadcrumbs
// --------------------------------------------------
.breadcrumb {
  padding: $breadcrumb-padding-vertical $breadcrumb-padding-horizontal;
  margin-bottom: $line-height-computed;
  list-style: none;
  background-color: $breadcrumb-bg;
  border-radius: $border-radius-base;

  >li {
    display: inline-block;

    +li:before {
      padding: 0 5px;
      color: $breadcrumb-color;
      $nbsp: "\00a0";
      content:"#{$breadcrumb-separator}#{$nbsp}";
    }
  }

  >.active {
    color: $breadcrumb-active-color;
  }
}

@media screen and (max-width: 543px) {
  .wrapper-breadcrumb:not(.breadcrumb-footer) {
    display: none;
  }
}

@media screen and (min-width: 544px) {
  .wrapper-breadcrumb.breadcrumb-footer {
    display: none;
  }
}