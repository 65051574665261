.popular-brands-item {
  border: 1px solid #eeeef0;
  color: $dark-blue;
  font-size: 1px;
  height: 100%;


  &:not(.item) {
    position: relative;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  &__wrapper {
    display: flex;
    width: 100%;
    height: 100%;
  }

  &__image-wrapper {
    position: relative;
    display: inline-block;
    padding: 0 15px;
    width: 128px;
    min-height: 100%;
  }

  &__image {
    position: relative;
    display: block;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    height: auto;
  }

  &__text-wrapper {
    display: inline-block;
    width: auto;
    height: 100%;
    border-left: 1px solid #eeeef0;
    padding: 17px;
    background: #fafafa;
    width: calc(100% - 128px);
  }

  &__text-title {
    display: block;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 10px;
    color: $dark-blue;
  }

  &__text-description {
    display: block;
    font-size: 14px;
    line-height: 1.3em;
    margin-bottom: 10px;
    color: $dark-blue;
  }

  &__text-link {
    font-size: 14px;
    line-height: 24px;
    outline: none;
  }

}
