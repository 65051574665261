.carousel-slider-wrapper {
  .carousel-slider {
    &-auto {
      .carousel-indicators {
        display: none;
      }

      .item {
        .carousel-banner__image {
          height: auto;
        }
      }
    }

    &--popular,
    &--promo {
      background: none;
      height: auto;

      .carousel-indicators {
        top: 250px;
      }
    }

    .carousel-inner {
      .item {
        .carousel-banner__text {
          width: 90%;
          left: 5%;
        }
      }
    }
  }
}