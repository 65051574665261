$navigation__height--desktop: 64px;

.navigation {
  border-bottom: 1px solid $medium-grey;
  transition: transform .3s;
  z-index: 1;
  position: relative;
  width: 100%;
  // height: $navigation__height--desktop;
  background-color: $white;
  transition: all .5s;

  &--has-opened-dropdown {
    z-index: 3;
  }

  &.active {
    background-color: $white;
    position: fixed;
    top: 160px;
    width: 100%;
    z-index: 10;
  }

  &__menu-header,
  &__menu-footer {
    display: none;
  }

  &__menu {
    padding-left: 16px;
    margin: 0;
    z-index: 10;
    text-transform: uppercase;
    display: flex;
    // border-bottom: 1px solid $medium-grey;
    background-color: $white;
  }

  &__menu-item {
    display: inline-block;
    padding: 14px 0;
    position: relative;
    vertical-align: top;

    &:hover,
    &:focus {
      .navigation__sub-menu {
        display: block;
        ///animation: menuItem 500ms ease-in-out both;
      }
    }

    &:last-child {
      .navigation__menu-link {
        border-right: 0;
      }
    }
    &:hover {
      .navigation__menu-link {
        &::before {
          transition: all .5s;
          content: "";
          display: block;
          background: #F0027F;
          height: 6px;
        }
      }
    }
  }

  &__menu-link {
    border-right: 1px solid $medium-grey;
    display: inline-block;
    font-size: 16px;
    padding: 10px 20px;
    white-space: nowrap;
    width: 100%;
    transition: color .5s;
    cursor: pointer;

    &::before {
      content: "";
      display: block;
      transition: all .5s;
      background: #F0027F;
      height: 0px;
      width: 100%;
      position: absolute;
      top: -11px;
      left: 0;
      margin: 10px 0;
      background: transparent;
    }

    &:hover {
      transition: color .5s;

      &::before {
        transition: all .5s;
        content: "";
        display: block;
        background: #F0027F;
        height: 6px;
      }
    }

    &__menu-link--main {
      font-size: 1.2em;
      margin: auto;
      padding: 10px;
      word-wrap: break-word;
    }

    .navigation__chevron {
      color: #393650;
      display: inline-block;
      font-size: 20px;
      line-height:12px;
      padding-left: 5px;
    }
  }

  &__sub-menu-part-name {
    display: none;
  }

  &__sub-menu {
    background-color: $white;
    //border-top: 1px solid $medium-grey;
    display: none;
    left: 0;
    min-width:100%;
    position: absolute;
    top: 64px;
    white-space: nowrap;
    z-index: 10;
  }

  &__sub-menu-item {
    display: block;
    padding: 10px;
  }

  &__sub-menu-item-link {
    border: 0 none;
    padding: 5px;
    display: block;
    cursor: pointer;
    transition: all .5s;

    &:hover {
      transition: all .5s;
    }

    .bubble-pink {
      background-color: $white;
      transition: all .5s;
    }

    &:hover,
    &:focus {
      .bubble-pink {
        @extend .bubble-pink-hover;
      }
    }
  }
}


@include md-more {

  .header.header--sticky {
    & ~ .navigation {
      position: fixed;
      top: 91px; //103px;
      //margin-top: -64px;
      transform: translateY(-100%);
      z-index: 4;

      &--has-opened-dropdown {
        position: absolute;
      }
    }

    &.header--open-menu {
      & ~ .navigation {
        transform: translateY(0%);
      }
    }
  }

}
