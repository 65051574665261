.benefits {

  .col-md-4 {
    border: 1px solid #eeeef0;
    border-right: 0;
    margin-bottom: 0;

    &:nth-child(3n) {
      border-right: 1px solid #eeeef0;
    }

    &:nth-child(n+4) {
    border-top: 0;
    }
  }
}

.generic-cnt + .benefits {
  margin-top: 50px;
}
