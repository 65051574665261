.legal-links {
  text-align: left;

  &__item {
    margin-bottom: 20px;
  }

  &__items {
    display: block;
    margin: 20px 0 0;
  }

  &__copyright {
    display: block;
    margin: 0 0 30px;
  }
}
