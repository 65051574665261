.reinsurance-bar-2 {

  &__items {
    display: block;
  }

  .reinsurance-bar-2__carousel {
    height: auto;
  }
}
