.latest-content-filters {
  clear: both;
  margin-bottom: 60px;

  &__menu-item {
    padding: 10px 15px;
    color: #000033;
    border: 1px solid #eeeef0;
    display: inline-block;
    margin: 10px 5px;
    font-size: 17px;
    cursor: pointer;
    transition: background-color .3s;

    &__hidden {
      color: #e5e2ef;
      border: 1px solid #fefef0;
    }

    &:hover {
      background-color: #F0027F;
      color: #FFF;
      transition: background-color .3s;
    }

    &--active {
      background-color: #000033;
      color: #FFF;

      &:hover {
        background-color: #000033;
      }
    }
  }

}
