$height: 48px;
$border-color: #d3d3d9;
$background: #fff;
$color: $dark-blue;
$scrollbar-background: #ccc;
$min-width: 100px;
$width: 100%;

@mixin box-sizing($attr) {
  box-sizing: $attr;
  -moz-box-sizing: $attr;
  -webkit-box-sizing: $attr;
}

%sb-link {
  display: block;
  background-color: $background;
  padding: 0 35px 0 15px;
  font-size: 16px;
  color: $color;
  height: $height;
  line-height: $height;
  text-decoration: none;
  white-space: nowrap;
  text-overflow: ellipsis;
  min-width: $min-width;
  width: $width;
  overflow: hidden;
  outline: none;
  @include box-sizing(border-box);

  &:hover,
  &:active {
    text-decoration: none;
    outline: none;
  }
}

.sb-container {
  font-family: $font-normal;
  display: inline-block;
  vertical-align: middle;
  width: $width;
  outline: none;
  position: relative;
  text-align: left;

  &-multi {
    .sb-item {
      a, div {
        &:before {
          content: '✓';
          float: left;
          color: #ddd;
          font-size: 1em;;
          padding-right: 6px;
        }
      }
      &-active {
        a, div {
          &:before {
            color: #666;
          }
        }
      }
    }
  }
}

.sb-toggle {
  $size: 5px;
  border: 1px solid $border-color;
  position: relative;
  @extend %sb-link;
  font-size: 18px;

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    right: 15px;
    background: url('images/slider-arrow-left.svg') left center no-repeat;
    background-size: 20px;
    width: 20px;
    height: 13px;
    transform: translateY(-50%) rotate(-90deg);
  }

  &-active {
    border-color: $pink !important;

    &:after {
      transform: translateY(-50%) rotate(90deg);
    }
  }

  &:focus {
    border: 1px solid darken($border-color, 5%);
  }
}

.sb-placeholder {
  color: rgb(152, 152, 152);
}

.sb-dropdown {
  background-color: $background;
  border: 1px solid $border-color;
  border-top: 0;
  position: absolute;
  z-index: 100;
  top: $height;
  left: 0;
  width: 100%;
  max-height: 198px;
  overflow-y: auto;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  list-style: none;

  @include box-sizing(border-box);

  &::-webkit-scrollbar {
    width: 7px;
    height: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #eee;
  }

  &::-webkit-scrollbar-thumb {
    background: $scrollbar-background;
    &:hover {
      background: darken($scrollbar-background, 10%);
    }
  }
}

.sb-item {
  position: relative;
  clear: both;

  &:hover {
    a, div {
      background-color: #daf0f6;
    }
  }

  &-active {
    a, div {
      color: $pink;

      &::after {
        content: '';
        display: inline-block;
        position: absolute;
        top: 50%;
        right: 10px;
        background: url('images/check.svg') left center no-repeat;
        background-size: 12px;
        width: 14px;
        height: 12px;
        transform: translateY(-50%);
      }
    }
  }

  &:not(:last-child) {
    //border-bottom: 1px solid $border-color;
  }

  a, div {
    @extend %sb-link;
    min-width: $min-width + 30px;
    width: calc($width + 30px);
  }

  &.sb-empty {
    padding: 10px;
    font-size: 14px;
    color: #999;
  }
}
