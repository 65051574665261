.accordion-item {
  margin-top: 0 !important;
  border-radius: 0;
  border-bottom: 0;
  box-shadow: none;

  &:last-child {
    border-bottom: 1px #eeeef0 solid;
  }

  .panel-heading {
    background: white;
    border-radius: 0;
    padding: 0 24px 0 0;
  }

  .panel-title {
    margin-bottom: 0;
    line-height: 1;

    > a {
      display: block;
      position: relative;
      padding: 19px 24px;
      text-decoration: none;
      line-height: 1;
      text-transform: uppercase;

      &:hover {

        .accordion-item__icon {
          color: $pink;
        }
      }
    }
  }

  .collapsed {
    position: relative;
    text-decoration: none;

    .accordion-item__icon-cross {
      display: block;
    }

    .accordion-item__icon-line {
      display: none;
    }
  }

  &__icon {
    position: absolute;
    right: 0px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 12px;
    width: 12px;
    height: 12px;
    color: #7c7a8c;
  }

  &__icon-cross {
    display: none;
    line-height: 1;
  }

  &__icon-line {
    display: block;
    line-height: 1;
  }

  .panel-body {
    padding: 24px;
    color: $dark-blue;
  }
}
