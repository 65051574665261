.thank-you-cards-item {
  display: block;
  width: 100%;
  height: 100%;
  border: 1px #eeeef0 solid;
  font-size: 1px;
  background: white;
  position: relative;

  &:hover {

    .thank-you-cards-item__category {

      &::before {
        width: 38px;
        transition: all .5s;
      }
    }

    .thank-you-cards-item__image {
      filter: brightness(60%);
      transition: all .5s;
    }
  }

  &__icon-wrapper {
    position: absolute;
    min-width: 64px;
    min-height: 64px;
    top: 25px;
    right: 30px;
  }

  &__icon-background {
    position: relative;
    border-radius: 50%;
    background: $pink;

    @include maintain-ratio(1 1);
  }

  &__icon-text {
    color: white;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 300;
    min-width: 64px;
    min-height: 64px;
    text-align: center;
    transform: translateY(-7px);
    padding: 50% 10px 0;
  }

  &__text-wrapper {
    width: 100%;
    height: auto;
    padding: 25px 30px;
  }

  &__category {
    text-transform: uppercase;
    font-size: 18px;
    line-height: 24px;
    vertical-align: middle;
    font-weight: 700;
    margin-bottom: 25px;

    &::before {
      content: "";
      display: inline-block;
      margin-right: 5px;
      border-top: 3px solid $dark-blue;
      width: 19px;
      height: 3px;
      vertical-align: middle;
      transition: all .5s;
    }
  }

  &__title {
    margin-bottom: 15px;
    font-size: 24px;
    line-height: 32px;
    font-weight: 700;
  }

  &__download {
    margin-top: 20px;
    color: $dark-blue;
    text-decoration: none;
    font-size: 20px;
    line-height: 24px;

    > strong {
      font-size: 14px;
    }

    &:hover {
      color: $pink;
    }
  }

  &__link-icon {
    margin-right: 10px;
  }

  &__image {
    width: calc(100% + 2px);
    margin-top: 15px;
    margin-left: -1px;
    margin-bottom: -1px;
    height: auto;
    transition: all .5s;
  }

  .thank-you-cards-item__icon-wrapper + .thank-you-cards-item__text-wrapper {
    .thank-you-cards-item__title,
    .thank-you-cards-item__category {
      width: calc(100% - 124px);
    }
  }
}
