.inpage-banner {
  width: 100%;
  margin-left: 0;

  &__title {
    margin: 0 25px;
  }

  &__wrapper-image {
    max-height: 192px;
  }

  &__description {
    margin: 0 25px;
  }

}
