$taggedCarouselHeight: 660px;

.tagged-carousel-item {
  min-height: $taggedCarouselHeight;

  /* Media */

  &__media {
    position: absolute;
    width: 77%;
    height: calc(100% - 60px);
  }

  &__image {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  /* Description */

  &__description {
    @include scrollbar();
    position: absolute;
    top: 60px;
    right: 0;
    bottom: 0;
    left: 60%;
    overflow: auto;
    z-index: 5;
    background-color: white;
    padding: 40px 50px;
    font-size: 18px;
    line-height: 1.3em;
  }

  &__title {
    font-size: 24px;
    line-height: 32px;
    padding-bottom: 20px;
    color: $dark-blue;
    font-weight: 700;
  }

  &__copy {
    padding-bottom: 20px;
    color: $dark-blue;
    font-size: 18px;
    line-height: 24px;
  }

  &__case-study-link {
    margin-top: 20px;
  }

  /* Tags */

  &__tags {
    position: absolute;
    top: 0;
    left: 0;
    right: 23%;
    bottom: 60px;
    z-index: 1;
  }

  &__tag {
    position: absolute;
    width: 48px;
    height: 48px;
    background: rgba(236, 0, 140, .3);
    color: white;
    border-radius: 50%;
    cursor: pointer;
    overflow: hidden;
    transition: all .3s;
    transform: translate(-50%, -50%);

    &:before {
      content: "";
      height: 32px;
      width: 32px;
      background: $pink;
      border-radius: 50%;
      position: absolute;
      top: 8px;
      left: 8px;
      transition: all .3s;
    }

    &:after {
      content: "+";
      font-size: 26px;
      font-weight: bold;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: all .3s;
    }

    &--active,
    &:hover {
      &:before {
        width: 48px;
        height: 48px;
        top: 0;
        left: 0;
      }
    }

    @for $i from 1 through 4 {
      @for $j from 1 through 3 {
        &--position-#{$i}-#{$j} {
          left: calc(-12% + 25% * #{$j});
          top: calc(-5% + 22% * #{$i});
        }
      }
    }

  }

  /* Hotspot panel */

  &__overlay {
    background-color: rgba(0, 0, 0, .45);
    position: absolute;
    top: 0;
    left: 0;
    right: 23%;
    bottom: 60px;
    z-index: 3;
    display: none;
    color: transparent;

    &--active {
      display: block;

      .tagged-carousel-item__hotspot-panel {
        transform: translateY(0);
        opacity: 1;
        color: black;
      }
    }
  }

  &__hotspot-panel {
    background-color: rgba(255, 255, 255, .85);
    position: absolute;
    top: 56px;
    left: 65px;
    width: 280px;
    padding: 45px 20px 15px;
    transform: translateY(-100px);
    opacity: 0.5;
    transition: all .3s;
  }

  &__close {
    position: absolute;
    top: 8px;
    right: 8px;
    font-size: 30px;
    cursor: pointer;

    &:before {
      content: "×";
    }
  }

  &__info {
    @include scrollbar();
    font-size: 18px;
    line-height: 1.3;
    min-height: 230px;
    max-height: 380px;
    overflow: auto;
  }

  &__info-title {
    text-transform: uppercase;
    margin-bottom: 12px;
    font-size: 18px;
    line-height: 24px;
    color: $dark-blue;
    font-weight: 700;

    &:before {
      content: "";
      display: inline-block;
      width: 16px;
      height: 2px;
      background: $dark-blue;
      vertical-align: middle;
      margin-top: -2px;
      margin-right: 5px;
    }
  }

  &__info-copy {
    margin-bottom: 12px;
    font-size: 18px;
    line-height: 24px;
    color: $dark-blue;
  }

  &__arrows {
    text-align: right;
    margin-top: 30px;
  }

  &__arrow-left,
  &__arrow-right {
    width: 32px;
    height: 32px;
    display: inline-block;
    margin-left: 12px;
    background-color: white;
    border-radius: 50%;
    cursor: pointer;
    position: relative;

    img {
      max-width: 90%;
      max-height: 90%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

}
