.page-navigation {
  background: #fbfbfb;
  position: relative;
  border-top: 1px solid #eeeef0;

  &::before {
    position: absolute;
    content: '';
    background: $white;
    width: 100%;
    display: block;
    border-bottom: 1px solid #eeeef0;
    left: 0;
    top: 0;
  }

  .container {
    max-width: 100%;
  }

  &__wrapper {
    font-size: 0;
  }

  &__wrapper-navigation {
    border-top: 1px solid #eeeef0;
    border-bottom: 1px solid #eeeef0;

    .panel-title {
      margin-bottom: 0 !important;
    }
  }

  &__menu {
    display: flex;
  }

  &__menu-item {
    position: relative;
    display: inline-flex;
    border-right: 1px solid #eeeef0;
    padding: 25px;
    width: 25%;
    background: $white;
    vertical-align: top;
    align-items: center;
    justify-content: center;

    &::before {
      content: "";
      display: block;
      height: 0px;
      width: 100%;
      transition: all .5s;
      position: absolute;
      //margin-left: -25px;
      top: -1px;
      left: 0;
    }

    &:hover {
      &::before {
        height: 8px;
        background: #F0027F;
      }
      .page-navigation__menu-item-icon {
        color: $pink;
      }
    }

    &.active {
      &::before {
        height: 8px;
        background: #F0027F;
      }
      &:hover {
        .page-navigation__menu-item-icon {
          color: #7c7a8c;
        }
      }

      .page-navigation__menu-item-link {
        &:hover {
          cursor: default !important;
        }
      }
    }

    &:first-child {
      border-left: 1px solid #eeeef0;
    }

    &.mobile-tabs {
      display: none;
    }
  }

  &__menu-item-link {
    display: block;
    color: $dark-blue;
    text-align: center;
    line-height: 24px;
    font-size: 18px;
    font-weight: 700;
    text-decoration: none;
    text-transform: uppercase;

    &:hover {
      text-decoration: none;
      cursor: pointer;
    }
  }

  &__wrapper-content {
    padding: 80px 0;
    font-size: 14px;

    .latest-content {
      margin: 0;
      padding-bottom: 0;
      border: 0 !important;

      .latest-content-filters {
        margin: 0;
      }

      .latest-content__load {
        margin-top: 30px;
      }
    }

    &.closed {
      overflow: auto;
      height: auto;
      padding: 80px 0;
      opacity: 1;
    }
  }

  &__menu-item-icon {
    display: none;
  }

}
