.certifications {
  border: 0;
  border-bottom: 1px #eeeef0 solid;
  text-align: center;

  padding: 20px 25px 30px;
  width: calc(100% + 70px);
  margin-left: -35px;

  &::after {
    display: none;
  }
}
