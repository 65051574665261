@keyframes progress {

  from {
    width: 0;
  }

  to {
    width: 100%;
  }

}
