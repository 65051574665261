.right-side-section {

  &__button {
    min-width: auto;
  }

  .buttons-block {

    .buttons-block__button {
      max-width: 100%;
      min-width: 100%;
    }
  }
}
