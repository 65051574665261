//
// Scaffolding
// --------------------------------------------------
* { @include box-sizing(border-box); }
*:before, *:after { @include box-sizing(border-box); }
html { font-size:10px; -webkit-tap-highlight-color:rgba(0, 0, 0, 0); }
body { font-family:$font-family-base; font-size:$font-size-base; line-height:$line-height-base; color:$text-color; background-color:$body-bg; }
input, button, select, textarea { font-family:inherit; font-size:inherit; line-height:inherit; }
a { 
  color:$link-color; text-decoration:none;
  &:hover, &:focus { color:$link-hover-color; text-decoration:$link-hover-decoration; }
  &:focus { @include tab-focus; }
}
figure { margin:0; }
img { vertical-align:middle; }
.img-responsive { @include img-responsive; }
.img-rounded { border-radius:$border-radius-large; }
.img-thumbnail { padding:$thumbnail-padding; line-height:$line-height-base; background-color:$thumbnail-bg; border:1px solid $thumbnail-border; border-radius:$thumbnail-border-radius; @include transition(all .2s ease-in-out); @include img-responsive(inline-block); }
.img-circle { border-radius:50%; }
hr { margin-top:$line-height-computed; margin-bottom:$line-height-computed; border:0; border-top:1px solid $hr-border; }
.sr-only { position:absolute; width:1px; height:1px; padding:0; margin:-1px; overflow:hidden; clip:rect(0, 0, 0, 0); border:0; }
.sr-only-focusable { 
  &:active, &:focus { position:static; width:auto; height:auto; margin:0; overflow:visible; clip:auto; }
}
[role="button"] { cursor:pointer; }
