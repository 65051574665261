.share-button {
  display: flex;

  &__button {
    display: inline-block;
    width: 100%;
    max-width: 64px;
    outline: none;
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }
  }

  &__icon {
    width: 100%;
    height: auto;
  }

  &__counter {
    display: inline-block;
    color: $dark-blue;
    font-size: 20px;
    margin-left: 10px;

    .addthis_counter {
      color: $dark-blue;
      font-weight: 700;
    }
  }
}
