.tagged-carousel-item {
  min-height: 284px;

  &__media {
    height: 284px;
  }

  &__description {
    padding: 30px 15px;
  }
}

.col-xs-12,
.col-md-9 {

  .tagged-carousel-item {
    &__description {
      padding: 30px 0;
    }
  }
}
