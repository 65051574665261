.promotional-block {
  position: relative;
  overflow: hidden;

  &__content-banner {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  &__title {
    color: #FFF;
    font-size: 4rem;
    margin-bottom: 24px;
    font-weight: 700;
  }

  &__description {
    color: #FFF;
    margin: 20px 0;
    font-size: 28px;
    font-weight: 300;
  }

  &__video-controls-mobile {
    position: relative;
    display: none;
    z-index: 2;
    width: 56px;
    height: 56px;
    border-radius: 50%;
    background: rgba(255,255,255, .7);
    cursor: pointer;
    transition: all .5s;
  }

  &__video-controls {
    position: relative;
    display: none;
    z-index: 2;
    width: 56px;
    height: 56px;
    border-radius: 50%;
    background: rgba(255,255,255, .7);
    cursor: pointer;
    transition: all .5s;
    margin: 20px auto;

    &--show {
      display: block;
    }

    &--video-play {
      .promotional-block__pause-button {
        display: block;
      }
      .promotional-block__play-button {
        display: none;
      }
    }
  }

  &__pause-button,
  &__play-button {
    position: absolute;
    width: 23px;
    height: 23px;
    transition: all .5s;
    top: 50%;
    left: 50%;
    margin: -11px 0 0 -11px;

    > img {
      height: 23px;
    }
  }

  &__pause-button {
    display: none;
  }

  &__media {
    overflow: hidden;
  }

  &__video {
    height: 390px;
    overflow: hidden;
  }

  &__video-item,
  &__media-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &--promo {
    height: auto;

    .promotional-block__content-banner {
      width: 80%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .promotional-block__media,
    .promotional-block__video {
      height: auto;
    }

    .promotional-block__video-item,
    .promotional-block__media-image {
      width: 100%;
      height: auto;
      //object-fit: none;
    }

  }

  &--full-width-banner {

    .promotional-block__content-banner {
      left: 50%;
      top: 50%;
      text-align: center;
      width: 100%;
      max-width: 800px;
      height: auto;
      transform: translate(-50%, -50%);
    }
  }

  &--full-width-promo {

    &:hover {
      cursor: pointer;
    }

    .promotional-block__content-banner {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 79%;
      left: 10%;
      right: 10%;
    }

    .promotional-block__part-name-title {
      position: relative;
      text-transform: uppercase;
      font-weight: 700;
      font-size: 22px;
      color: white;
      margin-bottom: 10px;

      &::before {
        content: '';
        display: inline-block;
        height: 3px;
        width: 15px;
        background: white;
        margin-bottom: 5px;
        margin-right: 5px;
      }
    }

    .promotional-block__title {
      color: white;
      font-size: 46px;
      font-weight: 700;
      margin: 0;
    }

    .promotional-block__description {
      font-size: 2.4rem;
      font-weight: 300;
      color: white;
      margin-top: 10px;
    }

    .promotional-block__video-controls {
      margin: 20px 0;
    }

    .promotional-block__pause-button {
      margin: -11px 0 0 -8px;
    }
  }



}
