.carousel-global-container {
  &.left-shift-menu {
    margin-left: 0;
  }
}

.carousel-slider-wrapper {
  .carousel-slider {
    &--popular,
    &--promo {
      .item {
        .carousel-banner__text {
          padding-left: 60px;
        }
      }
    }
  }
}
