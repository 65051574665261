.image-cover {
  max-width: 100%;
  max-height: 100%;
  min-height: 100%;
  width: 100%;
  height: 100%;
  font-size: 0px;
  overflow: hidden;

  &__object-fit {
    background-position: center !important;
    background-size: cover;
    background-repeat: no-repeat;

    > img {
      opacity: 0
    }
  }
}
