.share-popup {
  display: none;
  position: absolute;
  background: white;
  z-index: 2;
  width: 270px;
  border: 1px solid #eeeef0;

  &__header {
    height: 35px;
    clear: both;
    border-bottom: 1px solid #eeeef0;
  }

  &__body {
    padding: 10px;

    .custom_images {
      width: 100%;
      display: inline-block;
    }
  }

  &__header-title {
    width: calc(100% - 60px);
    margin: 10px;
    float: left;
    font-weight: 700;
    color: $dark-blue;
  }

  &__header-close-icon {
    margin: 10px;
    float: right;
    font-size: 12px;

    &:hover {
      cursor: pointer;
    }
  }

  &__link-page {
    margin: 0 0 20px 0;
  }

  &__link-page-input {
    padding: 5px;
    border: 1px solid #eeeef0;
    border-right: 0;
    line-height: 24px;
    width: 100%;
    color: $dark-blue;
    border-radius: 0;
    height: 34px;
    overflow: hidden;
    outline: none;

    &:focus {
      outline: none;
      border: 1px solid #eeeef0;
      box-shadow: none;
    }
  }

  &__link-page-button {
    color: $white;
    background-color: $pink;
    border-radius: 0;
    border: 0;
    height: 34px;
    text-align: center;
    line-height: 24px;
    outline: none;
    margin-left: 0 !important;

    &:hover,
    &:focus {
      border: 0;
      outline: none;
    }
  }

  &__link-page-button-icon {

  }

  &__link {
    max-width: 112px;
    line-height: 1;
    color: $dark-blue;
    margin-bottom: 5px;
    display: inline-flex;
    width: 50% !important;
    align-items: flex-start;
    align-content: flex-start;
    text-decoration: none;
    outline: none;

    &:hover,
    &:focus {
      text-decoration: none;
      outline: none;
    }

    &.addthis_button_more {
      .share-popup__link-icon {
        width: 25px;
        height: 25px;
      }

      .share-popup__link-lable {
        line-height: 30px;
      }
    }

    .pin_it_iframe_widget {
      opacity: 0;
      width: 1px;
    }

  }

  &__link-icon {
    display: inline-flex;
    width: 20px;
    height: 20px;
    line-height: 1;
    margin-right: 10px;
  }

  &__link-lable {
    display: inline-flex;
    width: calc(100% - 35px);
    line-height: 22px;
  }

}
