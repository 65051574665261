.right-side-section {
  padding: 25px;
  border-bottom: 1px solid #eeeef0;

  &__title {
    display: block;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 15px;
    text-transform: uppercase;
    color: $dark-blue;
  }

  &__button {
    margin-top: 15px;
    min-width: 100%;
  }

  .buttons-block {
    padding: 25px 0;

    .buttons-block__button {
      width: 100%;
      margin: 20px auto 0;
    }
  }
}
