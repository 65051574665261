.case-study-results-item {
  display: inline-block;
  min-height: auto;
  margin-bottom: 45px;

  &__title {
    font-size: 26px;
    line-height: 40px;
  }

  &__description {
    font-size: 16px;
    line-height: 24px;
  }

  .col-md-6 {

    &:nth-child(2) {
      border-left: 0;
    }
  }
}
