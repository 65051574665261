//
// Dropdown menus
// --------------------------------------------------
.caret { display:inline-block; width:0; height:0; margin-left:2px; vertical-align:middle; border-top:$caret-width-base dashed; border-top:$caret-width-base solid \9; border-right:$caret-width-base solid transparent; border-left:$caret-width-base solid transparent; }
.dropup, .dropdown { position:relative; }
.dropdown-toggle:focus { outline:0; }
.dropdown-menu {
  position:absolute; top:100%; left:0; z-index:$zindex-dropdown; display:none; float:left; min-width:160px; padding:5px 0; margin:2px 0 0; font-size:$font-size-base; text-align:left; list-style:none; background-color:$dropdown-bg; background-clip:padding-box; border:1px solid $dropdown-fallback-border; border:1px solid $dropdown-border; border-radius:$border-radius-base; @include box-shadow(0 6px 12px rgba(0, 0, 0, .175));
  &.pull-right { right:0; left:auto; }
  .divider { @include nav-divider($dropdown-divider-bg); }
  > li > a {
    display:block; padding:3px 20px; clear:both; font-weight:400; line-height:$line-height-base; color:$dropdown-link-color; white-space:nowrap; 
    &:hover, &:focus { color:$dropdown-link-hover-color; text-decoration:none; background-color:$dropdown-link-hover-bg; }
  }
}
.dropdown-menu > .active > a {
  &, &:hover, &:focus { color:$dropdown-link-active-color; text-decoration:none; background-color:$dropdown-link-active-bg; outline:0; }
}
.dropdown-menu > .disabled > a {
  &, &:hover, &:focus { color:$dropdown-link-disabled-color; }
  &:hover, &:focus { text-decoration:none; cursor:$cursor-disabled; background-color:transparent; background-image:none;  @include reset-filter; }
}
.open {
  > .dropdown-menu { display:block;   }
  > a { outline:0; }
}
.dropdown-menu-right { right:0; left:auto; }
.dropdown-menu-left { right:auto; left:0; }
.dropdown-header { display:block; padding:3px 20px; font-size:$font-size-small; line-height:$line-height-base; color:$dropdown-header-color;
  white-space:nowrap; }
.dropdown-backdrop { position:fixed; top:0; right:0; bottom:0; left:0; z-index:($zindex-dropdown - 10); }
.pull-right > .dropdown-menu { right:0; left:auto; }
.dropup, .navbar-fixed-bottom .dropdown {
  .caret { content:""; border-top:0; border-bottom:$caret-width-base dashed; border-bottom:$caret-width-base solid \9; }
  .dropdown-menu { top:auto; bottom:100%; margin-bottom:2px; }
}
@media (min-width:$grid-float-breakpoint) {
  .navbar-right {
    .dropdown-menu { right:0; left:auto; }
    .dropdown-menu-left { left:0; right:auto; }
  }
}