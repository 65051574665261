.sticky-menu-label {
    &__toggle-button {
      width: 30px;
      height: 30px;
    }

    &__toggle-button-line {
      height: 6px;
      border-radius: 6px;
      &:nth-child(1) {
        top: 0px;
      }
  
      &:nth-child(2) {
        top: 12px;
      }
  
      &:nth-child(3) {
        top: 24px;
      }
    }
  }
  
  .header.header--open-menu {
    .sticky-menu-label__toggle-button {
      &-line {
        &:nth-child(1) {
          top: 12px;
          transform: rotate(135deg);
        }
        &:nth-child(2) {
          width: 0%;
          opacity: 0;
        }
        &:nth-child(3) {
          top: 12px;
          transform: rotate(-135deg);
        }
      }
    }
    .e-menu { //eshop menu
      display: none;
      &--open {
        display: block;
      }
    }
  }
  