//
// Input groups
// --------------------------------------------------
.input-group {
  position:relative; border-collapse:separate; 
  &[class*="col-"] { float:none; padding-right:0; padding-left:0; }
  .form-control {
    position:relative; z-index:2; float:left; width:100%; margin-bottom:0;
    &:focus { z-index:3; }
  }
}
/*
.input-group-lg > .form-control, .input-group-lg > .input-group-addon, .input-group-lg > .input-group-btn > .btn { @extend .input-lg; }
.input-group-sm > .form-control, .input-group-sm > .input-group-addon, .input-group-sm > .input-group-btn > .btn { @extend .input-sm; }
*/
.input-group-addon/*, .input-group-btn*/, .input-group .form-control {
  display:table-cell;
  &:not(:first-child):not(:last-child) { border-radius:0; }
}
.input-group-addon/*, .input-group-btn*/ { width:1%; white-space:nowrap; vertical-align:middle; }
.input-group-addon { 
  padding:$padding-base-vertical $padding-base-horizontal; font-size:$font-size-base; font-weight:400; line-height:1; color:$input-color; text-align:center;  background-color:$input-group-addon-bg; border:1px solid $input-group-addon-border-color;  border-radius:$input-border-radius; 
  &.input-sm { padding:$padding-small-vertical $padding-small-horizontal; font-size:$font-size-small; border-radius:$input-border-radius-small; }
  &.input-lg { padding:$padding-large-vertical $padding-large-horizontal; font-size:$font-size-large; border-radius:$input-border-radius-large; }
  input[type="radio"], input[type="checkbox"] { margin-top:0; }
}
.input-group .form-control:first-child, .input-group-addon:first-child/*, .input-group-btn:first-child > .btn, .input-group-btn:first-child > .btn-group > .btn, .input-group-btn:first-child > .dropdown-toggle, .input-group-btn:last-child > .btn:not(:last-child):not(.dropdown-toggle), .input-group-btn:last-child > .btn-group:not(:last-child) > .btn*/ { @include border-right-radius(0); }
.input-group-addon:first-child { border-right:0; }
.input-group .form-control:last-child, .input-group-addon:last-child/*, .input-group-btn:last-child > .btn, .input-group-btn:last-child > .btn-group > .btn, .input-group-btn:last-child > .dropdown-toggle, .input-group-btn:first-child > .btn:not(:first-child), .input-group-btn:first-child > .btn-group:not(:first-child) > .btn*/ { @include border-left-radius(0); }
.input-group-addon:last-child { border-left:0; }
/*
.input-group-btn {
  position:relative; font-size:0; white-space:nowrap;
  > .btn {
    position:relative;
    + .btn { margin-left:-1px; }
    &:hover, &:focus, &:active { z-index:2; }
  }
  &:first-child {
    > .btn, > .btn-group { margin-right:-1px; }
  }
  &:last-child {
    > .btn, > .btn-group { z-index:2; margin-left:-1px; }
  }
}
*/