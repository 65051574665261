.search-prelayer {
  width: 100%;
  margin: 0;

  &__clear {
    float: none;
  }

  &__content {
    max-height: calc(768px - 126px);
    padding-bottom: 126px;
  }
}
