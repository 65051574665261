/* desktop animation explore-antalis show or hide */
@keyframes slideDown {
  0% {
    transform: translateY(calc(-100vh + 70px));
    visibility: visible;
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes slideUp {
  0% {
    transform: translateY(calc(100vh + 70px));
    visibility: visible;
  }
  100% {
    transform: translateY(0);
  }
}

/* Mobile animation explore-antalis show or hide */
@keyframes slideDownMobile {
  0% {
    transform: translateY(calc(-100vh + 75px));
    visibility: visible;
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes slideUpMobile {
  0% {
    transform: translateY(calc(100vh + 75px));
    visibility: visible;
  }
  100% {
    transform: translateY(0);
  }
}
