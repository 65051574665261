.blue-text-and-image {
  display: flex;
  height: 384px;
  overflow: hidden;
  width: 100%;
  max-width: 1140px;
  margin: 0 auto;

  &__text {
    position: relative;
    width: 50%;
    color: $white;
    padding: 50px;
    background: #000033;
    height: 384px;
  }

  &__text-content {
    position: absolute;
    width: 70%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &__part-title {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 18px;
    color: $white;
    margin-bottom: 20px;

    &::before {
      content: "";
      display: inline-block;
      height: 2px;
      width: 15px;
      background: $white;
      margin-bottom: 5px;
      margin-right: 5px;
    }
  }

  &__title {
    margin-bottom: 20px;
    color: $white;
  }

  &__description {
    margin-bottom: 20px;
    line-height: 24px;
    color: $white;
    max-width: 365px;

    > p {
      color: $white;
      font-size: 18px;

      > a {
        color: $white;
        text-decoration: none;
        border-bottom: 1px white solid;

        &:hover {
          text-decoration: none;
          border-bottom: 0;
        }
      }

      &:last-child {
        margin-bottom: 32px;
      }
    }

    > a {
      color: $white;
      text-decoration: none;
      border-bottom: 1px white solid;

      &:hover {
        text-decoration: none;
        border-bottom: 0;
      }
    }

    > p + p {
      margin-top: 22px;
    }
  }

  &__media {
    width: 50%;
    height: 384px;

    > img,
    .image-cover {
      object-fit: cover;
      background-position: left center;
      width: 100%;
      height: 100%;

      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }

    .image-cover__object-fit {
      background-size: cover;
      max-height: 100%;
    }
  }

  &--out {
    height: 464px;
    overflow: inherit;

    .blue-text-and-image__text {
      margin: 40px 0;
    }

    .blue-text-and-image__media {
      margin: 40px 0;
      background: #000033;
      height: auto;

      img,
      .image-cover {
        width: auto;
        max-width: 100%;
        height: calc(100% + 80px);
        max-height: calc(100% + 80px);
        display: block;
        margin: 0 auto;
        margin-top: -40px;
        overflow: visible;

        img {
          object-fit: contain;
          width: 100%;
          height: 100%;
        }
      }

      .image-cover__object-fit {
        background-size: contain;
        max-height: calc(100% + 80px);
        max-width: 100%;
      }
    }
  }

  &--full-width {

    .blue-text-and-image__text {
      width: 100%;

      .blue-text-and-image__text-content {
        position: relative;
        left: 0;
        top: 0;
        transform: none;
        width: 100%;

        .blue-text-and-image__description {
          max-width: 100%;
        }
      }
    }
  }

}
