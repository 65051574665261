@-moz-keyframes spin {
    100% { -moz-transform:rotate(360deg); }
}
@-webkit-keyframes spin {
    100% { -webkit-transform:rotate(360deg); }
}
@keyframes spin {
    100% { -webkit-transform:rotate(360deg); transform:rotate(360deg); }
}

.loading { 
    position:fixed; top:0; left:0; width:100%; height:100%; z-index:99999; background-color:hsla(0,0,0,.5); display:none;
    &-icon { 
        position:absolute; top:50%; left:50%; transform:translate(-50%,-50%); width:100px; height:100px; border-radius:5px; background:#fff; padding:15px;
        svg { display:inline-block; width:70px; height:70px; animation:spin 1.5s linear infinite; }
    }
}