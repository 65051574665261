.panoramic-gallery {

  &__carousel-popup-media {
		max-height: 120px;
	}

  &__carousel-control {
    display: block !important;  
  }

}
