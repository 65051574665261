.key-point-item {
  display: flex;
  width: calc(100% + 30px);
  margin-left: -15px;
  margin-right: -15px;
  margin-bottom: 30px;

  &:hover {

    .desktop & .key-point-item__icon {
      .key-point-item__icon-image {
        transition: all .5s ease-in;
        transform: scale(1.5);
      }
      .image-cover.image-cover__object-fit {
        transition: all .5s ease-in;
        transform: scale(1.5);
      }
    }
  }

  &__icon {
    display: inline-block;
    width: 50%;
    overflow: hidden;

    .image-cover.image-cover__object-fit {
      background-size: contain;
      background-position: 50% 0 !important;
      transition: all .5s ease-in;
    }
  }

  &__icon-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: all .5s ease-in;
  }

  &__text {
    display: inline-block;
    width: 50%;
    padding: 20px;
    border-top: 1px #eeeef0 solid;
    border-right: 1px #eeeef0 solid;
    border-bottom: 1px #eeeef0 solid;
    color: $dark-blue;
  }

  &__text-title {
    display: block;
    margin-bottom: 20px;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: $dark-blue;
  }

  &__text-description {
    display: block;
    margin-bottom: 20px;
    font-size: 18px;
    line-height: 24px;
    color: $dark-blue;
  }

  &__text-link {
    display: block;
  }

}
