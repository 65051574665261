nav.wrapper-breadcrumb {
  padding: 10px;

  ol.breadcrumb-menu-list {
    column-gap: 5px;

    li {
      &::after {
        padding: 0 2px;
      }
    }
  }

  .breadcrumb-menu {
    margin-left: 20px;
  }

  .breadcrumb-icon {
    display: none;
  }
}