.tagged-carousel {
  margin: 20px 0;

  .carousel-indicators {
    top: 250px;
  }

  .carousel-inner {
    min-height: 284px;
  }

}
