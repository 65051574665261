.blog-edito {
  box-shadow: 0px 3px 4px 0px #DFDFDF;
  padding: 20px 40px;
  margin-bottom:30px;
  &__text {
    &-title {
      color: $pink;
      margin-bottom: 10px;
    }
    &-subtitle {
      color: $pink;
      font-size: 14px;
      font-family: 'Source Sans Pro',Verdana,sans-serif;
      font-style: italic;
    }
    &-description {
      line-height: 20px;
      margin-bottom: 24px;
    }
  }
  &__image {
    padding-left: 40px;
  }
}
