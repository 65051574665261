.our-location {

  &__header {
    text-align: center;
    margin-bottom: 45px;
  }

  &__title-page {
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 24px;
    font-weight: 700;
  }

  &__description-page {
    font-size: 24px;
    line-height: 32px;
  }

  &__filters-group {
    display: block;
    position: relative;
    margin-bottom: 35px;
    width: 100%;

    label {
      font-weight: 700;
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 5px;
    }

    input,
    label,
    button,
    select {
      display: block;
      outline: none;
    }
  }

  &__filters-button-reset {
    display: flex !important;
    border: 0;
    background: transparent;
    font-size: 20px;
    line-height: 24px;
    bottom: 0;
    position: absolute;
    text-align: left;
  }

  &__filters-icon-reset {
    color: $pink;
    width: 16px;
    height: 16px;
    font-size: 16px;
    margin-right: 10px;
    vertical-align: middle;
    line-height: 16px;
  }

  /*
    Map styles
  */


}
