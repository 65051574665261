.related-downloads-item {
  margin-bottom: 30px;

  &__title {
    display: block;
    margin-bottom: 15px;
    font-size: 18px;
    font-weight: 700;
    color: $dark-blue;
  }

  &__added-date {
    display: block;
    color: #7c7a8c;
    margin-bottom: 20px;
    font-size: 14px;
  }

  &__link {
    display: flex;
    line-height: 20px;

    > strong {
      margin-left: 10px;
    }
  }

  &__link-icon {
    color: $pink;
    margin-right: 10px;
    font-size: 14px;
    line-height: 14px;
  }
}
