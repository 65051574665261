.footer {
  border-bottom: 1px #eeeef0 solid;

  &__reinsurance-bar-2-wrapper {
    background: #fbfbfb;
    border-bottom: 1px #eeeef0 solid;
  }

  &__top {
    padding: 0 20px;
  }

  &__navigation {
    padding: 0 20px;
    border-top: 1px #eeeef0 solid;
    border-bottom: 1px #eeeef0 solid;
  }

  &__bottom {
    padding: 0 20px;
  }

}
