.inpage-banner {
  padding: 20px 0;

  &__title {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    //margin-left: 13%;
    color: $dark-blue;
    margin-bottom: 20px;
  }

  &__wrapper-image {
    overflow: hidden;
    max-height: 400px;
  }

  &__wrapper-image-link {
    display: block;
    line-height: 1;
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }
  }

  &__image {
    width: 100%;
    height: auto;
  }

  &__description {
    font-size: 14px;
    line-height: 20px;
    color: #7c7a8c;
  }

}
