.generic-cnt {
  &--impact {
    padding-left: 0;
    padding-right: 0;
  }

  &__mobile-table {
    position: relative;
    border-left: 1px solid #d8d8d8;
    border-right: 1px solid #d8d8d8;
    overflow: hidden;
    @include scrollbar();

    table {
      white-space: nowrap;

      tr {
        border-left: 0;
        border-right: 0;
      }
    }

    &--scrollbar {
      &::before {
        content: '';
        display: block;
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, .75);
      }

      &::after {
        content: '';
        display: block;
        position: absolute;
        top: calc(50% - 20px);
        left: 50%;
        transform: translate(-50%);
        width: 45px;
        height: 45px;
        background: url('images/icon-touch-gestures-move.svg');
        animation: slideGesture 2s linear infinite;
      }
    }

    &--hide {
      overflow-x: auto;

      &::before,
      &::after {
        animation: hideAnimate .5s;
        animation-fill-mode: both;
      }
    }
  }

  &__video-container {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
    margin: 18px 0;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      margin: 0;
    }
  }
}
