.footer-contact-us {

  .col-md-4 {
    border-right: 0;
    border-bottom: 1px solid #eeeef0;

    &:last-child {
      border: 0;
    }
  }

  .contact-section .generic-cnt {
    max-width: 100%;
  }
}
