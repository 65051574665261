.carousel-slider-wrapper {
  .carousel-slider {
    &-auto {
      .item {
        .carousel-banner__image { height:auto; }
      }
    }
    &--promotion, &--loyalty {
      display: none;
    }
    &--popular, &--promo {
      background:none; height:auto;
      .carousel-indicators { top:250px; }
    }
  }
}

.carousel-tiles .carousel-slider-wrapper picture { 
  aspect-ratio: 627 / 370!important; 
}