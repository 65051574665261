.carousel-banner {
  &__container {
    height: auto;
  }

  &__image {
    height: 340px;
    position: relative;

    .progressbar {
      display: none;
      height: 5px;
      background-color: #F0027F;
      bottom: 0;
      position: absolute;
      z-index: 2;
      animation-name: progress;
      animation-fill-mode: forwards;
      animation-duration: 1000s;
      animation-timing-function: linear;
    }
  }

  &__inner-text {
    .title {
      display: none !important;

      &:not(.second, .first) {
        display: block !important;
      }
    }
  }
}