.footer-sitemap {

  .col-md-3 {
    border-right: 0;
    border-bottom: 1px solid #eeeef0;

    padding-top: 50px;
    padding-bottom: 35px;
    margin-bottom: 0;

    &:last-child {
      border-bottom: 0;
    }
  }

  &__wrapper {

    .footer-sitemap-section {
      &:first-child {
        .footer-sitemap-section__subtitle{
          &:first-child {
            margin-top: 0;
          }
        }
      }
    }
  }
}
