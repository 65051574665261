.navigation {
  &--sector {
    padding-left: 0px;
    position: relative;
    padding: 0;

    .navigation__menu {
      position: relative;
      text-transform: none;
      display: flex;
      justify-content: space-between;
      padding-left: 0;

    }

    .navigation__menu-item {
      height: 33px;
      border-right: 1px solid #eeeef0;
      font-size: 16px;
      padding: 0 16px;
      margin: 15px 0;
      display: inline-flex;
      align-items: center;
      width: 100%;
      align-content: flex-start;
      /* IE fix */
      flex: 1 1 0%;
      min-width: 0;

      &:last-child {
        border-right: 0;
      }

      &:first-child {
        padding: 10px 25px 10px 36px;
        text-transform: uppercase;
      }
      .navigation__part-name-chevron {
        transform: rotate(0deg);
        transition: all .5s;
        margin: 0 0 0 15px;
        font-size: 12px;
      }

      &.navigation__menu-item-active,
      &.navigation__menu-item-selected,
      &:hover {
        .navigation__menu-link {
          color: #000033;
          &::before {
            transition: all .5s;
            height: 6px;
            background: #F0027F;
          }
        }
      }
      &.navigation__menu-item-active {
        .navigation__part-name-chevron {
          transform: rotate(-180deg) scaleX(-1);
          margin-top: -8px;
          transition: all .5s;
          color:  #F0027F;
        }
      }

    @include md-more {
      &--dropdown {
        position: relative;

        &__subLink {
          display: none;
          position: absolute;
          top: 33px;
          left: 0;
          flex-direction: column;
          padding-top: 15px;
          width: max-content;
        }

        &:hover {
          .navigation__menu-item--dropdown__subLink {
            display: flex;

            a {
              font-size: 14px;
              padding: 15px;
              background: $white;
              text-transform: uppercase;
            }
          }
          .navigation__part-name-chevron {
            transform: rotate(-180deg) scaleX(-1);
            margin-top: -8px;
            transition: all .5s;
            color: $pink;
          }
        }
      }
    }
    }

    .navigation__menu-link {
      white-space: normal;
      border: 0;
      padding: 0;
      width: calc(100% - 31px);

      &--center {
        text-align: center;
      }

      &::before {
        content: "";
        background: #F0027F;
        display: block;
        transition: all .5s;
        width: calc(100%);
        position: absolute;
        top: -15px;
        left: 0;
        margin: 0;
      }
    }

    .navigation__sub-menu {
      top: 32px;
      &-item {
        font-size: 80%;
      }
    }

    .navigation__part-name-chevron {
      display: inline-block;
      vertical-align: middle;
    }

    .navigation--sector-wrapper {
      position: relative;
      clear: both;
      width: 100%;
    }

    .navigation--sector-content {
      display: block;
      opacity: 0.3;
      overflow: hidden;
      max-height: 0;
      width: 100%;
      z-index: 3;
      position: absolute;
      background-color: #FBFBFB;
      left: 0;
      right: 0;
      top: 0;
      padding: 0 50px 0;
      transition: transform .3s, opacity .3s;
      transform: translateY(50px);

      &.navigation--sector-content--open {
        opacity: 1;
        max-height: 10000px;
        padding: 50px 0 20px;
        transform: translateY(0px);
      }
    }


    /*
        Tabs content styles
    */
    .navigation--sector-content-separate {

      &:nth-child(2),
      &:nth-child(3) {
        border-right: 1px solid  1px solid $medium-grey;
      }

      &:nth-child(2) {
        border-left: 1px solid  $medium-grey;
      }
    }

    .navigation--sector-content-column {
      min-height: 300px;
      padding: 0 40px;
      font-size: 16px;
      vertical-align: top;

      .link {
        display: block;
        margin-top: 30px;
      }
      p {
        font-size: 16px;
        line-height: 24px;
      }
      strong {
        font-weight: 700;
      }
    }

    .navigation--sector-content-column-title {
      font-size: 3rem;
      font-weight: 700;
      margin-bottom: 20px;
    }

    .navigation--sector-content-column-links-parts {
      display: none;
    }

    .navigation--sector-content-column-links {

      .link {
        display: block;
        margin: 0 0 25px;
      }
    }

    .navigation--sector-content-column-image {
      margin-bottom: 10px;
      width: 100%;
      max-width: 256px;
      height: auto;
    }

    .navigation--sector-content-bottom {
      padding: 0;
      background-color: white;
      height: auto;
      margin-top: 20px;
      margin-bottom: 0px;
      vertical-align: middle;
    }

    .navigation--sector-content-bottom-text {
      display: inline-block;
      padding: 0 20px;
      width: calc(100% - 158px);
      vertical-align: middle;
      font-size: 18px;
      line-height: 24px;

      strong {
        display: block;
        font-weight: 700;
      }
      .link {
        display: block;
      }
    }

    .navigation--sector-content-bottom-image {
      display: inline-block;
      background: #F0027F;
      max-width: 128px;
      vertical-align: middle;
    }

    .navigation--sector-content-closed-button {
      display: flex;
      position: absolute;
      right: 5vw;
      top: 15px;
      background: transparent;
      border: 0;
      outline: none;
      font-weight: 700;
      font-size: 16px;
      line-height: initial;
      text-align: left;
    }
    .navigation--sector-content-closed-icon {
      text-align: right;
      margin-left: 10px;
      font-size: 14px;
      vertical-align: top;
      line-height: initial;
    }

    .navigation__content-back {
      background: transparent;
      border: 0;
      display: flex;
      vertical-align: middle;
      outline: none;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      text-align: left;
    }
  }
}

@include md-more {
  .navigation--reduce-font {
    .navigation__menu-content > ul > li {
      padding: 14px 10px;
      > a {
        font-size: 13px;
      }
    }
  }
}
