//
// Tables
// --------------------------------------------------
table {
  background-color:$table-bg;
  col[class*="col-"] { position:static; display:table-column; float:none; }
  td, th {
    &[class*="col-"] { position:static; display:table-cell; float:none; }
  }
}
caption { padding-top:$table-cell-padding; padding-bottom:$table-cell-padding; color:$text-muted; text-align:left; }
th { text-align:left; }
.table {
  width:100%; max-width:100%; margin-bottom:$line-height-computed;
  > thead, > tbody, > tfoot {
    > tr {
      > th, > td { padding:$table-cell-padding; line-height:$line-height-base; vertical-align:top; border-top:1px solid $table-border-color; }
    }
  }
  > thead > tr > th { vertical-align:bottom; border-bottom:2px solid $table-border-color; }
  > caption + thead, > colgroup + thead, > thead:first-child {
    > tr:first-child {
      > th, > td { border-top:0; }
    }
  }
  > tbody + tbody { border-top:2px solid $table-border-color; }
  .table { background-color:$body-bg; }
}
.table-condensed {
  > thead, > tbody, > tfoot {
    > tr {
      > th, > td { padding:$table-condensed-cell-padding; }
    }
  }
}
.table-bordered {
  border:1px solid $table-border-color;
  > thead, > tbody, > tfoot {
    > tr {
      > th, > td { border:1px solid $table-border-color; }
    }
  }
  > thead > tr {
    > th, > td { border-bottom-width:2px; }
  }
}
.table-striped {
  > tbody > tr:nth-of-type(odd) { background-color:$table-bg-accent; }
}
.table-hover {
  > tbody > tr:hover { background-color:$table-bg-hover; }
}
/*
@include table-row-variant('active', $table-bg-active);
@include table-row-variant('success', $state-success-bg);
@include table-row-variant('info', $state-info-bg);
@include table-row-variant('warning', $state-warning-bg);
@include table-row-variant('danger', $state-danger-bg);
*/
/*
.table-responsive {
  min-height:.01%; overflow-x:auto;
  @media screen and (max-width:$screen-xs-max) {
    width:100%;
    margin-bottom:($line-height-computed * .75);
    overflow-y:hidden;
    -ms-overflow-style:-ms-autohiding-scrollbar;
    border:1px solid $table-border-color;
    > .table {
      margin-bottom:0;
      > thead, > tbody, > tfoot {
        > tr {
          > th > td { white-space:nowrap; }
        }
      }
    }
    > .table-bordered {
      border:0;
      > thead, > tbody, > tfoot {
        > tr {
          > th:first-child, > td:first-child { border-left:0; }
          > th:last-child, > td:last-child { border-right:0; }
        }
      }
      > tbody, > tfoot {
        > tr:last-child {
          > th, > td { border-bottom:0; }
        }
      }
    }
  }
}
*/