/* Styles for add this popup */

#at-expanded-menu-host {
  display: none;
  position: fixed !important;
  max-width: 400px !important;
  max-height: 400px !important;
  width: 100% !important;
  height: 100% !important;
  color: $dark-blue !important;
  top: 50% !important;
  left: 50% !important;
  margin-left: -200px !important;
  margin-top: -200px !important;
  z-index: 2;
  outline: none !important;
}

div#at-expanded-menu-container {
  width: 100%;
  max-width: 100%;
  height: 100%;
  position: relative;
  padding: 40px 20px 20px;
  outline: none !important;

  &:focus {
    outline: none !important;
  }
}

#at-expanded-menu-host .at-expanded-menu {
  position: fixed !important;
  max-width: 400px !important;
  max-height: 400px !important;
  width: 100% !important;
  height: 100% !important;
  background: white !important;
  color: $dark-blue !important;
  top: 50% !important;
  left: 50% !important;
  margin-left: -200px !important;
  margin-top: -200px !important;
  border: 1px solid #eeeef0;
  z-index: 2;
  box-shadow: 0px 0px 20px 0px rgba(37,34,63,1);
  outline: none !important;

  @include scrollbar();
}

#at-expanded-menu-host #at-expanded-menu-hd,
#at-expanded-menu-host #at-expanded-menu-bd {
  position: relative;
  width: 100%;
  display: block;
}

#at-expanded-menu-host .at-expanded-menu-mask,
#at-expanded-menu-host .at-expanded-menu-fade,
#at-expanded-menu-title,
a.at-branding-logo.at-expanded-menu-branding {
  display: none !important;
}

#at-expanded-menu-host .at-expanded-menu-close {
  position: absolute !important;
  right: 20px !important;
  top: 10px !important;
  outline: none !important;
  background: transparent !important;

  &:hover {
    background: transparent !important;
    border-radius: 0 !important;
    border: 0 !important;
  }
}

#at-expanded-menu-host .at-expanded-menu-search,
#at-expanded-menu-host .at-expanded-menu-page-title,
#at-expanded-menu-host .at-expanded-menu-page-url,
#at-expanded-menu-host .at-expanded-menu-top-services-header,
#at-expanded-menu-host .at-expanded-menu-ft {
  width: auto !important;
}

/* icons container */
#at-expanded-menu-host .at-expanded-menu-service-list {
  display: block !important;
  width: 100% !important;
  padding: 0 20px 30px !important;
  text-align: left;
}

#at-expanded-menu-host .at-expanded-menu-service-list.border-before {
  &::before {
    width: 100% !important;
    margin-top: -61px !important;
  }
}

#at-expanded-menu-host .at-expanded-menu-service-list li {
  display: inline-block;
  width: 49% !important;
  margin: 0 0 10px !important;
  min-width: auto !important;
}

#at-expanded-menu-host .at-expanded-menu-hidden,
#at-expanded-menu-host .at-expanded-menu-top-services-header.at-expanded-menu-hidden {
  display: none !important;
}

#at-expanded-menu-host .at-expanded-menu-ft {
  margin: 0 !important;
  padding-bottom: 30px!important;
}

/* icon */
#at-expanded-menu-host .at-expanded-menu-round .at-icon-wrapper {
  border-radius: 0 !important;
}

#at-expanded-menu-host .at-expanded-menu-service-list button {
  width: 20px !important;
  margin-right: 10px !important;
  vertical-align: top;
}
#at-expanded-menu-host .at-expanded-menu .at-icon-wrapper {
  width: 20px !important;
  height: 20px !important;
  line-height: 1 !important;
}
/* icons lable */
#at-expanded-menu-host .at-expanded-menu-round .at-expanded-menu-button-label{
  display: inline-block !important;
  width: calc(100% - 30px) !important;
  padding-right: 10px !important;
  font-size: 14px !important;
  text-align: left !important;
  line-height: 25px;
  vertical-align: middle;
}

/* styles for a text */
#at-expanded-menu-host .at-expanded-menu-round .at-expanded-menu-button-label,
#at-expanded-menu-host .at-expanded-menu-load,
#at-expanded-menu-host .at-expanded-menu-close,
#at-expanded-menu-host #at-expanded-menu-title,
#at-expanded-menu-host .at-branding-logo,
#at-expanded-menu-host .at-copy-link-result-message span,
#at-expanded-menu-host .at-copy-link-share-page-url,
#at-expanded-menu-host .at-expanded-menu,
#at-expanded-menu-host .at-expanded-menu-button-label,
#at-expanded-menu-host .at-expanded-menu-email-disclaimer,
#at-expanded-menu-host .at-expanded-menu-load-btn,
#at-expanded-menu-host .at-expanded-menu-page-title,
#at-expanded-menu-host .at-expanded-menu-page-url,
#at-expanded-menu-host .at-expanded-menu-privacy-link,
#at-expanded-menu-host .at-expanded-menu-search-label-content,
#at-expanded-menu-host .at-expanded-menu-top-services-header,
#at-expanded-menu-host .at-expanded-menu-search-label,
form#at-expanded-menu-filter-form,
#at-expanded-menu-host .at-expanded-menu-search-input[type=text] {
  color: $dark-blue !important;
  font-family: $font-normal !important;
  font-size: 14px !important;
  outline: none !important;
}

form#at-expanded-menu-filter-form {
  position: relative !important;
}


#at-expanded-menu-host .at-expanded-menu-search-label:after,
#at-expanded-menu-host .at-expanded-menu-search-label:before {
  border-bottom: 1px solid #eeeef0;
  color: $dark-blue !important;
}

@include xs {

  #at-expanded-menu-host {
    max-width: 100% !important;
    max-height: 100% !important;
    width: 100% !important;
    height: 100% !important;
    z-index: 30;
    top: 0 !important;
    left: 0 !important;
    margin-top: 0 !important;
    margin-left: 0 !important;
  }

  #at-expanded-menu-host .at-expanded-menu {
    position: fixed !important;
    max-width: 100%!important;
    max-height: 100% !important;
    width: 100% !important;
    height: 100% !important;
    z-index: 30;
    top: 0 !important;
    left: 0 !important;
    margin-top: 0 !important;
    margin-left: 0 !important;
  }
}
