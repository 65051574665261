.first-bar {

  &__logo-link {
    margin: 12px 0;
    max-width: 110px;
    max-height: 55px;
  }

  &__secondary-logo {
    margin: 12px 0 12px 25px;
    max-width: 110px;
    max-height: 55px;
  }

  .search-bar__wrapper {
    display: none;
  }
}
