.first-bar {

  &__logo-link {
    padding-left: 5px;
    margin: 12px 0;
    max-width: 150px;
    max-height: 60px;
  }

  &__secondary-logo {
    max-width: 150px;
    margin: 12px 0 12px 25px;
    max-height: 60px;
  }
}
