//
// Buttons
// --------------------------------------------------


// Base styles
// --------------------------------------------------
.btn {
  display:inline-block; margin-bottom:0; font-weight:$btn-font-weight; text-align:center; white-space:nowrap; vertical-align:middle; touch-action:manipulation; cursor:pointer; background-image:none; border:1px solid transparent;  @include button-size($padding-base-vertical, $padding-base-horizontal, $font-size-base, $line-height-base, $btn-border-radius-base); @include user-select(none);
  &, &:active, &.active {
    &:focus, &.focus { @include tab-focus; }
  }
  &:hover, &:focus, &.focus { color:$btn-default-color; text-decoration:none; }
  &:active, &.active { background-image:none; outline:0; @include box-shadow(inset 0 3px 5px rgba(0, 0, 0, .125)); }
  &.disabled, &[disabled], fieldset[disabled] & { cursor:$cursor-disabled; @include opacity(.65); @include box-shadow(none); }
}
a.btn {
  &.disabled, fieldset[disabled] & { pointer-events:none; }
}
.btn-default { @include button-variant($btn-default-color, $btn-default-bg, $btn-default-border); }
.btn-primary { @include button-variant($btn-primary-color, $btn-primary-bg, $btn-primary-border); }
.btn-success { @include button-variant($btn-success-color, $btn-success-bg, $btn-success-border); }
.btn-info { @include button-variant($btn-info-color, $btn-info-bg, $btn-info-border); }
.btn-warning { @include button-variant($btn-warning-color, $btn-warning-bg, $btn-warning-border); }
.btn-danger { @include button-variant($btn-danger-color, $btn-danger-bg, $btn-danger-border); }
/*.btn-link {
  font-weight:400; color:$link-color; border-radius:0;

  &, &:active, &.active, &[disabled],
  fieldset[disabled] & { background-color:transparent; @include box-shadow(none); }
  &, &:hover, &:focus, &:active { border-color:transparent; }
  &:hover, &:focus { color:$link-hover-color; text-decoration:$link-hover-decoration; background-color:transparent; }
  &[disabled], fieldset[disabled] & {
    &:hover, &:focus { color:$btn-link-disabled-color; text-decoration:none; }
  }
}
*/
.btn-lg { @include button-size($padding-large-vertical, $padding-large-horizontal, $font-size-large, $line-height-large, $btn-border-radius-large); }
.btn-sm { @include button-size($padding-small-vertical, $padding-small-horizontal, $font-size-small, $line-height-small, $btn-border-radius-small); }
/*
.btn-xs { @include button-size($padding-xs-vertical, $padding-xs-horizontal, $font-size-small, $line-height-small, $btn-border-radius-small); }
*/
.btn-block { display:block; width:100%; }
.btn-block + .btn-block { margin-top:5px; }
input[type="submit"], input[type="reset"], input[type="button"] {
  &.btn-block { width:100%; }
}