.links-to-other-sites {
  padding: 25px 0 0;

  &__navigation {
    margin: 0;
    padding: 0;
    //font-size: 1px;
    text-align: left;
    column-count: 2;
    column-gap: 60px;

  }

  &__navigation-item {
    // display: inline-block;
    // font-size: 18px;
    // margin-right: 60px;
    // margin-bottom: 25px;
    margin-right: 0;
    display: block;
    line-height: 24px;
    width: 100%;
    
    &:last-child {
      margin-right: 0;
    }
  }

  &__navigation-item-link {
    color: $dark-blue;
    text-transform: uppercase;
  }

}
