.buttons-block {
  padding: 75px 0;

  &__button {
    width: 100%;
    max-width: 295px;
    display: flex;
    margin: 20px auto 0;
  }

  &.reduced{
    padding: 23px 0;
  }

}
