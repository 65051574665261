.tools-and-services {

  &__links {
    padding: 25px 0 5px;
    display: flex;
    flex-wrap: wrap;
  }

  &__link {
    margin-bottom: 20px;
  }
}
