.carousel-slider-wrapper {
  .carousel-slider {
    .carousel-control {
      display: none;
    }

    .carousel-indicators {
      bottom: auto;
      top: 300px;
    }

    .carousel-inner {
      height: auto;

      .item {
        .carousel-banner__image {
          width: 100%;
          position: relative;
          left: 0;
          right: 0;
        }

        .carousel-banner__text {
          .title {
            &.first, &.second {
              display: none;
            }
          }
        }
      }
    }

    &-auto {
      .carousel-inner {
        height: auto;
      }

      .carousel-indicators {
        display: none;
      }

      .item {
        .carousel-banner__container {
          height: auto;
        }

        .carousel-banner__image {
          height: auto;

          >video,
          >img {
            position: relative;
            top: 0;
            transform: translateY(0);
          }
        }
      }
    }

    &--popular,
    &--promo {
      background: none;
      height: auto;

      .carousel-inner {
        height: auto;
      }

      .carousel-indicators {
        top: 250px;

        >li {
          background-color: rgba(255, 255, 255, 0.7);
        }
      }

      .item {
        .container {
          clear: both;

          .row {
            display: flex;
            flex-direction: column-reverse;
          }
        }

        .carousel-banner__container {
          height: auto;
        }

        .carousel-banner__image {
          height: 288px;

          >video,
          >img {
            position: relative;
            top: 0;
            transform: translateY(0);
          }
        }

        .carousel-banner__text {
          position: relative;
          margin-top: 0;
          transform: none;
          height: auto;
          background: transparent !important;
          height: auto;
          min-height: 100%;

          .title {
            font-weight: 700;
            font-size: 4rem;
          }

          .paragraph {
            margin: 20px 0 30px 0;
            font-size: 18px;
          }
        }
      }
    }

    &--promo {
      .item {
        .carousel-banner__text {
          background: #FBFBFB !important;
          border-top: 0;
          border-right: 1px #efefef solid;
          border-left: 1px #efefef solid;
          border-bottom: 1px #efefef solid;
          margin-right: -30px;
          padding: 20px 50px;
        }
      }
    }
  }

  .slider-caption-controls {
    a.link {
      font-size: 10px !important;
    }
    .carousel-slider__tabs {
      height: 40px !important;
      margin: 0 -15px;
      ul {
        height: 40px !important;
      }
    }
  }
}

.carousel-tiles {
  flex-wrap: wrap;
  .carousel-slider-wrapper {
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
    .carousel-indicators {
      display: block!important;
      bottom: 5%;
      top: inherit;
    }
  }
  .carousel-tiles-list {
    width: 100%;
    >.carousel-tile {
      margin-bottom: 20px;
      .carousel-tile-title {
        font-size: clamp(14px,3.15cqi,20px);
      }
    }
  }
}