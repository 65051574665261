.thank-you-download {
  padding: 50px 10px;

  &__wrapper-mobile {
    display: block;

    & ~ .thank-you-download__wrapper-desktop {
      display: none;
    }
  }

  &__title {
    font-size: 32px;
    line-height: 48px;
  }

  p {
    font-size: 24px;
    line-height: 40px;
  }

}
