.contact-line-blue {
  background: $dark-blue;
  color: $white;
  font-size: 12px;
  padding: 20px;
  display: block;

  &__wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__text,
  &__phone {
    color: $white !important;
    font-size: 12px;

    .generic-cnt {
      color: $white !important;
      font-size: 12px;

      p {
        color: $white !important;
      }
    }
  }

  &__text {
    margin-right: 50px;
    width: 70%;
  }

  &__phone {
    margin-right: 25px;
    width: 30%;
    text-align: right;
  }
}
