@keyframes buttonHover {

  0% {
    display: block;
    transform: translateY(0);
    opacity: 1;
  }

  49% {
    opacity: 0;
    display: none;
    transform: translateY(-100%);
  }

  51% {
    opacity: 0;
    display: none;
    transform: translateY(100%);
  }

  100% {
    display: block;
    opacity: 1;
    transform: translateY(0);
  }
}
