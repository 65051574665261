.navigation {

  &--sector {
    overflow: auto;

    .navigation__menu {
      display: block;
      border: 0;
    }

    .navigation__menu-item {
      border-bottom: 1px solid  $medium-grey;
      border-right: 0;
      display: block;
      padding: 16px 20px 16px 30px;
      margin: 0;
      position: inherit;
      width: 100%;
      height: auto;
      transition: border-right-width .3s;

      a {
        transition: font-size .3s;
      }

      &--center {
        text-align: left;
      }

      &.navigation__menu-item-active {
        .navigation__menu-link{
          font-size: 16px;
        }
      }
    }

    .navigation__part-name-chevron {
      display: inline-block;
      margin-right: 15px !important;
      transform: rotate(-90deg) !important;
      margin-top: 0px;
      float: right;
      position: absolute;
      right: 10px;
      top: 17.5px;

      &::after {
        clear: both;
      }
    }

    .navigation__menu-link {
      display: inline-block;
      width: calc(100% - 56px);
      text-align: left;

      &::before {
        content: "";
        display: none;
      }

      &:hover {
        &::before {
          content: "";
          display: none;
        }
      }
    }

    /*
        Tabs content styles
    */
    .navigation--sector-wrapper {
      position: relative;
      height: auto;
      margin-left: 0;
      top: auto;
    }

    .navigation--sector-content-separate {

      &:nth-child(2),
      &:nth-child(3) {
        border: 0;
      }
    }

    .navigation--sector-content {
      display: block;
      width: 100%;
      padding: 0;

      &.navigation--sector-content--open {
        padding: 0;
      }
    }

    .navigation__content-back {
      display: flex;
      width: 100%;
      padding: 15px 25px 10px 30px;
      border-bottom: 1px solid  $medium-grey;
      text-align: left;
      text-transform: uppercase;
      background: #fbfbfb;
    }
    .navigation__content-back-icon {
      display: inline-block;
      vertical-align: middle;
      height: 0;
      overflow: hidden;
    }
    .navigation__current-sector {
      display: inline-block;
      vertical-align: middle;
      margin-left:-20px;
    }

    .navigation--sector-content-column-title {
      margin-top: 20px;
      margin-bottom: 10px;
    }

    .navigation--sector-content-column {
      min-height: inherit;

      .link {
        padding: 15px 5px;
        margin-bottom: 0;
        margin-top: 0;
      }
    }

    .navigation--sector-content-column-links {
      padding: 0 25px;
    }

    .navigation--sector-content-bottom {
      padding: 25px;
      display: none;
    }

    .navigation--sector-content-column-images {
      margin: 0;
      min-height: auto;
      padding: 25px 25px 0;
      border-top: 1px solid  $medium-grey;
    }

    .navigation--sector-content-bottom-image {
      display: block;
      margin: 0 auto 20px;
    }

    .navigation--sector-content-bottom-text {
      width: 100%;
    }
  }

  &--has-opened-dropdown {
    .navigation__menu-content {
      height: auto;
      position: relative;
      background: white;
    }

    .navigation--sector-content {
      position: relative;
      left: auto;
      right: auto;
      top: auto;
      height: auto;
    }

    .navigation__content-back {
      padding: 10px 25px;
    }

    .navigation__content-back-icon {
      margin-right: 10px;
      height: 27px;
      margin-left: 0;
      min-width: 10px;
      font-size: 24px;
      margin-top: 0px;
      font-size:30px;
      line-height:32px;
    }

    .navigation__current-sector {
      margin-left: 0px;
      margin-top: 5px;
    }

    .navigation__menu-content > ul > li:not(.navigation__menu-item-active) {
      display: none;
    }

    .navigation__menu-content > ul > .navigation__menu-item-active  {
      border-left: 6px #F0027F solid;
      padding: 15px 0 15px 25px;
      border-bottom: 0;

      .navigation__part-name-chevron {
        display: none;
      }
    }
  }
}
