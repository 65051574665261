.icon-text-contact-item {
  position: relative;
  padding: 30px 25px 25px;
  background: white;
  border: 1px solid #eeeef0;
  margin-bottom: 25px;
  width: 100%;
  color: $dark-blue;
  text-align: center;

  .panel.eshop-panel-default {
    -webkit-border-radius: 0;
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    margin-bottom: 20px;
    padding-bottom: 80px;
    .panel-body {
      padding:0;
    }
  }
  
  .es-fs-16 {font-size: 16px!important;}
  .es-f-coexbold {font-weight: 700;}
  .es-m-b5{margin-bottom: 5px;}
  .es-m-b10{margin-bottom: 10px;}
  .es-f-exbold{font-weight: 700;}
  .es-lh-18{line-height:18px;}
  .es-td-u{text-decoration: underline;}

  .contactus__title {
    font-size: 24px!important;
    line-height: 32px;
    margin-bottom: 12px;
    font-weight: 700;
    text-transform: none;
  }

  .contactus__address {
    text-transform: uppercase;
    line-height: 22px;
  }

  .contactus__text {
    font-size: 16px;
    line-height: 24px;
  }

  .contactus__phone {
    font-size: 20px!important;
    line-height: 32px;
    text-decoration: none;
    font-weight: 700;
    margin-bottom: 0;
  }
  .contactus__phone-info {
    font-size: 10px;
    color: #707070;
    line-height: 14px;
  }

  .contactus__email {
    a {border-bottom: 0;}
  }

  &__icon {
    display: block;
    height: auto;
    width: 90px;
    margin: 0 auto 30px;
  }

  &__title {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 12px;
    font-weight: 700;
  }

  &__description {
    font-size: 18px;
    line-height: 32px;
    padding-bottom: 80px;

    a {
      text-decoration: none;
      border-bottom: 1px solid $dark-blue;
      color: $dark-blue;

      &:hover {
        text-decoration: none;
      }
    }

    .generic-cnt {
      font-size: 16px;

      p {
        font-size: 16px;
      }
    }
  }

  &__button {
    position: absolute;
    display: flex;
    bottom: 25px;
    width: calc(100% - 50px);
    min-width: calc(100% - 50px);
  }

  /* Modal */
  .modal {
    @include bootstrap-modal;
  }

  /* Contact us */
  &--contact {
    padding: 0 0 75px 15px;
    position: relative;
    border: 0;
    text-align: left;
    margin-bottom: 0;

    .icon-text-contact-item__button {
      position: absolute;
      bottom: 0;
      width: auto;
      min-width: 200px;
      max-width: 100%;
    }

    .icon-text-contact-item__description {
      padding-bottom: 0;
    }

    .generic-cnt {
      max-width: 280px;
    }
  }

  /* Right side section */
  &--right-side {
    padding: 25px;
    border: 0;
    border-bottom: 1px solid #eeeef0;
    text-align: left;
    margin: 0;

    .icon-text-contact-item__title {
      display: block;
      font-size: 16px;
      font-weight: 700;
      margin-bottom: 15px;
      text-transform: uppercase;
      color: $dark-blue;
      line-height: 16px;
    }

    .icon-text-contact-item__description {
      padding-bottom: 0;
    }

    .icon-text-contact-item__button {
      margin-top: 12px;
      display: flex;
      position: relative;
      bottom: auto;
      width: 200px;
      min-width: auto;
    }

  }

  &--center-side {
    .icon-text-contact-item__title {
      display: block;
      font-size: 16px;
      font-weight: 700;
      margin-bottom: 15px;
      text-transform: uppercase;
      color: $dark-blue;
      line-height: 16px;
    }
    .icon-text-contact-item__description {
      padding-bottom: 0;
    }
    .icon-text-contact-item__button {
      position: relative;
      bottom: 0;
      width: 200px;
      min-width: auto;
      margin: 12px auto 0 auto;
    }
  }

}
