.reinsurance-bar--footer {
    display: block;
    .reinsurance-bar {
    &__items {
    	min-height: 100px;
    }
    &__link-icon {
    	font-size: 18px;
    	line-height: 36px;
    }
    &__link {
    	font-size: 12px;
        padding: 10px 0;
    }
    &__link-title {
    	line-height: 14px;
    }
  }
}