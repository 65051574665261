// --------------------------------------------------
// REM fallback for IE8
// --------------------------------------------------

@mixin font-size($size-value: 16, $line-height: false) {
  font-size: $size-value + px;
  font-size: rem($size-value);
  @if $line-height {
    line-height: $line-height;
  }
}

// --------------------------------------------------
// 1.1  Text Replacement
// --------------------------------------------------
@mixin text-hide {
  background-color: transparent;
  border: 0;
  color: transparent;
  font: #{0/0} a;
  text-shadow: none;
}

@mixin font-family($font-weight: 300) {
  font-family: $font-normal;
  font-weight: $font-weight;
}

// Maintain ratio mixin. Great for responsive grids, or videos.
// https://gist.github.com/brianmcallister/2932463
//
// $ratio - Ratio the element needs to maintain.
//
// Examples
//
//   // A 16:9 ratio would look like this:
//   .element {
//     @include maintain-ratio(16 9);
//   }
@mixin maintain-ratio($ratio: 1 1) {
  @if length($ratio) < 2 or length($ratio) > 2 {
    @warn "$ratio must be a list with two values.";
  }

  $width: 100%;
  $height: percentage(nth($ratio, 2) / nth($ratio, 1));

  width: $width;
  height: 0;
  padding-bottom: $height;
}


@mixin carousel-indicators() {
  position: absolute;
  bottom: 30px;
  margin: 0 auto;
  left: 50%;
  width: auto;
  padding-left: 0;
  text-align: center; 
  list-style: none;
  z-index: 2;
  font-size: 0;
  transform: translate(-50%, 0);
  li {
    display: inline-block;
    margin: 0 3px;
    width: 20px;
    height: 20px;
    border: none;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.7);

    &.active {
      background-color: #F0027F;
      margin: 0 3px;
    }
  }
}

@mixin carousel-arrows() {
  .carousel-control {
    background: transparent;
    opacity: 1;
    width: clamp(30px,3.2cqi,50px);
    position: absolute;
    top: 50%;
    bottom: inherit;
    transform: translateY(-50%);
    &.left {
      left: 2%;
      right: inherit;
    }
    &.right {
      right: 2%;
      left: inherit;
    }
    .right-control,
    .left-control {
      width: clamp(30px,3.2cqi,50px);
      height: clamp(30px,3.2cqi,50px);
      border-radius: 50%;
      background-color: rgba(255, 255, 255, 0.7);
      &:hover {
        background-color: white;
        cursor: pointer;
      }
    }
  }
}


@mixin scrollbar {

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 0;
    transition: background-color .4s;
    background: rgba(0, 0, 0, .1);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 0;
    background: rgba(0, 0, 0, .25);
  }
}
