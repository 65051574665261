@media print {
    .bodywrapper {
      margin:0!important; -webkit-print-color-adjust:exact; 
    }
      //Remove href URL added by bootstrap in Print Mode
      a[href]:after { display:none; visibility:hidden; }
  
      //HEADER FOOTER FIXES
      header.header { position:relative!important; }
      .reinsurance-bar, .eshop-navigation,footer.footer { display:none!important; }
  
      //SOME GLOBAL FIXES
      .btn, .es-btn, .btn-pink { display:none;}
  }