.top-menu {
  height: 55px;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  &__user,
  &__login,
  &__card,
  &__shop {
    margin-left: 32px;
    display: flex;
    justify-content: flex-start;
  }

  &__icon-cart,
  &__icon-cart-active {
    width: auto;
    height: 18px;
    display: inline-block;
  }

  &__login-icon {
    width: auto;
    height: 18px;
    display: inline-block;
  }

  &__login-content,
  &__card-content,
  &__shop-content {
    font-size: 16px;
    line-height: 21px;
    font-weight: 700;
    width: 100%;

    img {
      display: inline-block;
      vertical-align: middle;
    }

    span {
      font-size: 16px;
      display: inline-block;
      vertical-align: text-top;
      font-weight: 700;
      line-height: 21px;
    }

    .link {
      display: inline-block;
      vertical-align: middle;
    }

    &__switch {
      font-weight: bold;
    }
  }

  &__shop-content {
    .link {
      line-height: 1;
      padding-left: 5px;
    }
  }

  &__card-content {

    .top-menu__icon-cart-active {
      display: none;
    }

    .top-menu__detail-card {
      display: none;
      vertical-align: middle;
      color: $pink;
      padding-top: 0px;
      font-weight: 700;

      .nb-item {
        background-color: $pink;
        color: $white;
        padding: 0 5px;
        font-size: 12px;
        height: 17px;
      }
    }

    &--active {
      .top-menu__icon-cart,
      .top-menu__txt-card {
        display: none;
      }

      .top-menu__detail-card,
      .top-menu__icon-cart-active {
        display: inline-block;
      }
    }
  }

}
