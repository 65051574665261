.carousel-banner {
  &.item {
    height: auto;
  }

  &:hover {
    .video-controls.video-controls-show.video-play {
      display: block;

      .pause-button {
        display: block;
        opacity: 1;
        transition: all .5s;
      }
    }
  }

  &__container {
    height: 600px;
    position: relative;
  }

  &__text {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 10%;
    width: 80%;
    .title {
      font-size: clamp(18px, 3.15cqi, 60px);
      margin-bottom: clamp(10px, 1.68cqi, 32px);
      color: $white;
      font-weight: 700;
      &.first {
        font-size: clamp(16px, 2cqi, 38px);
        margin-bottom: clamp(10px, 1.68cqi, 32px);
      }
      &.second {
        font-size: clamp(14px, 1.57cqi, 30px);
        margin-bottom: clamp(10px, 1.68cqi, 54px);
      }
    }
    &.carousel-banner__text {
      &--white {
        .title {color: $white;}
      }
      &--pink {
        .title {color: $pink;}
      }
      &--blue {
        .title {color: $dark-blue;}
      }
    }

  }

  &__image {
    height: 600px;
    overflow: hidden;

    .generic-video {
      height: inherit;
      width: 100%;
    }

    img,
    video {
      display: block;
      height: auto;
      width: 100%;
      min-height: 100%;
      object-fit: cover;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      font-family: 'object-fit:cover;';
    }

    .no-object-fit & video {
      top: 0;
      transform: none;
    }

    .progressbar {
      display: none;
    }
  }

  &__video {
    height: 100%;
    position: relative;

    .generic-video__controls {
      display: none;
      position: absolute;
      z-index: 2;
      width: 56px;
      height: 56px;
      border-radius: 50%;
      background: rgba(255, 255, 255, .7);
      margin-left: -23px;
      margin-top: -23px;
      left: 50%;
      top: 50%;
      cursor: pointer;
      transition: all .5s;
    }

    .no-object-fit & object-fit {
      top: 0 !important;
    }
  }
  
  &__link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%
  }
}