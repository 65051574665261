.screen-overlay {
  background: rgba(0,0,0, .5);
  display: none;
  height: 100dvh;
  opacity: 0;
  position: fixed;
  top: 0;
  transition: opacity 500ms;
  width: 100vw;
  z-index: 49;

  &--show {
    display: block;
    opacity: 1;
    transition: opacity 500ms;
  }

  &--prelayer {
    z-index: 2;
  }

  &--prelayer-light {
    z-index: 4;
  }
}
