.link-card {
  position: relative;
  width: 100%;
  min-height: 182px;
  background: #f6f6f7;
  border: 1px #eeeef0 solid;
  overflow: hidden;
  display: flex;

  &__link {
    display: block;
    width: 100%;
    min-height: 100%;
    color: $dark-blue;
    text-decoration: none;
  }

  .desktop & .link-card__link:hover,
  .desktop & .link-card__link:focus,
  .tablet & .link-card__link:hover,
  .tablet & .link-card__link:focus {
    color: $dark-blue;
    text-decoration: none;
    cursor: pointer;

    .link-card__text {
      transform: translateY(-100%);
      transition: all .5s;
    }

    .link-card__text-hover {
      top: 0;
      //transform: translateY(-100%);
      transition: all .5s;
    }
  }

  &__text {
    position: relative;
    padding: 20px 20px 40px;
    display: block;
    width: 100%;
    height: 100%;
    transition: all .5s;
    line-height: 24px;
    // min-height: 100%;
  }

  &__title,
  &__hover-title {
    color: $dark-blue;
    margin-bottom: 15px;
    font-size: 18px;
    font-weight: 700;
  }

  &__description,
  &__hover-description {
    color: $dark-blue;
    font-size: 16px;
  }

  &__arrow {
    position: absolute;
    bottom: 15px;
    right: 25px;
    width: 45px;
    height: 22px;
    z-index: 3;
    transition: all .5s;
    text-align: right;

    &:hover {
      right: 10px;
      transition: all .5s;
    }
  }

  &__arrow-icon {
    color: $dark-blue;
    font-size: 25px !important;
  }

  &__text-hover {
    padding: 20px 20px 40px;
    position: absolute;
    color: white;
    top: 100%;
    //transform: translateY(100%);
    background: $pink;
    display: block;
    width: 100%;
    height: 100%;
    transition: all .5s;
    line-height: 24px;

    .link-card__arrow-icon {
      color: white
    }
  }

  &__hover-title,
  &__hover-description {
    color: white
  }

}
