.search-prelayer {
  position: fixed;
  width: calc(100% - 70px);
  height: auto;
  z-index: 50;
  background: white;
  top: 0;
  left: 0;
  margin: 0 35px;
  top: -500%;
  transition: top 500ms;
  overflow-y: scroll;

  &--open {
    top: 200px;
    transition: top 500ms;
    z-index: 5;
  }

  &__header {
    position: relative;
    padding: 20px 0 0;
    min-height: 50px;
    background: #fbfbfb;
  }

  &__close {
    position: absolute;
    display: flex;
    right: 20px;
    top: 10px;
    color: $dark-blue;
    text-decoration: none;
    line-height: 26px;

    &:hover {
      text-decoration: none;
    }
  }

  &__close-icon {
    display: inline-block;
    font-size: 15px;
    width: 15px;
    height: 15px;
    margin-left: 10px;
  }

  &__content {
    position: relative;
    background: #fbfbfb;
    // overflow-y: scroll;
    padding-bottom: 20px;
    // height: 100%;
    // max-height: 600px;
    //height: calc(100vh - 126px);
  }

  &__content-header {
    margin-bottom: 20px;
    margin-top: 30px;
    position: relative;
  }

  &__content-title {
    display: inline-block;
    font-size: 18px;
    font-weight: 700;
    color: $dark-blue;
    width: calc(100% - 300px);

  }

  &__content-more {
    position: absolute;
    max-width: 250px;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
  }

  &__content-more-link {
    float: right;
  }

  &__clear {
    display: none;
    color: $dark-blue;
    text-decoration: none;
    margin: 45px 0;
    float: right;
    width: 16px;
    height: 16px;
    font-size: 16px;
    opacity: 0;
    display: none;
    transition: opacity 500ms;

    &--show {
      // TODO: if need show, display block;
      display: none;
      opacity: 1;
      transition: opacity 500ms;
    }
  }

  .row--border-bottom {

    &:last-child {
      border: 0;
    }
  }

  &__content-empty {
    padding: 75px 0;
    font-size: 18px;
    font-weight: 700;
    color: $dark-blue;
  }
}
