.event-summary {
  padding: 35px 25px;
  color: $dark-blue;
  border-bottom: 1px $medium-grey solid;

  &--bg-color {
    background: $background-grey;
  }

  &__date {
    font-size: 13px;
    line-height: 32px;
    margin-bottom: 5px;
    color: $dark-blue;
    font-weight: 700;
  }

  &__time {
    font-size: 13px;
    line-height: 24px;
    margin-bottom: 15px;
    color: $dark-blue;
    font-weight: 700;
  }

  &__download-link {
    display: flex;
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 10px;
    color: $dark-purple;

    &:hover {
      color: $pink;
      text-decoration: none;
    }
  }

  &__download-link-icon {
    color: $pink;
    font-size: 14px;
    line-height: 24px;
    margin-right: 10px;
  }

  &__location {
    font-size: 16px;
    line-height: 32px;
    margin-bottom: 3px;
    color: $dark-blue;
    font-weight: 700;
  }

  &__get-direction {
    display: flex;
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 15px;
    color: $dark-purple;
    text-decoration: none;

    &:hover {
      color: $pink;
      text-decoration: none;
    }
  }

  &__get-direction-icon {
    display: inline-block;
    color: $pink;
    margin-right: 10px;
    font-size: 20px;
    font-size: 14px;
    line-height: 24px;
  }

  &__price {
    font-size: 16px;
    line-height: 24px;
    color: $dark-blue;
    font-weight: 700;
  }

  &__company-name {
    font-size: 16px;
    line-height: 24px;
    color: $dark-purple;
  }

  &__mail-link {
    font-size: 16px;
    line-height: 24px;
    color: $dark-purple;
    text-decoration: underline;

    &:hover {
      color: $pink;
      text-decoration: none;
    }
  }

}
