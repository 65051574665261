$taggedCarouselHeight: 660px;

.tagged-carousel {
  margin: 82px 0 0px;
  position: relative;
  z-index: 0;

  .carousel {
    max-width: 100%;
  }

  .carousel-slider {
    height: auto !important;
  }

  .carousel-indicators {
    @include carousel-indicators();
    position: relative;
    top: -75px;
    bottom: auto;
    left: 0;
    width: 60%;
    padding-right: 40%;
    margin: 45px auto 0;
    z-index: 6;

    li {
      background-color: #eeeef0;
    }
  }

  .carousel-inner {
    min-height: $taggedCarouselHeight;
    position: relative;
    width: 100%;
  }

  .carousel-control-arrow {
    @include carousel-arrows();
    left: 0;
    width: 100%;
    max-width: 100%;
    z-index: 10;

    .right-control,
    .left-control {
      background-color: transparent !important;
    }
  }

}

.col-xs-12 {

  .tagged-carousel {
    .carousel-control-arrow {
      @include carousel-arrows(); left: -60px; width: calc(100% + 120px); max-width: 100vw;
      .right-control, .left-control { background-color: transparent !important; }
    }
    @media (max-width:$bp-md-max) {
      .carousel-control-arrow { display: block; z-index: 10; left: 0; width: 100%; max-width: 100vw; min-width: 100px; }
    }
  }
}

.col-md-9 {
  .tagged-carousel {
    .carousel-control-arrow {
      display: none;
    }
  }
}
