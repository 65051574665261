@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

@keyframes hideup {
  0% {
    top: 50%;

    -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%); 
  }
  50% {

    -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0); 
    top: 20px;
  }
  100% {
    -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0); 
    top: 20px;
    opacity: 0;
  }
}
