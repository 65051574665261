body, button, input, textarea {
  font-family: $font-normal;
}

//SCROLL DEASABLED
body {
  font-size: 14px;
  background-color: $white;
  color: $dark-blue;
  &.open-menu {
    overflow-y: hidden;
  }
}

//paragraph
p {
  color: $dark-blue;
  font-size: 14px;
  font-family: $font-normal;
}

//TITLE
h1, h2, h3, h4, h5, h6 {
  color: $dark-blue;
}

h1 {
  font-size: 4rem;
  font-weight: 700;
  margin-bottom: 40px;
  @mixin sm-less { font-size: 3.2rem; }
}

h2 {
  font-size: 3.2rem;
  font-weight: 700;
  margin-bottom: 24px;
  @mixin xs { font-size: 2.6rem; }
  @mixin sm-less { font-size: 3.2rem; }
}

h3 {
  font-size: 2.4rem;
  margin-bottom: 24px;
  font-weight: 700;
}

h4 {
  font-size: 2.2rem;
  font-weight: 300;
  margin-bottom: 32px;
  @mixin xs { font-size:2rem; }
}

h5 {
  font-size: 1.8rem;
  font-weight: 700;
}

h6 {
  font-size: 1.6rem;
}

.link {
 color: $dark-blue;

  &:hover {
    color: $pink;
  }

  &:hover,
  &:focus {
    text-decoration: none;

    .bubble-pink {
      @extend .bubble-pink-hover;
    }
  }

  &:focus:not(:hover) {
    color: $dark-blue;
  }

  &--big {
      font-weight: 700;
      text-transform: uppercase;
      text-decoration: underline;
  }

}

//BUBBLE
.bubble-pink {
  height: 10px;
  width: 10px;
  display: inline-block;
  margin-right: 10px;
  // background: url('images/bubble-pink.svg') no-repeat;
  // background-size: 10px 10px;
  transition: all .4s;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    border: 3px solid $pink;
  }
}

.bubble-pink-hover {
  height: 10px;
  width: 10px;
  margin-right: 10px;
  display: inline-block;
  // background: url('images/bubble-pink-hover.svg') no-repeat;
  transition: all .4s;
  // background-size: 10px 10px;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    display: block;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    border: 3px solid $pink;
    background-color: $pink;
  }
}

//LIST
ul {
  list-style: none;
  padding-left: 0;
}

.btn-pink {
  display: inline-block;
  text-align: center;
}

strong {
  font-weight: 700;
}
