.scroll-lock {
  overflow: hidden !important;
  touch-action: none;
  width: 100%;
  scrollbar-gutter: stable;
}

@supports (-webkit-touch-callout: none) {
  .scroll-lock {
    position: fixed;
  }
}

 /* ===== Native Scrollbar CSS ===== */
@include sm-more {
  .scroll-styled {
    /* Firefox */
    * {
      scrollbar-width: thin;
      scrollbar-color: #d3d3d9 #ffffff;
    }
    /* Chrome, Edge, and Safari */
    *::-webkit-scrollbar {
      width: 6px;
    }
    *::-webkit-scrollbar-track {
      background: #ffffff;
    }
    *::-webkit-scrollbar-thumb {
      background-color: #d3d3d9;
      border-radius: 10px;
    }
  }
}