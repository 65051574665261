//
// Modals
// --------------------------------------------------
.modal-open { overflow:hidden; }
.modal {
  position:fixed; top:0; right:0; bottom:0; left:0; z-index:$zindex-modal; display:none; overflow:hidden; -webkit-overflow-scrolling:touch; outline:0;
  &.fade .modal-dialog { @include translate(0, -25%); @include transition-transform(0.3s ease-out); }
  &.in .modal-dialog { @include translate(0, 0); }
}
.modal-open .modal { overflow-x:hidden; overflow-y:auto; }
.modal-dialog { position:relative; width:auto; margin:10px; }
.modal-content { position:relative; background-color:$modal-content-bg; background-clip:padding-box; border:1px solid $modal-content-fallback-border-color; border:1px solid $modal-content-border-color; border-radius:$border-radius-large; @include box-shadow(0 3px 9px rgba(0, 0, 0, .5)); outline:0; }
.modal-backdrop {
  position:fixed; top:0; right:0; bottom:0; left:0; z-index:$zindex-modal-background; background-color:$modal-backdrop-bg; 
  &.fade { @include opacity(0); }
  &.in { @include opacity($modal-backdrop-opacity); }
}
.modal-header { padding:$modal-title-padding; border-bottom:1px solid $modal-header-border-color; @include clearfix; }
.modal-header .close { margin-top:-2px; }
.modal-title { margin:0; line-height:$modal-title-line-height; }
.modal-body { position:relative; padding:$modal-inner-padding; }
.modal-footer {
  padding:$modal-inner-padding; text-align:right; border-top:1px solid $modal-footer-border-color; @include clearfix; 
  .btn + .btn { margin-bottom:0; margin-left:5px; }
  .btn-group .btn + .btn { margin-left:-1px; }
  .btn-block + .btn-block { margin-left:0; }
}
.modal-scrollbar-measure { position:absolute; top:-9999px; width:50px; height:50px; overflow:scroll; }
@media (min-width:$screen-sm-min) {
  .modal-dialog { width:$modal-md; margin:30px auto; }
  .modal-content { @include box-shadow(0 5px 15px rgba(0, 0, 0, .5)); }
  .modal-sm { width:$modal-sm; }
}
@media (min-width:$screen-md-min) {
  .modal-lg { width:$modal-lg; }
}