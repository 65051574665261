.locations {

  &__link {
    display: inline-block;
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }
  }

  &__image {
    vertical-align: middle;
    margin: 10px 0;
  }

  &__locations-title {
    display: inline-block;
    margin-right: 25px;
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
    vertical-align: middle;
    padding: 10px 0;
    color: $dark-blue;
    &:hover {
      text-decoration: none;
      color: $pink;
    }
  }
}
