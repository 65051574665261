.right-side-navigation {
  border-left: 1px solid #eeeef0;
  border-right: 1px solid #eeeef0;
  border-bottom: 1px solid #eeeef0;

  .buttons-block {
    padding: 25px;

    .buttons-block__button {
      width: 100%;
      min-width: 100%;
      margin: 0 auto 20px;
    }
  }

  .es-fs-16 {font-size: 16px!important;}
  .es-f-coexbold {font-weight: 700;}
  .es-m-b5{margin-bottom: 5px;}
  .es-m-b10{margin-bottom: 10px;}
  .es-f-exbold{font-weight: 700;}
  .es-lh-18{line-height:18px;}
  .es-td-u{text-decoration: underline;}

  .contactus__title {
    font-size: 24px!important;
    line-height: 32px;
    margin-bottom: 12px;
    font-weight: 700;
    text-transform: none;
  }

  .contactus__address {
    text-transform: uppercase;
    line-height: 22px;
  }

  .contactus__text {
    font-size: 16px;
    line-height: 24px;
  }

  .contactus__phone {
    font-size: 20px!important;
    line-height: 32px;
    text-decoration: none;
    font-weight: 700;
    margin-bottom: 0;
  }
  .contactus__phone-info {
    font-size: 10px;
    color: #707070;
    line-height: 14px;
  }

  .contactus__email {
    a {border-bottom: 0;}
  }

}
