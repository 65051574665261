.footer {

  &__top {
    text-align: center;
  }

  &__navigation {
    text-align: center;
  }

  &__bottom {
    text-align: center;
  }
}
