.related-downloads {
  padding: 30px 25px;
  border-bottom: 1px solid #eeeef0;

  &__title {
    font-weight: 700;
    margin-bottom: 25px;
    font-size: 18px;
    text-transform: uppercase;
    color: $dark-blue;
  }

  &__button {
    min-width: 100%;
  }
}
