.social-network-item {
  display: inline-block;
  background: #000033;
  margin-right: 23px;
  width: 32px;
  height: 32px;
  overflow: hidden;
  text-align: center;
  border-radius: 50%;

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    cursor: pointer;

    .desktop & .social-network-item__icon {
      animation: iconHover .35s;
    }
  }

  &__link {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: white;
    font-size: 16px;
    width: 32px;
    height: 32px;
  }
}
