.footer {

  &__top {
    padding: 0 10px;
  }

  &__navigation {
    padding: 0 10px;
  }

  &__bottom {
    padding: 0 10px;
  }
}
