:root {
  --message-container-height: 0px;
}

.search-prelayer-light {
  position: absolute;
  width: 100%;
  height: auto;
  max-height: calc(100dvh - 120px - var(--message-container-height));
  z-index: 7;
  top: -3000px;
  left: 0;
  margin: 0px;
  overflow-y: auto;
  min-height: 52px;
  overflow: auto;
  overscroll-behavior: none;
  color: $dark-blue;
  &--open {
    top: calc(100% + 20px);
  }
  &__header {
    position: relative;
    background: #fbfbfb;

    & .col-md-12 {
      height: 28px;
      padding-top: 6px;
    }
  }

  &__close {
    position: absolute;
    display: flex;
    right: 20px;
    top: 10px;
    color: $dark-blue;
    text-decoration: none;
    line-height: 26px;

    &:hover {
      text-decoration: none;
    }
  }

  &__close-icon {
    display: inline-block;
    font-size: 15px;
    width: 15px;
    height: 15px;
    margin-left: 10px;
  }

  &__content {
    position: relative;
    background: #fbfbfb;

    & .col-md-12 {
      &.child, &.main {
        height: 40px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #e9e9e9;
      }
    }
  }

  &__content-header {
    margin-bottom: 20px;
    margin-top: 30px;
    position: relative;
  }

  &__content-title {
    display: inline-block;
    font-size: 18px;
    font-weight: 700;
    color: $dark-blue;
    width: calc(100% - 300px);

  }

  &__content-more {
    position: absolute;
    max-width: 250px;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
  }

  &__content-more-link {
    float: right;
  }

  &__clear {
    display: none;
    color: $dark-blue;
    text-decoration: none;
    margin: 45px 0;
    float: right;
    width: 16px;
    height: 16px;
    font-size: 16px;
    opacity: 0;
    display: none;
    transition: opacity 500ms;

    &--show {
      display: none;
      opacity: 1;
      transition: opacity 500ms;
    }
  }

  .row--border-bottom {
    &:last-child {
      border: 0;
    }
  }

  &__content-empty {
    padding: 75px 0;
    font-size: 18px;
    font-weight: 700;
    color: $dark-blue;
  }

  &--disabled {
    display: none;
  }

  .main {
    font-weight: 700;
    background-color: #e9e9e9;
    text-transform: uppercase;
  }

  .child {
    background-color: #fff;

    .item-name {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: calc(100% - 35px);
      display: inline-block;
      vertical-align: middle;

      &.inspirational {
        max-width: 100%;
      }
    }

    .count {
      display: inline-block;
      margin-bottom: 0px;
      vertical-align: middle;
    }
  }

  .col-md-12 a {
    color: $dark-blue;
    text-decoration: none;
    width: 100%;
    line-height: 18px;
    &:hover {
      color: #F0027F;
    }
  }

  .fa-angle-right:before {
    font-size: 17px;
  }

  .bg-white {
    background: white;
  }

  .no-result {
    padding: 10px;
  }

  .suggestions-inspiration {
    .col-md-12.main, .col-md-12.child {
      border: 0;
      height: 24px;
    }
  }

}

.header--sticked {
  .search-prelayer-light--open {
    max-height: calc(100dvh - 80px);
  }
}

@media (max-width:$bp-sm-max) {
  .search-prelayer-light--disabled {
    display: block;
  }
}

.prelayer-open {
  .navigation {
    &::after {
      background: rgba(0,0,0,.5);
      position: absolute;
      width: 100%;
      height: 100%;
      content: '';
      top: 1px;
      z-index: 10;
    }
  }
}