.news-and-events {

  &__heading {
    display: block;
    font-size: 30px;
    line-height: 38px;
    width: 100%;
    margin-bottom: 16px;
  }

  &__see-all {
    display: inline-block;
    line-height: 28px;
    text-align: left;
    width: 100%;
    max-width: 100%;
  }

  &__all-news {
    margin: 20px 0
  }

}
