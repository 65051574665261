.first-bar {
  position: relative;
  z-index: 2;
  background-color: white;
  width: 100%;
  padding: 0 20px;

  &__logo-link {
    display: inline-block;
    width: 100%;
    max-width: 142px;
    height: auto;
    max-height: 55px;
    margin: 20px 0;
    padding-left: 16px;
    vertical-align: middle;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  &__secondary-logo {
    display: inline-block;
    vertical-align: middle;
    color: $pink;
    border-left: 1px #eeeef0 solid;
    font-size: 25px;
    margin: 35px 18px;
    padding: 2px 0 2px 25px;
    max-width: 178px;
    //max-height: 30px;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  &__secondary-logo > img,
  &__logo-link > img {
    vertical-align: middle;
  }

  &__help-message {
    padding: 22px;
  }

  &__btn-contact {
    min-width: 100%;
    width: auto;
    margin-top: 27px;
  }

  &__select-order {
    display: flex;
    width: 100%;
    height: 51px;
    background: #e9e7ed;
    padding: 17px 15px 17px 20px;
    text-align: left;
    color: $dark-blue;
    line-height: 22px;
    overflow: hidden;
    margin-top: 27px;

    &--open {
      height: 100px;
    }

    &:hover {
      cursor: pointer;
    }
  }

  &__select-order-label {
    display: inline-block;
    width: calc(100% - 20px);
    font-size: 14px;
    margin-right: 5px;
  }

  &__select-order-button {
    display: inline-block;

  }

  &__select-order-icon {
    width: 18px;
    height: 14px;
    font-size: 16px;
  }

  &__share {
    background: transparent;
    border: none;
    width: 32px;
    height: 50px;
    display: none !important;
    transition: all .4s;

    &--show {
      display: flex !important;
      align-items: center;
      transition: all .4s;
    }
  }

  &__share-icon {
    width: 32px;
    height: 32px;
  }

  .search-bar__wrapper {
    display: block;
  }

}

.header.header--sticky:not(.header--show-logos) {
  .first-bar {
    &__logo-link,
    &__secondary-logo  {
      display: none;
    }
  }
}
