.navigation {
  border: 0;
  left: 0;
  height: calc(100vh - 160px - var(--message-container-height));
  overflow-x: hidden;
  padding: 0;
  position: absolute;
  transition: .5s ease-in-out;
  max-width: 80vw;
  min-width: 300px;
  width: 60%;
  z-index: 15;
  background: white;
  left: -100%;
  margin-left: 0;
  transition: margin-left .3s;
  top: 100%!important;

  &--open {
    margin-left: 100%;
    transition: margin-left .3s;
    padding-bottom: 20px;
  }

  &.fixed {
    position: fixed;
    top: 90px;
  }

  &__menu-header,
  &__menu-footer {
    display: block;
  }

  &__menu-footer {
    position: relative;
    background: white;

    .btn-contact {
      width: calc(100% - 50px);
      margin: 30px 25px;
    }
  }

  &__menu-content {
    position: relative;
  }

  &__menu {
    background-color: $background-grey;
    overflow: hidden;
    padding-left: 0;
    width: 100%;
    display: block;
    transition: all .3s;
    border: 0;

    &--slide-left {
      overflow: visible
    }
  }

  &__chevron {
    transform: rotate(-90deg);
    margin-top: 4px;
    position: absolute;
    right: 15px;
  }

  &.open {
    display: block;
    opacity: 1;
    transition: .5s ease-in-out;
  }

  &__menu-item {
    border-bottom: 1px solid  $medium-grey;
    display: block;
    padding: 20px 20px 18px;
    position: inherit;
    width: 100%;

    &:hover,
    &:focus {
      &.navigation__sub-menu {
        animation: none;

        &.navigation__sub-menu-item {
          padding: 15px;
        }
      }
    }

    &:hover {
      .navigation__menu-link {
        &::before {
          content: "";
          display: none;
        }
      }
    }
  }

  &__menu-link {
    border-right: 0 none;
    display: block;
    padding: 0 0 0 10px;

    &::before {
      content: "";
      display: none;
    }

    &:hover {
      &::before {
        content: "";
        display: none;
      }
    }
  }

  &__sub-menu-part-name {
    background: $background-grey;
    border-bottom: 1px solid #eeeef0;    
	  font-size: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 20px 20px 18px 30px;
    opacity: 1;
    height: 51px;

    &:hover {
      cursor: pointer;
    }
  }

  &__part-name-chevron {
    display: inline-block;
    margin-right: 15px;
    transform: rotate(90deg);
    height: 18px;
    font-size:20px !important;
    line-height:12px !important;

    &::after {
      clear: both;
    }
  }

  &__sub-menu {
    background: none;
    border-bottom:0;
    display: block;
    left: 100%;
    position: absolute;
    top: 0 !important;
    white-space: normal;
    width: 100%;
    z-index: 13;
    transition: left .3s;
    height: calc(100vh - 160px - var(--message-container-height));
    overflow: hidden;

    &--open {
      left: 0;
      max-height: 10000px;
      overflow: visible;
    }

    &.open {
      display: block;
      position: absolute;
    }
  }

  &__sub-menu-item {
    border-bottom: 1px solid #eeeef0;
    padding: 15px 20px;
    background: $white;
  }

  &__sub-menu-item-link {
    display: flex;
    padding: 10px;

    span.bubble-pink {
      min-width: 10px;
    }
  }
}


// MANAGE NAVIGATION TABLET MOBILE HEIGHT WHEN THE MENU IS STICKED
.header--sticked {
  .navigation {
    height: calc(100vh - 160px);
    &__sub-menu {
      height: calc(100vh - 160px);
    }
  }
}