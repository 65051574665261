$carouselHeightDefault: 600px;
$carouselHeightEshop: 388px;
$carouselHeightPromo: 700px;
$popularImageShift: -25px;


.carousel-global-container {
  transition: all .3s linear 0s;
  margin-left: 0;
  &.left-shift-menu {
    margin-left: var(--width-menu-one-level, 380px);
  }
}

.carousel-slider-wrapper {
  max-width: 100vw;
  .carousel-slider {
    position: relative;
    container: banner / inline-size;

    &.slide {
      height: auto;
      padding-bottom: 0;
    }

    .carousel-indicators {
      @include carousel-indicators();
    }

    .carousel-control-arrow {
      @include carousel-arrows();
    }

    .carousel-inner {
      position: relative;
      width: 100%;
      overflow: hidden;
      height: $carouselHeightDefault;
    }

    &--promotion {
      margin-top: 10px;
      .item .carousel-banner__text {
        .btn-pink {
          background-color: #FF4E00;
        }
      }
      &.carousel-slider-auto .item .carousel-banner__image picture {
        aspect-ratio: 1254 / 360;
      }
    }

    &--loyalty {
      margin-top: 10px;
      .item .carousel-banner__text {
        .title.second {
          display: none;
        }
      }
    }

    &-auto { 
      .carousel-inner,
      .carousel-banner__container {
        height: auto;
      }

      .item {
        .carousel-banner__image {
          height: auto; 
          overflow: hidden; 
          picture {
            aspect-ratio: 1920 / 493;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          img,video { 
              height: auto;
              min-height: inherit;
              position: relative;
              top: 0;
              transform: none;
              font-family: none;
              object-fit: contain;
              max-height: 100%;
              width: 100%;
          }

        }

        .carousel-banner__text {
          .title {
            margin-bottom: clamp(10px, 1.06cqi, 20px);
          }

          .btn-pink {
            margin-top: 10px;
            width: clamp(140px, 11cqi, 200px);
            height: clamp(36px, 2.63cqi, 50px);
            min-width: clamp(140px, 11cqi, 200px);
            min-height: clamp(36px, 2.63cqi, 50px);
            font-size: clamp(12px, 0.84cqi, 18px); 
            padding: 0 5px;
          }
        
        }
      }
    }

    &--popular,
    &--promo {
      height: $carouselHeightPromo;
      padding-bottom: 55px !important;

      .carousel-control-arrow {
        max-width: 100%;
      }

      .carousel-banner__container {
        height: $carouselHeightPromo;
      }

      .carousel-control {
        background: transparent;
        opacity: 1;
        margin-top: -40px;

        .right-control,
        .left-control {
          background-color: rgba(235, 235, 235, 0.7);

          &:hover {
            background-color: rgba(235, 235, 235, 1);
          }
        }
      }

      >.carousel-indicators {
        bottom: 20px;

        li {
          background: #ebebeb;
          width: 17px;
          height: 17px;
        }
      }

      .carousel-inner {
        height: 614px;
        position: relative;
      }

      .item {
        .carousel-banner__container {
          max-width: 1100px;
          width: 100%;
          margin: 0 auto;
          position: relative;
        }

        .carousel-banner__image {
          position: absolute;
          height: 614px;
          top: 0;
          left: 50%;
          right: $popularImageShift;
          bottom: 0;

          >video,
          >img {
            position: relative;
            top: 0;
            transform: translateY(0);
            object-fit: cover;
            font-family: 'object-fit:cover;';

            @media screen and (-ms-high-contrast:active),
            (-ms-high-contrast:none) {
              & {
                height: 100%;
                width: auto;
                top: 0;
                left: 50%;
                transform: translateX(-50%);
              }
            }
          }
        }

        .carousel-banner__text {
          height: 614px;
          width: 50%;
          padding: 10px 30px;
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          transform: none;
          display: flex;
          align-items: center;

          .title {
            font-weight: 700;
            font-size: 4rem;
            color: #000033;
            width: 100%;
          }

          .paragraph {
            margin: 20px 0 30px 0;
            font-size: 18px;

            p {
              font-size: 18px;
            }
          }
        }
      }
    }

    &--popular {
      .carousel-inner {
        background: #FBFBFB;
      }

      .carousel-banner__text {
        padding: 0 50px 0 80px;
      }
    }

    &--promo {
      .carousel-banner__text {
        position: relative;
        background: #FBFBFB;
        border-top: 1px #efefef solid;
        border-left: 1px #efefef solid;
        border-bottom: 1px #efefef solid;
        right: -30px;
        padding: 0 50px;
      }
    }
  }

  .slider-caption-controls {
    .menu-content {
      .list-links-menu {
        text-align: center;

        .carousel-slider__tabs {
          position: relative;
          overflow: hidden;
          border-left: 1px solid #eeeef0;
          height: 84px;

          ul {
            padding-left: 0;
            font-size: 0;
            width: 100%;
            overflow: visible;
            height: 84px;
            display: flex;
            flex-wrap: nowrap;
            position: relative;

            li {
              position: relative;
              flex: 1 1 33.33%;
              min-width: 33.33%;
              border-bottom: 1px solid #eeeef0;
              border-right: 1px solid #eeeef0;
              cursor: pointer;
              display: flex;
              flex-wrap: wrap;
              align-items: center;
              justify-content: center;

              &:hover {
                >a {
                  color: #F0027F;
                }
              }

              >a {
                font-weight: 700;
                font-size: 1.8rem;
                padding: 10px;
                text-align: center;
                box-sizing: border-box;
              }

              &.active {
                background-color: #F6F6F7;
              }

              .progressbar {
                position: absolute;
                display: block;
                top: 0;
                left: 0;
                height: 5px;
                background-color: #F0027F;
                animation-name: progress;
                animation-fill-mode: forwards;
                animation-duration: 1000s;
                animation-timing-function: linear;
              }
            }
          }
        }
      }
    }
  }
}

.carousel-slider-wrapper[paused='true'] {
  .progressbar {
    animation-play-state: paused !important;
  }
}

.carousel-tiles {
  display: flex;
  margin-left: 0;
  transition: all 0.3s linear 0s;
  .carousel-slider-wrapper {
    margin-right: calc(19 / 1900 * 100%);
    font-size: 0;
    width: calc((((1900 - 19) / 3) * 2) / 1900 * 100%);
    picture {
      aspect-ratio: 1254 / 360!important;
    }
  }
  .carousel-tiles-list {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: calc(((1900 - 19) / 3) / 1900 * 100%);
    container: tiles/inline-size;
    .carousel-tile {
      position: relative;
      font-size: 0;
      display: block;
      .carousel-tile-title {
        position: absolute;
        top: 20px;
        left: 20px;
        width: 50%;
        z-index: 1;
        font-size: clamp(14px,4.2cqi,24px);
        color: white;
        font-weight: 600;
        &--pink {color: $pink;}
        &--white {color: $white;}
        &--blue {color: $dark-blue;}
      }
    }
    picture {
      aspect-ratio: 627 / 170;
      height: auto; 
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        object-fit: contain;
        width: 100%;
        max-height: 100%;
      }
    }


  }
}
