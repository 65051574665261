.blockquote-with-cta {

  .left {
    margin: 65px auto;
    width: 90%;
  }

  .left .blockquote {
    margin-bottom: 30px;
  }

  .left .blockquote img {
    display: block;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .left .blockquote p {
    font-size: 2rem;
    font-weight: 700;
  }

  .btn-pink {
    display: inline-flex;
    margin-right: 20px;
    vertical-align: middle;
    margin-bottom: 30px;
  }

  .left .link {
    display: block;
    text-decoration: none !important;

    &:hover {
      text-decoration: none !important;
    }
  }

  .btn-light {
    margin: 0 0 30px 0;
    vertical-align: middle;
    //border: 2px solid #000033;
  }

  .generic-cnt {
    p q:before {
      display: none;
    }
  }
}
