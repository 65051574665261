.tagged-carousel {
  margin: 42px 0;

  .carousel-indicators {
    width: 100%;
    padding-right: 0;
    margin: 0 auto;
    z-index: 6;
    bottom: auto;
    position: absolute;
    top: 400px;
    left: auto;
  }

  .carousel-inner {
    min-height: 440px;
    height: auto;
  }

  .carousel-control-arrow {
    display: none !important;
  }

}
