.panoramic-gallery {

	&--full-width {
		position: relative;
		height: 472px;
		overflow: hidden;

		.panoramic-gallery__media {
			max-height: 472px;
			height: 100%
		}
	}

	&__media {
		position: relative
	}

	&__image {
		display: block;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	&__video {
		display: block;
		width: 100%;
		height: auto;
	}

	&__view-link {
		position: absolute;
		bottom: 0;
		right: 0;
		background: rgba(255, 255, 255, .5);
		height: 40px;
		text-decoration: none;
		cursor: pointer;
		transition: all .3s;

		&:hover {
			background: rgba(255, 255, 255, .7);
		}
	}

	&__view-gallery {
		height: 40px;
		line-height: 45px;
		font-size: 14px;
		font-weight: bold;
		font-weight: 700;
		color: #000033;
		padding: 0 68px 0 28px;
	}

	&__plus-icon {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		width: 40px;
		color: $white;
		text-align: center;
		line-height: 45px;
		font-size: 25px;
		background-color: $pink;
	}

	&__media-info {
		margin: 15px 0 20px;
	}

	&__first {
		font-size: 14px;
		font-weight: 700;
	}

	&__dash {
		font-size: 14px;
		font-weight: 700;
		color: #7a7881;
	}

	&__last {
		font-size: 14px;
		font-weight: 700;
	}

	&__media-title {
		margin-left: 20px;
		font-size: 14px;
		color: #9d9d9d;
	}

	&__media-text-wrapper {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		width: 55%;
		left: 10%;
		right: 10%;
	}

	&__media-text-category,
	&__media-text-title,
	&__media-text-description {
		color: white;
	}

	&__media-text-category {
		text-transform: uppercase;
		font-weight: 700;
		font-size: 18px;
		line-height: 24px;
		margin-bottom: 25px;

		&::before {
			content: "";
			display: inline-block;
			height: 3px;
			width: 15px;
			background: white;
			margin-bottom: 5px;
			margin-right: 5px;
		}
	}

	&__media-text-title {
		font-weight: 700;
		font-size: 40px;
		line-height: 48px;
		margin-bottom: 25px;
	}

	&__media-text-description {
		font-weight: 300;
		font-size: 24px;
		line-height: 32px;
		margin-bottom: 25px;
	}

	&__media-text-wrapper-button {
		display: none;
	}

	/*

		Modal

	*/

	&__modal-dialog {
		width: 100%;
		margin: 0;
		height: 100%;
	}

	&__modal-content {
		border-radius: 0;
		background-color: black;
		box-shadow: none;
		color: white;
		min-height: 100vh;
		border: 0;
	}

	&__modal-header {
		border: 0;

		.close {
			color: white;
		}
	}

	&__modal-title {
		color: white;
	}


	&__modal-body {
		// border: 0;
	}

	&__modal-footer {
		border: 0;
		text-align: center;
	}


	/*

		Carousel

	*/

	&__modal-content {
		padding: 15px 0;
	}

	&__carousel-item-title {
		text-align: center;
		font-size: 22px;
		margin: 35px 0;
	}

	&__modal-title {
		font-size: 14px;
		font-family: $font-normal;
	}

	&__carousel-popup-media {
		position: relative;
		width: calc(100vw / 1.5);
		left: 50%;
		margin-left: calc((-100vw / 1.5) / 2);
		height: 55vh;
		overflow: hidden;
	}

	.generic-video {
		height: 100%;
	}

	&__carousel-item {
		height: auto !important;
	}

	&__carousel-image,
	&__carousel-item .generic-video__media {
		width: 100%;
		height: 100%;
		overflow: hidden;
		object-fit: cover;
		/* Polyfill for IE: */
		font-family: 'object-fit: cover;';
	}

	.close {
		color: white;
		opacity: 1;
		font-family: $font-normal;
		vertical-align: top;
		font-size: 16px;
		text-shadow: none;
		font-weight: normal;
		outline: none;

		> span {
			font-size: 25px;
			display: inline-block;
			vertical-align: top;
			margin-top: -2px;
			font-weight: 300;
			margin-left: 3px;
			color: #7a7881;
		}
	}

	&__carousel-control {
		opacity: 1;
		width: 40px;
		height: 40px;
		border-radius: 50%;
		background-image: none;
		background-color: rgba(255,255,255, 0.7);
		top: 50%;
		transition: all .5s;

		&:hover {
			transition: all .5s;
			background-color: rgba(255,255,255, 1);
		}

		&--left {
			left: 5%;
		}
		&--right {
			right: 5%;
		}
	}

	&__modal-header-shares {
		width: 25px;
		height: 25px;
		float: right;
		margin-top: -10px;
		margin-right: 30px;

		.share-button__button {
			width: 25px;
			height: 25px;
		}
	}

	&__modal-header-shares-icon {
		width: 25px;
		height: 25px;
	}
}

@media only screen and (min-width : 1920px) {

  .panoramic-gallery__carousel-popup-media {
		max-height: 560px;
	}
}
