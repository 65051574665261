nav.wrapper-breadcrumb {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  padding: 10px 20px;
  border-bottom: 1px solid #eeeef0;

  .breadcrumb-menu {
    margin-left: 50px;
  }

  .breadcrumb-icon {
    display: flex;
    align-items: center;
    column-gap: 1.5rem;

    .breadcrumb__icon-link--print {
      background: url('images/print.svg') center no-repeat;
      width: 25px;
      height: 25px;
    }

    .share-button__icon {
      background: url('images/share.svg') center no-repeat;
      width: 15px;
      height: 15px;
    }
  }

  ol.breadcrumb-menu-list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    column-gap: 8px;
    margin: 0;
  }

  li:not(:last-child) {
    &::after {
      content: ">";
      position: relative;
      top: -1px;
      font-size: 1rem;
      color: #7c7a8c;
      padding: 0 5px 0 8px;
    }
  }

  li {
    color: #7c7a8c;
    white-space: nowrap;

    a {
      color: #7c7a8c;
      text-decoration: none;
      transition-duration: 300ms;

      &:hover {
        color: #23527c;
      }
    }

    &:last-child {
      font-weight: 700;
    }
  }

  button {
    width: 25px;
    background-color: transparent;
    border:none;
    -webkit-appearance: none;
    -moz-appearance: none;
  }
}