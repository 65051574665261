.benefits {

  .col-md-4 {
    border: 1px solid #eeeef0;
    margin-bottom: 0;

    &:nth-child(n+1) {
      border-top: 0;
    }

    &:first-child {
      border-top: 1px solid #eeeef0;
    }
  }
}
