.legal-links {
  text-align: right;
  margin: 30px 0;

  &__items {
    display: inline-block;
    font-size: 1px;
    padding: 0;
    margin-bottom: 15px;
  }

  &__item {
    font-size: 14px;
    display: inline-block;
    margin-right: 30px;

    &:last-child {
      margin-right: 0;
    }
  }

  &__item-link {
    color: $dark-blue;

    &:hover {
      color: $pink;
      text-decoration: none;
      cursor: pointer;
    }
  }

  &__copyright {
    display: inline-block;
    margin: 0 0 0 30px;
  }

}
