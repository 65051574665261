.case-study-results-item {
  display: flex;
  min-height: 110px;
  margin-bottom: 55px;

  &__title {
    color: $pink;
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    vertical-align: top;
  }

  &__description {
    font-size: 18px;
    line-height: 24px;
    vertical-align: top;
  }

  .col-md-6 {

    &:nth-child(2) {
      border-left: 1px $dark-blue solid;
      min-height: 100%;
    }
  }
}
