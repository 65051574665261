.introductory-text {
  padding: 20px 0;
  text-align: center;

  &--no-padding-bottom {
    padding-bottom: 0;
  }

  &--left-align {
    text-align: left;

    .introductory-text__wrapper {
      margin-left: 9.1%;
      max-width: 695px;
    }

  }

  &__wrapper-desktop {
    margin-bottom: 20px;
  }

  &__wrapper-mobile {
    display: none;
    margin-bottom: 20px;
  }

  &__title {
    font-size: 40px;
    line-height: 48px;
  }

  p {
    font-size: 24px;
    line-height: 32px;
  }

  &__button {
    width: auto;
  }

  &__link {
    display: block;
    margin-top: 40px;
  }

}

.introductory-text + .form-wrapper {
  padding-top: 0;
  margin-top: -80px;
}

.introductory-text + .latest-content {
  margin-top: 0;

  .latest-content-filters {
    margin-top: 0px; 
  }
}
