.testimonial-carousel {
	background: #fbfbfb;
	padding: 75px 0 80px;
	position: relative;

	&__title {
		display: block;
		text-align: center;
		margin: 0 auto 75px;
	}
	.carousel {
		margin: 0 40px;
	}

	.carousel-inner > .item {
		align-items: stretch;
		height: 100%;
		gap: 20px;
		&.active, &.next.left, &.prev.right {
			display: flex;
		}
		> .inner-item {
			display: flex;
			width: 100%;
		}
	}

	&__arrows-wrapper {
		position: absolute;
		top: calc(50% - 75px);
		transform: translateY(-50%);
		width: calc(100% + 100px);
		left: -50px;
		height: 40px;
		z-index: 0;
		.carousel-control {
			width: 40px;
			height: 40px;
			border-radius: 50%;
			background-image: none!important;
			background-color: rgba(255, 255, 255, 0.7);
			display: inline-block;
			opacity: 1;
	
			&::after {
				clear: both;
			}
	
			&:hover {
				background-color: white;
				cursor: pointer;
			}
	
			&.left {
				float: left;
			}
	
			&.right {
				float: right;
			}
		}
	}

	&__button {
		display: flex;
		margin: 50px auto 0;
	}

	.carousel-indicators {
		margin-top: 35px;
		position: relative;
		height: 25px;

		> li {
			margin: 0 3px;
			width: 20px;
			height: 20px;
			border: none;
			border-radius: 50%;
			background-color: rgba(255, 255, 255, 0.7);
			border: solid 1px $pink;

			&.active {
				width: 20px;
				height: 20px;
				background-color: $pink;
				margin: 0 3px;
			}
		}
	}
}