.promotional-block {

  &__title {
    font-size: 18px;
    margin-bottom: 8px;
  }

  &__description {
    font-size: 14px;
    margin: 5px 0;
  }
  &__content-banner {
    > .btn-pink {
      height: 36px;
      min-height: 36px;
    }
  }
  &__video-controls--show,
  &__video-controls-mobile--show {
    display: block;
  }
}
