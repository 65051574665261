@font-face {
  font-family: 'Source Sans Pro';
  src: url('fonts/sourcesanspro-light.woff2') format('woff2'),
       url('fonts/sourcesanspro-light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Source Sans Pro';
  src: url('fonts/sourcesanspro-regular.woff2') format('woff2'),
       url('fonts/sourcesanspro-regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Source Sans Pro';
  src: url('fonts/sourcesanspro-bold.woff2') format('woff2'),
       url('fonts/sourcesanspro-bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

