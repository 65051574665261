.links-to-other-sites {
  padding: 20px 0 0;

  &__navigation {
    margin: 0;
    padding: 0;
    font-size: 1px;
  }

  &__navigation-item {
    display: inline-block;
    font-size: 18px;
    margin-right: 35px;
    margin-bottom: 20px;

    &:last-child {
      margin-right: 0;
    }
  }

  &__navigation-item-link {
    color: $dark-blue;
    text-transform: uppercase;
    word-break: break-word;
    &:hover {
      text-decoration: none;
      color: $pink;
    }
  }

}
