.locations-map {

  &__location {
    margin-bottom: 35px;
  }

  &__map-button {
    position: relative;
    padding: 20px 25px 15px;
    border: 1px solid #eeeef0;
    background: white;
    text-transform: uppercase;
    font-size: 18px;
    line-height: 24px;
    font-weight: 700;

    &:hover {
      cursor: pointer;
    }

    &--show {
      .locations-map__map-icon {

        .fa-plus {
          display: none;
        }

        .fa-minus {
          display: block;
        }
      }
    }
  }

  &__map-wrapper {
    height: 0;
    opacity: 0;
    transition: all 500ms;
    background: white;
    overflow: hidden;
    border-right: 1px solid #eeeef0;
    border-left: 1px solid #eeeef0;

    &--show {
      height: 455px;
      opacity: 1;
      transition: all 500ms;
      border-bottom: 1px solid #eeeef0;
    }
  }

  &__map-icon {
    position: absolute;
    right: 25px;
    top: 20px;
    color: #7c7a8c;

    .fa-plus {
      position: absolute;
      right: 0;
      display: block;
    }

    .fa-minus {
      position: absolute;
      right: 0;
      display: none;
    }
  }

  &__map {
    margin-top: -40px;
    display: block;
    height: 515px;
    width: 100%;

    a {
      color: $dark-blue
    }

    #content {
      max-width: 300px;
      width: 100%;
      margin: 0;
      color: $dark-blue;

      .header-content {
        font-size: 16px;
        line-height: 24px;
        font-weight: bold;
        margin-bottom: 10px;
      }

      .body-content {
        font-weight: normal;
        text-decoration: underline;
        font-size: 14px;
        line-height: 18px;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }

  // styles for delete close button in google infowindow
  .gm-style-iw + div {
      display: none;
  }
  .gm-style-iw {
    text-align:center;
  }
}
