.reinsurance-item {
  display: block;
  margin: 0 10px 35px;
  width: 100%;
  text-align: center;

  &__wrapper-image {
    position: relative;
    display: block;
    width: 80px;
    height: 80px;
    overflow: hidden;
    text-align: center;
    margin-bottom: 35px;
    transform: translateX(-50%);
    left: 50%;
  }

  &__image {
    width: 100%;
    height: auto;
  }

  &__title {
    margin-bottom: 20px;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    text-transform: uppercase;
  }
  
  &__text {
    display: block;
    position: relative;
    transform: translateX(-50%);
    left: 50%;
    max-width: 150px;
  }
}
