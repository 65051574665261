.form-wrapper {
  //padding: 35px 5px;

  .form-group {
    button.btn.btn-sm {
      width: 100%;
    }
  }

  &--push-up {
    padding: 35px 25px;
  }

  /* Styles Dapackier */

  .datePickerMin {

    .uib-datepicker-popup.dropdown-menu {
      width: 100%;
    }

    table.uib-daypicker {
      margin: 10px 0 !important;
    }

    .uib-datepicker {
      margin: 10px 0 !important;
      min-width: auto;
    }

    th.text-center.ng-scope {
      padding: 10px 0 !important;
    }
    table thead tr th button.btn.btn-default.btn-sm.pull-left.uib-left,
    table thead tr th button.btn.btn-default.btn-sm.pull-right.uib-right {
      width: auto !important;
    }

    table {
      tbody {
        tr {
          td {
            button.btn.btn-default.btn-sm {
              padding: 5px !important;
              animation: none !important;

              &:hover {
                animation: none !important;
              }
            }
          }
        }
      }
    }

    .uib-button-bar .btn-group,
    .uib-button-bar .uib-close {
      margin: 0 10px 10px;
    }
  }
}
