.key-points {
  padding-bottom: 80px;

  &--icons {

    .row--equal-height>* {
      margin-bottom: 0;
    }

    .key-point-item {
      padding: 30px;
      transition: background .4s;
      margin-bottom: 0;

      .key-point-item__icon {
        width: 19%;
        vertical-align: top;
      }

      .key-point-item__icon-image {
        height: auto;
      }

      .key-point-item__text {
        width: 80%;
        border: 0;
        padding: 0;
        padding-left: 25px;
      }
    }

    .desktop & .key-point-item:hover {

      transition: background .4s;
      background: #eeeef0;

      .key-point-item__icon {

        .key-point-item__icon-image {
          transform: none!important;
        }
      }
      .image-cover.image-cover__object-fit {
        transform: none!important;
      }
    }
  }

  &__button {
    display: flex;
    margin: 60px auto 0;
  }
}

.container .key-points .container {
  width: 100%;
}
