.cookies {
  background-color: $dark-blue;
  padding: 20px;
  color: $white;
  font-weight: bold;
  font-size: 14px;
  position: relative;
  width: 100%;
  z-index: 12;

  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-start;

  p {
    a {
      color: white;
    }
  }

  u {
    a {
      color: white;
    }
  }

  a {
    color: white;

    span {
      color: white;
    }
  }

  .btn-cookies {
    border: 1px solid $white;
    background-color: transparent;
    border-radius: 5px;
    // float: right;
    // margin-top: 6px;
    display: flex;
    padding: 2px 15px;
    min-width: 80px;
    max-width: 250px;
    justify-content: center;
    display: inline-flex;
  }

  &.cookies-open {
    display: flex;
  }

  &.cookies-close {
    display: none;
  }

  .generic-cnt {
    font-size: 14px;
    line-height: 1;
    color: white !important;
    display: inline-flex;
    // padding-top:  8px;
    // max-width: calc(100% - 270px);
    justify-content: flex-start;
    margin-right: 20px;
    width: 100%;

    a,
    p {
      color: white !important;
      font-size: 14px;
    }
    a {
      border-bottom: 1px solid white !important;
    }
  }
}
