.case-study-results {
  padding: 50px 10px;

  &__title {
    font-size: 32px;
    line-height: 40px;
    width: 100%;
  }

  &__description {
    font-size: 16px;
    line-height: 40px;
    margin-bottom: 55px;
  }

  &__button {
    max-width: 100%;
  }
}
