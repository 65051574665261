.error,
.success,
.cta-alert {
  width: 100%;
  top: 0px;
  padding: 20px;
  display: none;
  color: $white;

  .cross-error {
    width: 26px;
    height: 26px;
    background: url('images/close-white.png') center center no-repeat;
    display: inline-block;
    border-bottom: 0 !important;
  }

  p {
    display: inline-block;
    padding-top: 8px;
    color: $white;
    width: calc(100% - 35px)
  }

  a {
    color: $white;
    text-decoration: none;
    border-bottom: 1px solid white !important;

    &:hover {
      text-decoration: none;
    }
  }
}

.error {
  background-color: $error;
}

.cta-alert {
  background-color: $pink;
}

.success {
  color: $corrected-error;
}

.error-open {
  display: block;
}

.error-close {
  display: none;
}
