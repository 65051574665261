@media print {
  *, *:before, *:after {text-shadow:none !important; box-shadow:none !important; }
  a, a:visited { text-decoration:underline; }
  a[href]:after { content:" (" attr(href) ")"; }
  abbr[title]:after { content:" (" attr(title) ")"; }
  a[href^="#"]:after, a[href^="javascript:"]:after { content:""; }
  pre, blockquote { border:1px solid #999; page-break-inside:avoid; }
  thead { display:table-header-group; }
  tr, img { page-break-inside:avoid; }
  img { max-width:100% !important; }
  p, h2, h3 { orphans:3; widows:3; }
  h2, h3 { page-break-after:avoid; }
  //.navbar { display:none; }
  .btn, .dropup > .btn {
    > .caret { border-top-color:#000 !important; }
  }
  .label { border:1px solid #000; }
  .table {
    border-collapse:collapse !important;
    td, th { background-color:#fff !important; }
  }
  .table-bordered {
    th, td { border:1px solid #ddd !important; }
  }
}
