.latest-content {
  margin-bottom: 20px;
  margin-top: 20px;
  padding-bottom: 50px;

  .latest-content-filters {
    margin-top: 0;
    margin-bottom: 20px;
  }

  &--compact {
    background: #fbfbfb;
    margin-top: 0;
    padding: 75px 0 80px;
    border-top: 1px #eeeef0 solid;
    border-bottom: 1px #eeeef0 solid;
    margin-bottom: 0;

    .latest-content-filters {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &--compact-white {
    @extend .latest-content--compact;
    background: white !important;
    border-top: 0 !important;
    border-bottom: 0 !important;
  }

  &__load {
    clear: both;
    margin-top: 20px;
  }

  &__wall-item {

    &--social {
      overflow: hidden;

      .latest-item {
        padding-bottom: 40px;

        &::after {
          content: '';
          display: block;
          width: calc(100%);
          position: absolute;
          height: 1px;
          background: #eeeef0;
          margin: 0;
          bottom: 40px;
        }
      }

      .share-button {
        transform: translateY(calc(-100% - 20px));
        -ms-transform: translateY(-100%) translateY(-20px);
        position: relative;
        z-index: 1;
      }
    }
  }

  .news-card--event {
    .news-card__date {
      margin-bottom: 20px;
    }
  }
}
