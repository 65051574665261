.payment-options {
  padding: 15px 20px;
  border-top: 1px solid #eeeef0;
  display: flex;
  align-items: center;
  color: $dark-blue;

  &__title {
    display: inline-block;
    margin-right: 25px;
    font-weight: 700;
    vertical-align: middle;
    white-space: nowrap;
  }

  &__items {
    display: inline-block;
    list-style: none;
    vertical-align: middle;
  }

  &__item {
    display: inline-block;
    background: #f8f8f8;
    padding: 5px 10px;
    height: 40px;
    width: 65px;
    overflow: hidden;
    margin: 0 0 7px 7px;
  }

  &__link {
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 40px;
    width: 100%;
    height: 100%;
    max-width: 65px;
    overflow: hidden;
  }

  .image-cover {
    align-self: center;
  }

  .image-cover__object-fit {
    background-position: center;
    background-size: contain;
  }

  &__image {
    align-self: center;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

}
