.messages-container {
  width: 100%;
  position: relative;
  z-index: 5;
  background: $pink;

  &--hidden {
    min-height: 4px;
    overflow: hidden;

    .cookies,
    .important-message {
      position: relative;
      opacity: 0;
      z-index: -1;
      overflow: hidden;
    }
  }

}
