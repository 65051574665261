@keyframes hideAnimate {
    from {
      display: block;
      opacity: 1;
    }
    to {
      display: none;
      opacity: 0;
    }
}

@-webkit-keyframes slideGesture {
    0%{
        margin-left: -20px;
    }
    25%{
        margin-left: 0;
    }
    50%{
        margin-left: 20px;
    }
    75% {
        margin-left: 0;
    }
    100% {
        margin-left: -20px;
    }
} 
