.promotional-block {

  &--promo {
    height: auto;

    .promotional-block__content-banner {
      position: relative;
      transform: none;
      top: auto;
      text-align: left;
      padding: 20px 10px;
      left: 0;
      width: 100%;
    }

    .promotional-block__title,
    .promotional-block__description {
      color: #000033;
      margin-bottom: 20px;
    }

    .promotional-block__video-controls {
      display: none;
    }

    .promotional-block__pause-button {
      margin: -11px 0 0 -8px;
    }

    .promotional-block__video,
    .promotional-block__media {
      width: 106%;
      margin: 0 -15px;
    }

  }

  &--full-width-promo {
    height: auto;

    .promotional-block__content-banner {
      position: relative;
      transform: none;
      top: auto;
      text-align: left;
      padding: 20px 0;
      left: 20px;
    }

    .promotional-block__title,
    .promotional-block__description {
      color: #000033;
      margin-bottom: 20px
    }

    //.promotional-block__video-controls,

    .promotional-block__part-name-title {
      color: #000033;

      &::before {
        background: #000033;
      }
    }


  }

  &--promo,
  &--full-width-promo {

    .promotional-block__video {
      position: relative;
      height: auto;

      .promotional-block__video-controls-mobile--show {
        display: block;
      }
    }
    .promotional-block__video-controls--show {
      display: block;
    }

    .promotional-block__video-controls-mobile {
      display: none;
      position: absolute;
      left: 50%;
      top: 50%;
      margin-left: -23px;
      margin-top: -56px;
      transform: translateY(50%);
    }
  }

  &--full-width-banner {
    max-height: 264px;
  }

}
