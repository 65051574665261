.case-study-results {
  padding: 75px 0 80px;
  color: $dark-blue;

  &__title {
    color: $dark-blue;
    font-weight: 700;
    font-size: 40px;
    line-height: 48px;
    width: 45%;
  }

  &__description {
    color: $dark-blue;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    text-transform: uppercase;
    margin-bottom: 60px;
  }

  &__button {
    display: flex;
    width: auto;
    max-width: 360px;
    margin: 0 auto;
  }
}
