.search-bar {
  position: relative;

  &__input {
    width: 100%;
    outline: none;
    height: 40px;
    border: 0;
    padding: 0 40px 0 20px;
    border: solid 2px $pink;

    &::placeholder {
      color: $dark-blue;
    }

    &::-webkit-input-placeholder {
      color: #999 !important;
    }
    &:-moz-placeholder {
      color: #999 !important;
    }
    &::-moz-placeholder {
      color: #999 !important;
    }
    &::-ms-clear {
     display: none;
    }
  }

  &__input:-ms-input-placeholder {
    color: #999 !important;
  }
  &__input::-ms-input-placeholder {
    color: #999 !important;
  }
  &__input::placeholder {
    color: #999 !important;
  }

  &__button {
    height: 40px;
    width: 40px;
    right: 0;
    border: 0;
    position: absolute;
    background: $pink;
    outline: none;
  }

  &__icon {
    font-size: 18px;
    color: white;
  }

  &__clear {
    width: 36px;
    height: 36px;
    background-color: #fff;
    position: absolute;
    top: 2px;
    right: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:before {
      content: "";
      width: 13px;
      height: 13px;
      background: url(images/cross.svg) center center no-repeat;
    }
  }
}
