.back-to-top {
  display: block;
  height: 0px;
  width: 0px;
  border: 0 none;
  background-color: #000033;
  position: fixed;
  z-index: 10;
  bottom: 100px;
  right: 10px;
  visibility: hidden;
	opacity: 0;
  transition: all .5s 0s;
  transform: scale(0);

  &__icon {
    width: 40px;
    height: 40px;
    display: block;
    transform: translate(50%, 50%);
    color: white;
    font-size: 40px !important;
    line-height: normal;
    margin-left: -8px;
    margin-top: -15px;
  }

  &:hover {
    cursor: pointer;
  }

  &--fade-out,
  &--visible {
    transition: all .5s 0s;
    transform: scale(1);
  }

  &--visible {
    height: 50px;
    width: 50px;
    visibility: visible;
		opacity: .5;
  }

  &--fade-out {
    opacity: .5;
  }
}
