@keyframes iconHover {

  0% {
    display: block;
    transform: translate(0, 0);
    opacity: 1;
  }

  49% {
    opacity: 0;
    display: none;
    transform: translate(0, -100%);
  }

  51% {
    opacity: 0;
    display: none;
    transform: translate(0, 100%);
  }

  100% {
    display: block;
    opacity: 1;
    transform: translate(0, 0);
  }
}
