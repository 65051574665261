//
// Utility classes
// --------------------------------------------------
.clearfix { @include clearfix; }
.center-block { @include center-block; }
.pull-right { float:right !important; }
.pull-left { float:left !important; }
.hide { display:none !important; }
.show { display:block !important; }
.invisible { visibility:hidden; }
.text-hide { @include text-hide; }
.hidden { display:none !important; }
.affix { position:fixed; }