.our-location-item {
  position: relative;
  padding: 25px 25px 50px;
  background: white;
  border: 2px solid #eeeef0;
  margin-bottom: 25px;
  width: 100%;
  box-sizing: border-box;

  &:hover {

    .desktop & .our-location-item__category {

      &::before {
        width: 38px;
        transition: all .5s;
      }
    }
  }

  &__block-icon {
    position: absolute;
    width: 64px;
    height: 64px;
    top: 25px;
    right: 25px;
  }

  &__category {
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 700;
    margin: 0 0 30px;
    vertical-align: middle;

    &::before {
      content: "";
      display: inline-block;
      margin-right: 5px;
      border-top: 3px solid #000033;
      width: 19px;
      height: 3px;
      vertical-align: middle;
      transition: all .5s;
    }
  }

  &__title {
    display: block;
    margin-bottom: 20px;
    font-weight: 700;
  }

  &__get-direction {
    display: flex;
    border: 0;
    text-decoration: none;
    color: $dark-blue;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 20px;

    &:hover {
      text-decoration: none;
      color: $pink;
    }
  }

  &__icon-link-external {
    display: inline;
    width: 20px;
    height: 20px;
    color: $pink;
    margin-right: 10px;
    font-size: 20px;
    line-height: 20px;
  }

  > p {
    margin-bottom: 20px;
    font-size: 18px;
    line-height: 24px;

    a {
      color: $dark-blue;
      border-bottom: 1px #92909f solid;
      text-decoration: none;
      line-height: 30px;

      &:hover {
        border-bottom: 0;
        text-decoration: none;
      }
    }
  }

  &__link {
    position: absolute;
    font-size: 20px;
    line-height: 24px;
    bottom: 25px;
  }

  .our-location-item__block-icon + .our-location-item__category,
  .our-location-item__block-icon + .our-location-item__category + .our-location-item__title {
    width: calc(100% - 80px);
  }

}

.desktop .our-location-item {

  &--active,
  &:hover {
    border-color: $pink;
    border-width: 2px;
  }
}
