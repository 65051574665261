.article-header {
  padding-top: 35px;
  &__tag {
    position: relative;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 18px;
    color: $dark-blue;
    margin-bottom: 10px;

    &::before {
      content: "";
      display: inline-block;
      height: 2px;
      width: 25px;
      margin-bottom: 5px;
      margin-right: 5px;
      background-color: $dark-blue;
    }
  }

  &__title {
    margin: 35px 0;
    font-size: 40px;
    line-height: 48px;
    color: $dark-blue;
    font-weight: 700;
  }

  &__description {
    font-size: 24px;
    line-height: 32px;
    color: $dark-blue;
    font-weight: 300;
  }

  &__date {
    margin-bottom: 20px;
    font-size: 20px;
    color: #9d9d9d;
    font-weight: 300;
  }

  &__info-panel {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    line-height: 24px;
    vertical-align: middle;
  }

  &__share {
    display: flex;
    margin-right: 15px;
    align-items: center;
    justify-content: flex-start;
    line-height: 24px;
    color: $dark-blue;
    vertical-align: middle;
  }

  &__share-icon {
    display: inline-flex;

    .share-button__button {
      display: inline-block;
      width: 25px;
      height: 24px;
      margin-left: 15px;
      overflow: hidden;

      .share-button__icon  {
        height: 24px;
      }
    }

    .share-button__counter {
      display: inline-block;
      line-height: 24px;

      .addthis_counter {
        vertical-align: middle !important;
      }
    }
  }

  &__share-label {
    display: inline-block;
    font-weight: 700;
    font-size: 20px;
    color: $dark-blue;
    margin-right: 5px;
    vertical-align: middle;
    line-height: 24px;
  }

  &__shares {
    display: inline-block;
    font-weight: 700;
    font-size: 20px;
    color: $dark-blue;
    margin-left: 5px;
  }

  &__print {
    display: flex;
    margin: 0 35px;
    font-weight: 700;
    font-size: 20px;
    color: $dark-blue;
    align-items: center;
    justify-content: flex-start;
    line-height: 24px;
    vertical-align: middle;

    &:hover {
      text-decoration: none;
      cursor: pointer;
    }
  }

  &__print-icon {
    font-size: 20px;
    margin-left: 5px;
    margin-top: -2px;
    display: inline-flex;
    width: 20px;
  }

  &__comments {
    display: flex;
    font-size: 20px;
    color: $dark-blue;
    font-weight: 300;
    align-items: center;
    justify-content: flex-start;
    line-height: 24px;
    vertical-align: middle;

    &:hover {
      cursor: pointer;

      .panoramic-gallery__comments-link {
        text-decoration: none;
      }
    }
  }

  &__comments-number {
    margin-right: 5px;
  }

  &__comments-link {
    color: $dark-blue;
    text-decoration: underline;
  }
}
