//
// Carousel
// --------------------------------------------------
.carousel { position:relative; }
.carousel-inner {
  position:relative; width:100%; overflow:hidden;
  > .item {
    position:relative; display:none; @include transition(.6s ease-in-out left);
    > img, > a > img { @include img-responsive; line-height:1;  }
    @media all and (transform-3d), (-webkit-transform-3d) {
      @include transition-transform(0.6s ease-in-out); @include backface-visibility(hidden); @include perspective(1000px);
      &.next, &.active.right { @include translate3d(100%, 0, 0); left:0; }
      &.prev, &.active.left { @include translate3d(-100%, 0, 0); left:0; }
      &.next.left, &.prev.right, &.active { @include translate3d(0, 0, 0); left:0; }
    }
  }
  > .active, > .next, > .prev { display:block; }
  > .active { left:0; }
  > .next, > .prev { position:absolute; top:0; width:100%; }
  > .next { left:100%; }
  > .prev { left:-100%; }
  > .next.left, > .prev.right { left:0; }
  > .active.left { left:-100%; }
  > .active.right { left:100%; }
}
.carousel-control {
  position:absolute; top:0; bottom:0; left:0; width:$carousel-control-width; font-size:$carousel-control-font-size; color:$carousel-control-color; text-align:center; text-shadow:$carousel-text-shadow; background-color:rgba(0, 0, 0, 0); @include opacity($carousel-control-opacity);
  &.left { @include gradient-horizontal($start-color:rgba(0, 0, 0, .5), $end-color:rgba(0, 0, 0, .0001)); }
  &.right { right:0; left:auto; @include gradient-horizontal($start-color:rgba(0, 0, 0, .0001), $end-color:rgba(0, 0, 0, .5)); }
  &:hover, &:focus { color:$carousel-control-color; text-decoration:none; outline:0; @include opacity(.9); }
  .icon-prev, .icon-next, .glyphicon-chevron-left, .glyphicon-chevron-right { position:absolute; top:50%; z-index:5; display:inline-block; margin-top:-10px; }
  .icon-prev, .glyphicon-chevron-left { left:50%; margin-left:-10px; }
  .icon-next, .glyphicon-chevron-right { right:50%; margin-right:-10px; }
  .icon-prev, .icon-next { width:20px; height:20px; font-family:serif; line-height:1; }
  .icon-prev {
    &:before { content:"\2039"; }
  }
  .icon-next {
    &:before { content:"\203a"; }
  }
}
.carousel-indicators {
  position:absolute; bottom:10px; left:50%; z-index:5; width:60%; padding-left:0; margin-left:-30%; text-align:center; list-style:none; 
  li { display:inline-block; width:10px; height:10px; margin:1px; text-indent:-999px; cursor:pointer; background-color:#000 \9; background-color:rgba(0, 0, 0, 0); border:1px solid $carousel-indicator-border-color; border-radius:10px; }
  .active { width:12px; height:12px; margin:0; background-color:$carousel-indicator-active-bg; }
}
.carousel-caption {
  position:absolute; right:15%; bottom:20px; left:15%; z-index:10; padding-top:20px; padding-bottom:20px; color:$carousel-caption-color; text-align:center; text-shadow:$carousel-text-shadow;
  & .btn { text-shadow:none; }
}
@media screen and (min-width:$screen-sm-min) {
  .carousel-control {
    .glyphicon-chevron-left, .glyphicon-chevron-right, .icon-prev, .icon-next { width:($carousel-control-font-size * 1.5); height:($carousel-control-font-size * 1.5); margin-top:($carousel-control-font-size / -2); font-size:($carousel-control-font-size * 1.5); }
    .glyphicon-chevron-left, .icon-prev { margin-left:($carousel-control-font-size / -2); }
    .glyphicon-chevron-right, .icon-next { margin-right:($carousel-control-font-size / -2); }
  }
  .carousel-caption { right:20%; left:20%; padding-bottom:30px; }
  .carousel-indicators { bottom:20px; }
}