//
// Labels
// --------------------------------------------------
.label {
  display:inline; padding:.2em .6em .3em; font-size:75%; font-weight:700; line-height:1; color:$label-color; text-align:center; white-space:nowrap; vertical-align:baseline; border-radius:.25em;
  &:empty { display:none; }
  .btn & { position:relative; top:-1px; }
}
a.label {
  &:hover, &:focus { color:$label-link-hover-color; text-decoration:none; cursor:pointer; }
}

.label-default { @include label-variant($label-default-bg); }
.label-primary { @include label-variant($label-primary-bg); }
.label-success { @include label-variant($label-success-bg); }
.label-info { @include label-variant($label-info-bg); }
.label-warning { @include label-variant($label-warning-bg); }
.label-danger { @include label-variant($label-danger-bg); }