:root {
  --scroll-top: 0px;
}

.header {
  position: sticky;
  top: -40px;
  z-index: 6;
  background-color: $white;
  &--eshop {
    .sticky-menu-label {
      display: block;
    }
  }

  &-top {
    display: flex;
    align-items: center;
    height: 40px;
    background: #E9E9E9;
    border-bottom: 1px solid $medium-grey;
    height: 40px; 
    justify-content: space-between;
    padding: 0 20px;
    &--left {
      width: 20%;
    }
    &--right {
      width: 20%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }

  &__contact-us {
    display: flex;
    align-items: center;

    &-content {
      position: relative;
      padding-left: 25px;
    }
    &-icon {
      position: absolute;
      top: 50%;
      left: 0;
      width: 20px;
      height: 20px;
      font-size: 22px;
      line-height: 20px;
      margin-top: -10px;
    }

    &-number {
      font-size: 12px;
      color: $pink;
      font-weight: 700;
    }

    &-infos {
      font-size: 10px;
      color: #707070;
      line-height: 14px;
    }

    &-agency {
      font-size: 10px;
      color: #707070;
      line-height: 14px;
      & + .header__contact-us-number-info:before {
        content: " | ";
        display: inline;
        line-height: 14px;
      }
    }

    &-number-info {
      font-size: 10px;
      color: #707070;
      line-height: 14px;
    }

    &-link {
      margin-right: 20px;
      font-size: 11px;
      line-height: 11px;
    }
  }

  &__switch {
    display: flex;
    align-items: center;
    justify-content: center;
    &-link {
      font-size: 11px;
      line-height: 11px;
    }
  }

  &__language-selector {
    position: relative;
    height: 40px;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-left: 25px;
    &--selected {
      font-size: 11px;
      transition: all 0.4s;
    }
    &--select {
      position: absolute;
      width: auto;
      top: 0;
      right: 0;
      transition: all 0.4s;
      z-index: -1;
      background: $white;
      padding: 5px;
      box-shadow: 4px 4px 4px #00000029;
      border: 1px solid #E9E9E9;
      opacity:0;
      > a {
        font-size: 12px;
        color: #000033;
        text-decoration: none;
        padding: 2px 5px;
        display: block;
        &:hover, &.active { 
          color: $pink;
          text-decoration: underline;
        }
      }
    }
    .fa-angle-down {
      transition: transform 0.4s;
      font-size: 22px;
      margin-right: 5px;
    }
    &.opened {
      .fa-angle-down {
        transform: rotate(180deg);
      }
      .header__language-selector {
        &--select {
          transition: all 0.4s;
          top:100%;
          z-index: 3;
          opacity: 1;
        }
      }
    }
  }

  &_global-wrapper {
    display: flex;
    padding: 5px 20px;
    min-height: 80px;
    align-items: center;
    border-bottom: 1px solid $medium-grey;
  }

  &__logo-link-image { 
    width: auto;
    height: 40px;
    margin-right: 20px;
  }

  &__forms-back-wrapper {
    display: none;
  }

  &__select-order {
    width: 120px;
    height: 40px;
    margin-left: 10px;
    color: #000033;
    position: relative;
    .btn-light {
      height: 40px;
      font-size: 14px;
    }
  }

  &__lock-popup {
    display: none;
  }

  &__forms {
    max-width: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    margin: 0 auto;
    max-width: 700px;
  }

  &__search {
    width: calc(100% - 130px);
  }

  //TO REMOVE IF NOT USED (verify registration and conversion funnel)
  &__btn-contact {
    min-width: 123px;
    width: 123px;
    font-size: 14px;
  }

  &__technical-links-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    height: 70px;
  }

  &__cart-wrapper {
    order: 3;
  }

  &__switch-wrapper {
    order: 4;
  }

  &__login-wrapper {
    order: 2;
    position: relative;
    margin-left: 10px;
    margin-right: 10px;
    height: 100%;
    display: flex;
  }

  &__login {
    display: flex;
    align-items: center;
    text-align: left;
    text-decoration: none;
    color: $dark-blue;
    max-width: 200px;

    i {
      font-size: 25px;
    }

    &:focus, &:hover {
      text-decoration: none;
      outline: none;
      i {
        color: $black;
      }
    }
  }
  &__login-icon {
    display: inline-block;
    width: 17px;
    height: auto;
  }
  &__login-text {
    display: inline-block;
    font-size: 14px;
    margin-left: 10px;
    line-height: 1;
  }
  &__login-wellcom {
    display: inline-block;
    color: $dark-blue;
    font-weight: 700;
    font-size: 14px;
  }
  &__login-name {
    display: block;
    color: $dark-blue;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 14px;
  }
  &__login-description {
    display: block;
    color: $dark-blue;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 14px;
  }

  // Login popup
  &__login-popup {
    display: none;
    position: absolute;
    top: calc(100% + 5px);
    z-index: 7;
    left: 50%;
    width: 0;
    align-items: center;
    justify-content: center;
    &-wrapper {
      position: relative;
      width: 260px;
      height: calc(100dvh - 120px - var(--message-container-height));
      overflow: auto;
      min-width: 260px;
      &--content {
        background: $white;
        border: 1px solid #e9e7ed;
        padding-bottom: 20px;
      }
    }
    &--open {
      display: flex;
    }
  }

  &__login-popup-section {
    display: flex;
    justify-content: space-between;
    font-weight: 700;
    align-items: center;
    height: 40px;
    padding: 0 15px;
    background-color: #E9E9E9;
    .title {
      text-transform: uppercase;
      font-size: 14px;
    }
    .link {
      text-decoration: underline;
      font-size: 12px;
    }
  }

  &__login-popup-menu-item {
    border-bottom: solid 1px #E9E9E9;
    > a {
      display: flex;
      position: relative;
      font-size: 14px;
      height: 40px;
      align-items: center;
      padding: 15px;
      &:hover {
        background-color: #FAFAFA;
      }
      &.active {
        color: $pink;
        &:after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 5px;
          height: 100%;
          background-color: #f0027F;
          transition: all 0.1s linear 0s;
        }
        &:hover:after {
          width: 10px;
          transition: all 0.1s linear 0s;
        }
      }
    }
  }

  &__login-popup-sign-out {
    font-size: 14px;
    text-align: center;
    padding: 15px 0 0 0;
  }

  &__login-popup-sign-out-link {
    display: block;
    color: $pink;
    font-weight: 700;
    font-size: 14px;
    text-decoration: underline;
    text-align: center;
    padding: 10px 0;
    &:hover {
      color: $pink;
      text-decoration: underline;
      opacity: 0.8;
    }
  }

  // cart
  &__cart-wrapper {
    display: flex;
    align-items: center;

    i {
      font-size: 25px;
      color: $black;
      margin-right: 5px;
    }
  }

  &__cart {
    display: flex;
    align-items: center;

    &--active {
      color: $pink;

      .header__cart-icon-wrapper {
        margin-right: 10px;
      }
      .header__cart-count {
        display: block;
      }
      .header__cart-link-amount {
        display:block;
        color: $pink;
      }
    }

    &:hover {
      text-decoration: none;
      cursor: pointer;
    }
  }

  &__cart-icon-wrapper {
    position: relative;
    width: 25px;
    margin-right: 5px;
    display: inline-block;
  }

  &__cart-count {
    display: none;
    position: absolute;
    width: 20px;
    height: 20px;
    background: $pink;
    color: $white;
    border-radius: 50%;
    text-align: center;
    top: -10px;
    right: -8px;
    z-index: 2;
  }

  &__cart-count-text {
    display: block;
    text-align: center;
    top: 4px;
    position: relative;
  }

  &__cart-icon {
    position: relative;
    width: 25px;
    z-index: 1;
  }

  &__cart-link {
    display: inline-block;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    text-decoration: none;
    color: $dark-blue;
    white-space: nowrap;
    &-amount {
    display: none;
      color: #000033;
      font-size: 13px;
      letter-spacing: -1px;
    }
  }
  &--registration {
    .header {
      &_global-wrapper {
        justify-content: space-between;
      }

      &__previous-page {
        order: 2;
      }

      &__contact-us {
        order: 3;
        .btn-pink {
          height: 40px;
          min-height: 40px;
          width: auto;
          min-width: auto;
          padding: 0 10px;
        }
      }
    }
  }
  &--delivery {
    .header {
      &_global-wrapper {
        justify-content: space-between;
      }

      &__contact-us {
        order: 2;
        margin: 0 auto 0 30px;
      }

      &__info {
        order: 3;
      }
    }
  }
}

.header--sticked .header__login-popup-wrapper {
  height: calc(100vh - 80px );
}

.overlay {
  position: fixed;
  display: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 4;
  background: rgba(0, 0, 0, 0.5);
}




