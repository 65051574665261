.customer-segments-item {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  min-height: 272px;
  overflow: hidden;

  .image-cover {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    z-index: 0;
  }

  &__image,
  &__video.generic-video {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0;
    /* IE Fix */
    font-family: 'object-fit: cover;';
  }

  &__video {
    .generic-video__media {
      width: 100%;
      height: 100%;
      object-fit: cover;
      /* IE Fix */
      font-family: 'object-fit: cover;';
    }
  }

  &__link {
    position: relative;
    display: block;
    width: 100%;
    color: white;
    text-decoration: none;
    font-size: 1px;

    &:hover {
      .desktop & .customer-segments-item__wrapper-title,
      .tablet & .customer-segments-item__wrapper-title {
        animation: hideup 1.5s;
        animation-fill-mode: forwards;
      }

      .desktop & .customer-segments-item__wrapper-hover,
      .tablet & .customer-segments-item__wrapper-hover {
        transform: translateY(0);
        opacity: 1;

        .customer-segments-item__wrapper-hover-title,
        .customer-segments-item__wrapper-hover-description,
        .customer-segments-item__arrow  {
          animation: fadein 3.5s;
        }

        .customer-segments-item__wrapper-hover-title {
          margin-top: 0;
        }
      }
      .desktop & .customer-segments-item__background,
      .tablet & .customer-segments-item__background {
        top: 0;
        transform: translateY(0);
        transition: all .8s;
      }
    }
  }

  &__wrapper {
    padding: 20px;
    position: relative;
    z-index: 3;
    top: 0;
    width: 100%;
    height: 100%;
    text-align: center;
  }

  &__wrapper-title {
    position: absolute;
    width: calc(100% - 40px);
    top: 50%;
    transform: translateY(-50%);
    font-size: 24px;
    line-height: 32px;
    z-index: 1;
  }

  &__wrapper-hover {
    position: relative;
    color: #fff;
    top: 0px;
    transform: translateY(100%);
    display: block;
    height: 100%;
    line-height: 24px;
    transition: all .5s;
    text-align: center;
    z-index: 3;
    opacity: 0;
    padding-bottom: 40px;
  }

  &__description {
    font-size: 24px;
    line-height: 32px;
    color: white;

    > p {
      color: white !important;
    }
  }

  &__wrapper-hover-title {
    font-size: 24px;
    line-height: 32px;
  }

  &__wrapper-hover-description {
    font-size: 18px;
    line-height: 24px;
    margin-top: 35px;
    color: white ;

    > p {
      color: white !important;
      font-size: 18px;
      line-height: 24px;
      color: white;
    }
  }

  &__arrow {
    position: absolute;
    bottom: 0px;
    right: 25px;
    width: 45px;
    height: 22px;
    z-index: 3;
    transition: all .5s;
    text-align: right;

    &:hover {
      right: 10px;
      transition: all .5s;
    }
  }

  &__background {
    position: absolute;
    transform: translateY(100%);
    background: $pink;
    display: block;
    width: 100%;
    height: 100%;
    z-index: 2;
  }

  &__arrow-icon {
    color: white;
    font-size: 20px!important;
  }
}
