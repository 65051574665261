.container {
  max-width: 100%;
}

.sector {
  &--grey {
    background-color: #fbfbfb;
    border-top: 1px #eeeef0 solid;
    border-bottom: 1px #eeeef0 solid;
  }
}

.row {

  &--equal-height {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: stretch;

    & > * {
      margin-bottom: 25px;
      display: flex;
      align-items: stretch;
    }

    &::after,
    &::before {
      content: normal;
    }
  }

  &--border-bottom {
    border-bottom: 1px solid #d3d3d9;
  }

  &--lg-divider {
    position: relative;
    overflow: hidden;

    @include md-more {
      &::after {
        content: "";
        display: block;
        position: absolute;
        left: calc(50% - 1px);
        width: 1px;
        border-left: 1px solid #eeeef0;
        top: 0;
        bottom: 0;
      }
    }
  }

  &--md-reverse {
    @include sm-less {
      display: flex;
      flex-direction: column-reverse;
    }
  }

}

@mixin bootstrap-modal {
  /* Modal */
    text-align: left;
    border-radius: 0;

    .modal-dialog {
      border-radius: 0;

      .modal-header {
        border: 0;
        border-radius: 0;
      }

      .modal-footer {
        border: 0;
        padding: 0;
        border-radius: 0;
      }

      .close {
        color: $dark-blue;
        font-size: 14px;
        display: flex;
        opacity: 1;
        align-items: center;
        outline: none;
        font-weight: 700;
        align-content: center;
        line-height: 24px;
      }

      .modal-content {
        border-radius: 0;
        border-color: #eeeef0;
        height: auto;
      }

      .modal-body-title {
        text-align: center;
        margin-bottom: 25px;
      }

      .modal-body {
        padding: 0 40px 25px;

        .form-wrapper {

          fieldset {

            legend {
              text-align: center;
            }
          }
        }
      }
    }

}
