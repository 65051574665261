.reinsurance-bar-2 {
  padding: 60px 0 90px;
  text-align: center;

  &--benefits {
    .reinsurance-item__text {
      font-size: 18px;
      line-height: 24px;
    }
  }

  &__title {
    margin-bottom: 50px;
  }

  &__button {
    margin-top: 15px;
  }

  &__items {
    display: flex;
  }

  &__carousel {
    height: 200px;

    .carousel-inner {
      height: auto !important;
    }

    .carousel-banner__container {
      height: auto;
    }
    .carousel-indicators {
      display: none;
    }

    .carousel-control-arrow {
      max-width: 100%;

      &--hide {
        display: none;
      }

      .carousel-control {
        .left-control,
        .right-control {
          background: transparent;
        }
      }
    }
  }
}


.generic-cnt + .reinsurance-bar-2 {
  margin-top: 50px;
}
