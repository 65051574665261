.testimonial-carousel-item {
  padding: 40px 40px 120px;
  border: 1px #eeeef0 solid;
  background: white;
  min-width: 280px;
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;

  &__image-wrapper {
    width: 100%;
    max-width: 135px;
    height: 146px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__image {
    display: block;
    width: 100%;
    height: auto;
  }

  &__content {
    width: 100%;
    &::before {
      display: block;
      content: '';
      height: 4px;
      width: 50px;
      background: $pink;
      margin: 40px 0 20px;
    }
  }

  &__quote {
    font-size: 20px;
    line-height: 28px;
    font-weight: 700;
    margin-bottom: 25px;
    max-height: 453px;
    overflow: hidden;
  }

  &__customer {
    font-size: 16px;
    line-height: 24px;
    font-weight: 300;
  }

  &__button {
    position: absolute;
    bottom: 40px;
    width: 100%;
    left: 0;
  }
}
