.reinsurance-bar {
  width: 60%;
  &__items {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__item {

    text-align: center;
    border-right: 1px solid #eeeef0;
    display: flex;
    align-items: stretch;
    align-content: center;
    max-width: 180px;

    &:last-child {
      border: none;
    }
  }

  &__link {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    text-align: center;
    height: 39px;
    max-height: 39px;
    color: $dark-blue;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 24px;
    padding: 0 10px;
    width: 100%;

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }

  &__link-icon,
  &__link-image {
    font-size: 20px;
    line-height: 39px;
    margin-right: 5px;
    max-width: 30px;
    max-height: 39px;
  }

  &__link-image {
    width: 18px;
    height: auto;
  }

  &__link-title {
    line-height: 13px;
    font-size: 12px;
    letter-spacing: -0.3px;
  }
}

@media (max-width: 1400px) {
  .reinsurance-bar__item { 
    max-width: 140px;
  }
}