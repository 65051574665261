.cards {
  height: 600px;
  border: 1px solid #eeeef0;
  background-color: #FFF;
  padding: 40px;

  > img {
    display: block;
    margin-bottom: 40px;
    width: auto !important;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .quote {
    font-size: 2rem;
    font-weight: 700;
    margin: 30px 0;
  }
  .customer {
    font-weight: 300;
    font-size: 1.6rem;
    margin-bottom: 30px;
  }
}
