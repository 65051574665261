.sticky-menu-label {
  display: none;
  opacity: 1;
  width: auto;
  margin-right: 15px;
  transition: all .3s; 
  cursor: pointer;

  &__toggle {
    display: flex;
    align-items: center;
  }

  &__toggle-button {
    width: 40px;
    height: 40px;
    position: relative;
    transform: rotate(0deg);
    transition: .5s ease-in-out;
    outline: none;
  }

  &__toggle-button-line {
    display: block;
    position: absolute;
    height: 7px;
    border-radius: 7px;
    width: 100%;
    background: $pink;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: .25s ease-in-out;

    &:nth-child(1) {
      top: 0px;
    }

    &:nth-child(2) {
      top: 16px;
    }

    &:nth-child(3) {
      top: 33px;
    }
  }
  &__toggle-label {
    text-transform: uppercase;
    font-weight: 600;
    color: #f0027f;
    font-size: 14px;
    margin-left: 5px;
  }
}

.header.header--open-menu {
  .sticky-menu-label__toggle-button {
    &-line {
      &:nth-child(1) {
        top: 17px;
        transform: rotate(135deg);
      }
      &:nth-child(2) {
        width: 0%;
        opacity: 0;
      }
      &:nth-child(3) {
        top: 17px;
        transform: rotate(-135deg);
      }
    }
  }
  .e-menu { //eshop menu
    display: block;
  }
}
