.key-points {
  padding-bottom: 50px;

  &--icons {

    .key-point-item {
      padding: 20px;
      width: 100%;
      margin: 0 10px;

      .key-point-item__text {
        padding: 0;
      }
    }
  }

  &__button {
    display: flex;
    margin: 30px auto 0;
  }
}
