.links {
  padding: 35px 25px;
  border-bottom: 1px solid #eeeef0;

  &--bg-color {
    background: #fbfbfb;
  }

  &__title {
    display: block;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 15px;
    text-transform: uppercase;
    color: $dark-blue;
  }

  &__resource {
    display: block;
    color: $dark-blue;
    text-decoration: underline;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 10px;

    &:hover {
      text-decoration: none;
      color: $pink;
    }
  }

  &__added-date {
    display: block;
    font-size: 14px;
    line-height: 32px;
    color: #7c7a8c;
    margin-bottom: 10px;
  }
}
