.locations {
  padding: 32px 25px;
  width: calc(100% + 50px);
  margin-left: -25px;
  border-bottom: 1px #eeeef0 solid;

  &__image {
    display: none;
  }
}
