.tagged-carousel-item {
  min-height: 440px;
  height: auto !important;

  /* Media */

  &__media {
    position: relative;
    width: 100%;
    height: 440px;
  }

  /* Description */

  &__description {
    position: relative;
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
    width: 100%;
    padding: 30px 25px;
    font-size: 15px;
    background: transparent;
  }

  /* Tags */

  &__tags {
    display: none;
  }

}

.col-xs-12,
.col-md-9 {

  .tagged-carousel-item {
    &__description {
      padding: 30px 0;
    }
  }
}
