.caterories {
  margin: 30px 30px 30px 15px;
  border-bottom: 1px solid #d3d3d9;

  &:last-child {
    border: 0;
  }

  &__header {
    position: relative;
  }

  &__title {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 20px;
    color: $dark-blue;
    width: calc(100% - 100px);
  }

  &__more {
    position: relative;
    width: 100%;
    transform: translateY(-50%);
  }

  &__more-link {

  }

  &__container {

  }

  &__items {

  }

  &__item {
    padding: 5px 0;
  }

  &__item-link {
    font-size: 14px;
    text-decoration: none;
    font-family: 'Source Sans Pro',Verdana,sans-serif;
    color: $dark-blue;

    > span {
      font-weight: 700;
    }

    &:hover {
      color: $pink;
    }
  }

  &__hideshow {
    margin-top: 15px;
    position: relative;
    //left: -15px;
  }

  &__show-link,
  &__hide-link {
    position: relative;
    color: $pink;
    font-size: 14px;
    padding-left: 22px;
    margin-bottom: 30px;

    &::before {
      position: absolute;
      color: #FFF;
      background-color: #F0027F;
      width: 14px;
      height: 14px;
      left: 0px;
      top: -1px;
      line-height: 18px;
      text-align: center;
      font-size: 18px;
    }

    &:hover {
      opacity: 0.8;
    }
  }


  &__show-link {
    display: block;

    &::before {
      content: "+";
    }
  }

  &__hide-link {
    display: none;

    &::before {
      content: "-";
    }
  }
}
