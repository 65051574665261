.result-item {
  display: block;
  margin-bottom: 30px;
  text-decoration: none;
  color: $dark-blue;
  max-width: 100%;
  width: 100%;
  
  &:hover {
    color: $pink;
    text-decoration: none;
  }

  &__image-wrapper {
    max-width: 125px;
    display: inline-block;
    margin-right: 30px;
    vertical-align: top;
    width: 100%;

    &--without-image {
      width: 124px;
      height: 124px;
      border: 1px $light-grey solid;
      background: $white;
      max-width: 100%;
      vertical-align: inherit;
    }
  }

  &__image {
    width: 100%;
    height: auto;
  }

  &__text-wrapper {
    display: inline-block;
    width: calc(100% - 175px);
    vertical-align: top;
  }

  &__title {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 15px;
  }

  &__description {
    font-size: 14px;
    font-family: 'Source Sans Pro',Verdana,sans-serif;
  }
}
