.search-prelayer-light {
    max-height: calc(100dvh - 140px - var(--message-container-height));
    &--open{
        top: 100%;
    }

    &__content {
        .container {width: 100%;}
    }
}

.header--sticked {
    .search-prelayer-light--open {
      max-height: calc(100dvh - 100px);
    }
}